import React, { useState,useEffect } from 'react'
import DataTable from 'components/DataTable'
import { TableRow,TableCell } from '@material-ui/core'
import SearchTable from 'components/SearchTable'
import _ from 'lodash'
import * as actionSa from 'redux/actions/sa'
import {useSelector,useDispatch} from 'react-redux'
import moment from 'moment'
const RenderHeadRiwayatKredit=(props)=>(
    <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
        {/* <SearchTable
            {...props}
            searchToggle={(value)=>props.searchToggle(value)}
        /> */}
    </div>
)
export default function Riwayat_kredit(props) {
    const [search, setsearch] = useState('')
    const sa = useSelector(state => state.sa)
    const dispatch = useDispatch()
    let {client_detail,list_history}=sa

    useEffect(() => {
        dispatch(actionSa.getHistoryCredit(client_detail.id))
    }, [])
    const searchToggle=_.debounce(async (e)=>{
        setsearch(e)

    },1000)
    return (
        <div>
            <div className='head-section'>
                <h4><b>Riwayat Kredit</b></h4>
            </div>
            <br/>
            <DataTable
                head={['No.','Aktifitas','Tanggal','Keterangan','Jumlah','Sisa Kredit']}
                body={()=>{
                    return list_history.map((data,i)=>(
                        <TableRow key={i} >
                            <TableCell style={{width:10}}>{i+1}</TableCell>
                            <TableCell>{data.activity}</TableCell>
                            <TableCell>{moment(data.date).format('DD MMM YYYY')}</TableCell>
                            <TableCell>{data.description}</TableCell>
                            <TableCell  style={{color:data.amount>=0?'#a8e090':'#ff6e79',fontWeight:'bold'}}>{data.amount}</TableCell>
                            <TableCell align="right">{data.balance}</TableCell>
                        </TableRow>
                    ))
                }}
                cardHead={<RenderHeadRiwayatKredit {...props}  searchToggle={searchToggle} />}
                loading={false}
                pagination={null}
                togglePagination={null}
            />
            <br/>

        </div>
    )
}
