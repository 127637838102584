import React ,{useEffect,useState}from 'react'
import {useSelector,useDispatch} from 'react-redux'

import {Button,TextField,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import Skeleton from 'components/Skeleton'
import { MuiThemeProvider, createTheme,withStyles, makeStyles} from '@material-ui/core/styles'
import SearchImg from 'assets/icon/Search.png'
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce} from 'lodash'
import {modalToggle} from 'redux/actions/general'
// import {getClientList,resetDetail,getIndustry,deleteClient} from 'redux/actions/client'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import close from 'assets/icon/close.svg'
import Key from 'assets/icon/Key.svg'
import * as clientAction from 'redux/actions/client'
import * as proyekAction from 'redux/actions/proyek'
const useStyles = makeStyles(theme => ({
    search: {
        [`& fieldset`]: {
            borderRadius: 8,
            height:30,
            margin: theme.spacing(1),
          },
          width:200,
          height:10,
          marginBottom:40
  },
  input:{
    fontSize:14,
    margin:3      
  }
}));
export default function List(props) {
    const dispatch=useDispatch()
    const classes=useStyles()
    const client = useSelector(state => state.client)
    const proyek = useSelector(state => state.proyek)
    const general = useSelector(state => state.general)
    const [search, setSearch] = useState('')
    const {list_industry,client_filter,client_list}=client
    const {proyek_list,proyek_pagination}=proyek
    useEffect(()=>{
        dispatch(proyekAction.getHierarchyListForDropDown())
        if(list_industry.length<1){
            dispatch(clientAction.getIndustry())
        }
        if(client_list.length<1){
            dispatch(clientAction.getClientList(`/0/1/10/*`))
        }
        dispatch(proyekAction.getProyekList(`/1/10/*`))

    },[])
    const addProyek=async ()=>{
        let res= await dispatch(clientAction.getClientListForDropDown())
    
        if(res){
            dispatch(modalToggle({
                modal_open: true,
                modal_title: `Tambah Proyek`,
                modal_component: "tambah_proyek",
                modal_size:500,
                modal_data:{action:()=>dispatch(proyekAction.getProyekList(`/1/10/*`))},
                modal_action:'tambah_proyek'
            }))
        }
    }
    const searchToggle=debounce(async (value)=>{
        setSearch(value)
        await dispatch(proyekAction.getProyekList(`/1/10/${value!==''?value:'*'}`))
    },1000)
    const togglePagination=(page,pageLength)=>{
        dispatch(proyekAction.getProyekList(`/${page}/${pageLength}/${search!==''?search:'*'}`))
    }
    const renderColor=(number)=>{
        if(number>=0){
            return '#a8e090'
        }else{
            return '#ff6e79'
        }
    }
    const renderColorStatus=(status)=>{
        if(status==='Proses'){
            return '#ffb865'
        }else if(status==='Menunggu'){
            return '#ff6e79'
        }else{
            return '#a8e090'
        }
    }
    const detail_proyek=async (data)=>{
        // dispatch(proyekAction.setProyekDetail({projectId:data.id}))
        dispatch(proyekAction.resetRecapFilter())
        let res= await dispatch(proyekAction.getDetailProject(`/${data.id}`))
        
        if(res){
            props.tabToggle('detail')

        }
    }
    const edit_proyek=async (data)=>{
        // let res=await dispatch(getDetailClient(`/${data.clientId}`))

        // if(res){
        //     props.tabToggle('edit')

        // }
    }
    const action_delete=async (data)=>{
        let res= await dispatch(proyekAction.deleteProject(`/${data.id}/${props.profile.id}`))
        if(res){
            dispatch(proyekAction.getProyekList(`/1/10/*`))
        }
        
    }
    const delete_proyek=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Project',
                action:()=>action_delete(data),
                msg:`<p>Apakah anda yakin menghapus ${data.name} </p>`
            },
            modal_action:'delete_wla'
        }))
    }
    const kode_akses=async (data)=>{
        let res= await dispatch(proyekAction.getKey(`/${data.id}/${props.profile.id}`))
        if(res){
            dispatch(modalToggle({
                modal_open: true,
                modal_title: `Kode Akses`,
                modal_component: "kode_akses",
                modal_size:350,
                modal_data:res.data,
                modal_action:'kode_akses'
            }))
        }
    }
    return (
        <div>
            <div className='head-section'>
                <div>
                    <h4 style={{marginBottom:0,marginTop:0}}>Daftar Rangkuman Proyek Rekap Kerja Klien</h4>
                </div>
                <Button onClick={addProyek} color='primary' className='btn-rounded btn-remove-capital btn-action' variant="contained">Tambah Proyek</Button>
            </div>
            <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table__head'>
                        <TextField
                            placeholder="Cari"
                            variant='outlined'
                            size='small'
                            InputLabelProps={{shrink: false}}
                            className={classes.search}
                            inputProps={{
                                className:classes.input
                            }}
                            onChange={(e)=>searchToggle(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <img alt="search" src={SearchImg} style={{width:15}}/>
                                </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className='card-table__content'>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                        <TableCell align="left" style={{width:10}}>No.</TableCell>
                        <TableCell align="left"style={{width:150}} >Nama Proyek</TableCell>
                        <TableCell align="left"  style={{width:150}}>Nama Perusahaan</TableCell>
                        <TableCell align="left" style={{width:10}}>Jumlah Jabatan</TableCell>
                        <TableCell align="left" style={{width:10}}>Jumlah SDM</TableCell>
                        <TableCell align="left" style={{width:10}}>Kebutuhan SDM</TableCell>
                        <TableCell align="left" style={{width:10}}>Surplus/Deficit</TableCell>
                        <TableCell align="center" style={{width:10}}>Status</TableCell>
                        <TableCell align="center">Aksi</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {general.isLoadingTable?
                                <Skeleton count={9}/>
                            :
                            proyek_list.length<1?
                            <TableRow>
                                <TableCell colSpan={9} style={{textAlign:'center'}}>Belum ada proyek</TableCell>
                            </TableRow>
                            :proyek_list.map((data,i)=>(
                                <TableRow key={i}>
                                    <TableCell >{i+1}</TableCell>
                                    <TableCell >{data.name}</TableCell>
                                    <TableCell>{data.company}</TableCell>
                                    <TableCell align='center'>{data.jobTitles}</TableCell>
                                    <TableCell align='center'>{data.sdm}</TableCell>
                                    <TableCell align='center'>{data.need}</TableCell>
                                    <TableCell align='center' style={{color:renderColor(data.surplus),fontWeight:'bold'}}>{data.surplus}</TableCell>
                                    <TableCell align='center'  style={{color:renderColorStatus(data.status),fontWeight:'bold'}}>{data.status}</TableCell>
                                    <TableCell align="center">
                                        <div >
                                        <img src={Key} onClick={()=>kode_akses(data)} className='icon-action'/>
                                        <img src={Eye} onClick={()=>detail_proyek(data)} className='icon-action'/>
                                        <img src={close} onClick={()=>delete_proyek(data)} className='icon-action'/>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                            
                            }
                        </TableBody>
                    </Table>
                    <div className='card-table__pagination'>
                    {proyek_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={proyek_pagination.page}
                            pageLength={proyek_pagination.perPage}
                            totalRecords={proyek_pagination.total}
                            totalRecords={proyek_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    }
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
