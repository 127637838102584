import React from 'react'
import Layout from 'components/Layouts'

import { createTheme,MuiThemeProvider } from '@material-ui/core'
import List from './list'
import Detail from './detail'
import Detail_talent from './detail_talent'
import PetaTalent from './peta'
const themeButton = createTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#4cc614',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Index(props) {
    const [tab, settab] = React.useState('list')
    const tabToggle=(key)=>{
        settab(key)
    }
    return (
        <div>
            <Layout>
            <MuiThemeProvider theme={themeButton}>
                {tab==='list'&&<List tab={tab} tabToggle={tabToggle} {...props} />}
                {tab==='detail'&&<Detail tab={tab} tabToggle={tabToggle} {...props} />}
                {tab==='edit'&&<Detail tab={tab} tabToggle={tabToggle} {...props} />}
                {tab==='add'&&<Detail tab={tab} tabToggle={tabToggle} {...props} />}
                {tab==='detail_talent'&&<Detail_talent tab={tab} tabToggle={tabToggle} {...props} />}
                {tab==='peta'&&<PetaTalent tab={tab} tabToggle={tabToggle} {...props} />}
            </MuiThemeProvider>
        </Layout>
        </div>
    )
}
