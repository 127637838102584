import React from 'react'
import './style.css'
import wla_login from 'assets/image/wla_login.png'
export default function Admin_in_mobile() {
    return (
        <div className='in-mobile-wrapper'>
            <img src={wla_login} style={{width:'100%'}}/>
            <br/>
            <p className='semi-bold'>Anda login sebagai admin saat ini system admin tidak dapat diakses melalui mobile web</p>
        </div>
    )
}
