import React from 'react'
import { Button } from '@material-ui/core'
export default function Confirm_rbk(props) {
    let {modal_data}=props

    return (
        <div>
            <div className='confirm-container'>
                <h4>Data berhasil disimpan</h4>
                <p>Apakah anda ingin melanjutkan pengerjaan?</p>
                <div className='div-flex div-space-between'>
                    {/* <MuiThemeProvider theme={themeButton}> */}
                        <Button onClick={()=>modal_data.action_no()}   size='small' color='primary' variant="text" className='btn-remove-capital'>Tidak, Saya sudah selesai</Button>
                        <Button onClick={()=>modal_data.action_ya()}   size='small' color='primary' variant='contained' className='btn-remove-capital btn-rounded '>Ya</Button>
                    {/* </MuiThemeProvider> */}
                </div>
            </div>
        </div>
    )
}
