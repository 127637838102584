import React,{useRef,useState,useEffect} from 'react'
import {Button,TextField} from '@material-ui/core'
import { MuiThemeProvider, createTheme,withStyles, makeStyles} from '@material-ui/core/styles'
import AutoCompleteSelect from 'components/Select'
import {useDispatch,useSelector} from 'react-redux'
import {setFilterClient,getClientList} from 'redux/actions/client'
const themeButton = createTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
          },
          width:'100%',
          marginBottom:15
  },
  textField2: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width:'100%',
    marginBottom:10
}
}));

export default function Filter_project(props) {
    const dispatch = useDispatch()
    const client = useSelector(state => state.client)
    const [industry, setindustry] = useState([])
    let {list_industry,client_filter}=client
    useEffect(()=>{
        console.log('props.modal_data', props.modal_data)
        setindustry(props.modal_data.industry)
    },[])
    const onClickSimpan=async ()=>{
        let new_industry=[]
        industry.map((data)=>{
            new_industry.push(data.id)
        })
        // dispatch(setFilterClient({industry:industry}))
        props.modal_data.afterPostFilter(industry)
        await dispatch(getClientList(`/${new_industry.length>0?new_industry.join():0}/1/10/*`))
        props.modalToggleReset()
    }
    const onChange=(value)=>{
        
        setindustry(value)
    }
    return (
        <div>
           <div style={{width:'100%'}}>
                <AutoCompleteSelect
                    onChange={(event,value)=>onChange(value)}
                    options={list_industry}
                    value={industry}
                    getOptionLabel={(option) => option.text}
                    label={<>Industry</>}
                    multiple
                    // disabled={handleDisable()}
                />
            </div>
            
            <div style={{textAlign:'right'}}>
                <MuiThemeProvider theme={themeButton}>
                    <Button size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Save</Button>
                </MuiThemeProvider>
            </div>
            <br/>
        </div>
    )
}
