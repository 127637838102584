import React, { useState } from 'react'
import './style.css'
import Chevron from 'assets/icon/chevron_white.svg'

import {Collapse} from '@material-ui/core'
import {logout} from 'redux/actions/auth'
import {withRouter} from 'react-router-dom'
import ava_default from 'assets/icon/avadefault.svg'
import Users2 from 'assets/icon/Users2.svg'
import Users1 from 'assets/icon/Users3.svg'
import UserTie from 'assets/icon/UserTie.svg'
import UserTie2 from 'assets/icon/UserTie2.svg'
import Spreadsheet from 'assets/icon/Spreadsheet2.svg'
import Spreadsheet2 from 'assets/icon/Spreadsheet.svg'
import Invoice from 'assets/icon/Invoice.png'
import {useDispatch} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
import OneTalent from 'assets/image/OneTalent.png'
import Altius from 'assets/image/Altius.png'

function Index(props) {
    // console.log(`props.profile`, props.profile)
    const dispatch = useDispatch()
    const [state, setState] = useState({
        page_collapse:false,
        admin_collapse:false,
        profile:null
    })
    const [hover, sethover] = useState({
        abk:false,
        client:false,
        konsultan:false,
        mapping:false
    })
    const toggleCollapse=(key)=>{
        setState({[key]:!state[key]})
    }
    const toggleActive=(key)=>{
        let url=props.location.pathname
        let match=url.includes(key)
        return match
    }
    const gantiPassword=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Reset Password`,
            modal_component: "reset_password",
            modal_size:350,
            modal_data:null,
            modal_action:'reset_password'
        }))
    }
    let url=props.location.pathname
    let wla=url.match('/wla')
    let talenta=url.match('/manajementalenta')
    let sa=url.match('/sa')
    // console.log(`sa&&props.profile.roleId`, sa,props.profile.roleId)
    const renderItemNav=()=>{
        
        if(wla){
            return (
                <>
                    <div onMouseEnter={()=>sethover({...hover,abk:true})} onMouseLeave={()=>sethover({...hover,abk:false})} onClick={()=>window.location.assign('/wla/analisis-beban-kerja')} className={`list-sidebar ${toggleActive('/wla/analisis-beban-kerja')?'is-active-menu':''}`}>
                        <img src={toggleActive('/wla/analisis-beban-kerja')||hover.abk?Spreadsheet:Spreadsheet2} className='icon-size'/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a >Analisis Beban Kerja</a>
                    </div>
                    <div onMouseEnter={()=>sethover({...hover,client:true})} onMouseLeave={()=>sethover({...hover,client:false})} onClick={()=>window.location.assign('/wla/client')} className={`list-sidebar ${toggleActive('/wla/client')?'is-active-menu':''}`}>
                        <img src={toggleActive('/wla/client')||hover.client?Users1:Users2} className='icon-size'/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a >Manajemen Klien</a>
                    </div>
                    <div onMouseEnter={()=>sethover({...hover,konsultan:true})} onMouseLeave={()=>sethover({...hover,konsultan:false})} onClick={()=>window.location.assign('/wla/konsultan')} className={`list-sidebar ${toggleActive('/wla/konsultan')?'is-active-menu':''}`}>
                        <img src={toggleActive('/wla/konsultan')||hover.konsultan?UserTie:UserTie2} className='icon-size'/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a >Daftar Konsultan</a>
                    </div>
                </>
            )
        }
        if(talenta&&props.profile.roleId!==3){

            return (
                <>
                    <div onMouseEnter={()=>sethover({...hover,mapping:true})} onMouseLeave={()=>sethover({...hover,mapping:false})} onClick={()=>window.location.assign('/manajementalenta/mapping')} className={`list-sidebar ${toggleActive('/manajementalenta/mapping')?'is-active-menu':''}`}>
                        <img src={toggleActive('/manajementalenta/mapping')||hover.mapping?Spreadsheet:Spreadsheet2} className='icon-size'/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a >Talent Mapping</a>
                    </div>
                    {/* <div onMouseEnter={()=>sethover({...hover,client:true})} onMouseLeave={()=>sethover({...hover,client:false})} onClick={()=>window.location.assign('/manajementalenta/client')} className={`list-sidebar ${toggleActive('/manajementalenta/client')?'is-active-menu':''}`}>
                        <img src={toggleActive('/manajementalenta/client')||hover.client?Users1:Users2} className='icon-size'/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a >Manajemen Klien</a>
                    </div> */}
                </>
            )
        }
        if(sa&&props.profile.roleId===3){
            
            return (
                <>
                    <div onMouseEnter={()=>sethover({...hover,mapping:true})} onMouseLeave={()=>sethover({...hover,mapping:false})} onClick={()=>window.location.assign('/sa/talenta/mapping')} className={`list-sidebar ${toggleActive('/sa/talenta/mapping')?'is-active-menu':''}`}>
                        <img src={toggleActive('/sa/talenta/mapping')||hover.mapping?Spreadsheet:Spreadsheet2} className='icon-size'/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a >Talent Mapping</a>
                    </div>
                    <div onMouseEnter={()=>sethover({...hover,client:true})} onMouseLeave={()=>sethover({...hover,client:false})} onClick={()=>window.location.assign('/sa/client')} className={`list-sidebar ${toggleActive('/sa/client')?'is-active-menu':''}`}>
                        <img src={toggleActive('/sa/client')||hover.client?Users1:Users2} className='icon-size'/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a >Manajemen Klien</a>
                    </div>
                </>
            )
        }
    }
    const {admin_collapse}=state
    
    return (
        <div>
            <div className='sidebar-wrapper'>
                <div className='is-collapse admin-list' onClick={()=>toggleCollapse('admin_collapse')}>
                    <div className='' style={{display:'flex',alignItems:'center'}}>
                        <img src={ava_default} style={{width:30}}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a>{props.profile.name}</a>
                    </div>
                    <div>
                        <img src={Chevron} className={admin_collapse?'chevron':' active-chevron'}/>
                    </div>
                </div>
                <Collapse in={admin_collapse}>
                <div className='content-collapsee' onClick={()=>gantiPassword()}>
                        <div className='list-collapse'>
                            <a>Ganti Password</a>
                        </div>
                    </div>
                    <div className='content-collapsee' onClick={()=>dispatch(logout(wla?'wla':'talenta'))}>
                        <div className='list-collapse'>
                            <a>Logout</a>
                        </div>
                    </div>
                    
                </Collapse>
                <div className='hr'/>
                {renderItemNav()}
                {(talenta)&&<div   onClick={()=>window.open('https://onegml.com/assets/web/ecatalog/Panduan%20penggunaan%20aplikasi%20manajemen%20talenta.pdf','_blank')} className={`list-sidebar `}>
                        <img src={Invoice} className='icon-size'/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a >Panduan</a>
                    </div>}
                {(talenta)&&<div style={{position:'absolute',width:220,bottom:20,display:'flex',justifyContent:'center'}}>
                    <img src={Altius} style={{width:'80%',margin:'auto'}}/>

                </div>}
            </div>
        </div>
    )
}
export default withRouter(Index)
