import React from 'react'
import Peta from 'views/talenta/Proyek/peta/peta'
export default function PetaTalent(props) {
    return (
        <div>
            <Peta
                {...props}
                // tab_back="detail_talent"
            />
        </div>
    )
}
