import * as actionTypes from "../constants/client";
const initialState={
    client_list:[],
    client_list_for_dropdown:[],
    client_pagination:{
        page:0,
        perPage:10,
        total:0
    },

    client_detail:{
        clientId:0,
        company:'',
        industry:null,
        phone:'',
        address:'',
        
    },
    list_industry:[],
    client_filter:{
        industry:[]
    }
}
export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.GET_CLIENT_LIST:
            return{
                ...state,
                client_list:action.payload.client_list,
                client_pagination:action.payload.client_pagination
            }
        case actionTypes.GET_CLIENT_LIST_FOR_DROPDOWN:
            return{
                ...state,
                client_list_for_dropdown:action.payload,
                
            }
        case actionTypes.SET_CLIENT_DETAIL:
            return{
                ...state,
                client_detail:{...state.client_detail,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.GET_INDUSTRY:
            return{
                ...state,list_industry:action.payload
            }
        case actionTypes.SET_FILTER:
            return{
                ...state,
                client_filter:{...state.client_filter,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.RESET_DETAIL:
            return{
                ...state,
                client_detail:{
                    clientId:0,
                    company:'',
                    industry:null,
                    phone:'',
                    address:'',
                    kredit:[],
                    admin:[]
                }
            }
        default:
            return state;
    }
}

