import React from 'react'
import CreatableSelect from 'react-select/creatable';
export default function Index(props) {
    let {options,handleChange}=props
    const customStyles = {
        control:(provided,state)=>({
            ...provided,
            border:'none',
            boxShadow: state.isFocused ? 0 : 0,
              '&:hover': {
              border: state.isFocused ? 0 : 0
              }
        }),
        dropdownIndicator:(provided,state)=>({
            ...provided,
            display:'none'
        }),
        indicatorSeparator:(provided,state)=>({
            ...provided,
            display:'none'
        })
    }
    return (
        <div>
            <CreatableSelect 
                isMulti 
                {...props}
                placeholder=""
                styles={customStyles} 
                options={options} 
                onChange={handleChange}
            />
        </div>
    )
}
