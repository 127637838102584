import React,{useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {Button,TextField} from '@material-ui/core'
import Users3 from 'assets/icon/Users3.svg'
import Users1 from 'assets/icon/Users1.svg'
import Dumpbell from 'assets/icon/Dumpbell.svg'
import Edit from 'assets/icon/edit.svg'
import ChevronDown from 'assets/icon/chevron-down.svg'
import TreeDots from 'assets/icon/TreeDots.svg'
import InputMask from 'react-input-mask'
export default function Info(props) {
    let {responden_modal,
        setresponden_modal,
        wke_modal,
        setwke_modal,
        classes,
        batasfte_modal,
        setbatasfte_modal,
        editJabatan,
        detailKetWla,
        recap_detail,
        onClickRespondenModalItem,
        onClickWkeModalItem,
        wke,
        setwke,
        fte,
        setfte,
        onClickSimpanFteModal,
        recap_column,
        recap_detail_column
    }=props
    const renderConsultanName=(consultant)=>{
        if(consultant.id==0){
            return <span style={{color:'#ff6e79',fontWeight:500}}>(Belum terverifikasi)</span>
        }else{
            return <span>{consultant.text}</span>
        }
    }
    const renderDescSurplus=(surplus)=>{
        if(surplus>0){
            return 'Surplus'
        }else if(surplus<0){
            return 'Deficit'
        }else{
            return 'Equal'
        }
    }
    const renderColorStatus=(status)=>{
        if(status==='Menunggu'){
            return '#ff6e79'
        }else if(status==='Proses'){
            return '#ffb865'
        }
        else{
            return '#a8e090'
        }
    }
    // console.log(`recap_detail.remarks,recap_detail.remarks.length`, recap_detail.remarks.slice(0,100),recap_detail.remarks.length)
    const findValueColumn=(columnId)=>{
        console.log(`recap_detail_column`, recap_detail_column)
        let filter=recap_detail_column.filter((d)=>{
            return d.columnId===columnId
        })[0]
        return filter
    }
    return (
        <div className='rbk-info-wrapper'>
            <div className='rbk-info-posisi'>
                <div style={{padding:15}}>
                    <div className='div-flex div-space-between div-align-center'>
                        <p className='rbk-info-title' >Informasi Posisi/Jabatan Kerja</p>
                        <div className='div-flex div-align-center'>
                            <p className='rbk-info-status' style={{color:renderColorStatus(recap_detail.status)}}>{recap_detail.status}</p>
                            &nbsp;&nbsp;&nbsp;
                            <img onClick={editJabatan} src={Edit} style={{width:15,margin:0,cursor:'pointer'}}/>
                        </div>
                    </div>
                    <div className='rbk-info-posisi-item'>
                        <div className='div-flex div-align-center '>
                            <p style={{width:120}}>Responden</p>
                            <p>:&nbsp;{recap_detail.respondent.text}</p>
                            {/* <div className='position-relative'>
                                <div className='chevron-down' onClick={()=>setresponden_modal(!responden_modal)}><img src={ChevronDown}/></div>
                                {responden_modal&&
                                <div className='responden-modal'>
                                    {recap_detail.respondents.map((data,i)=>(
                                        <div key={i}>
                                            <div className={`responden-modal-item ${recap_detail.respondent.id===data.id&&'responden-modal-item-active'}`} onClick={()=>onClickRespondenModalItem(data.id)}>
                                                <h4>{data.text}</h4>
                                            </div>
                                            {i!==recap_detail.respondents.length-1&&<div className='custom-hr'></div>}
                                        </div>
                                    ))}
                                    
                                </div>}
                            </div> */}
                            
                        </div>
                        <div className='div-flex'>
                            <p style={{width:120}}>Jabatan</p>
                            <p>:&nbsp;{recap_detail.shortname} - {recap_detail.jobTitle}</p>
                        </div>
                        <div className='div-flex'>
                            <p style={{width:120}}>Jenjang jabatan</p>
                            <p>:&nbsp;{recap_detail.jobLevel.text}</p>
                        </div>
                        <div className='div-flex'>
                            <p style={{width:120}}>Jenis WKE</p>
                            <p>:&nbsp;<span style={{color:'#00a1ed'}}>{recap_detail.wke.text}</span></p>
                            <div className='position-relative'>
                                <div className='chevron-down' onClick={()=>setwke_modal(!wke_modal)}><img src={ChevronDown}/></div>
                                {wke_modal&&
                                <div className='wke-modal'>
                                    <div style={{padding:10}}>
                                        <h3>Jenis WKE</h3>
                                    </div>
                                    <div className='custom-hr'></div>
                                    {recap_detail.wkes.map((data,i)=>(
                                        <div onClick={()=>setwke(data.id)} className={`wke-modal-item ${wke===data.id&&'wke-modal-item-active'}`} key={i}>
                                            <h5>{data.text}</h5>
                                        </div>
                                    ))}
                                    
                                    <div className='custom-hr2'></div>

                                    <div style={{textAlign:'right',padding:10}}>
                                        <Button onClick={onClickWkeModalItem} className='btn-remove-capital btn-rounded' size='small' variant='contained' color='primary'>Simpan</Button>
                                    </div>

                                </div>
                             } 
                            </div>
                        </div>
                        {recap_detail_column.length>0&&recap_column.map((d,i)=>(
                            <div className='div-flex' key={i}>
                                <p style={{width:120}}>{d.text}</p>
                                <p>:&nbsp;{findValueColumn(d.id).data}</p>
                            </div>
                        ))}
                        <div className='div-flex'>
                            <p style={{width:120}}>Lokasi Kerja</p>
                            <p>:&nbsp;{recap_detail.location}</p>
                        </div>
                        <div className='div-flex div-space-between'>
                            <div className='div-flex'>
                                <p style={{width:120}}>Nama Konsultan</p>
                                <p>:&nbsp;{renderConsultanName(recap_detail.consultant)}</p>
                            </div>
                            <p>Batch {recap_detail.batch}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='rbk-small-card'>
                <div className='rbk-small-top'>
                    <div className='rbk-small-top-card-workload'>
                        <div className='div-flex-column div-space-between' style={{padding:10,height:'75%'}}>
                            
                            <p className='rbk-info-title'>Total Beban Kerja</p>
                            <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
                                <div className='rb-info-content div-flex div-space-between div-align-center' style={{width:'50%'}}>
                                    <img src={Dumpbell} style={{width:30}}/>
                                    <h2>{recap_detail.respondent.text===''&&recap_detail.consultant.text==='-'?'-':recap_detail.workload.toLocaleString()}</h2>
                                </div>
                                
                                <div style={{width:'40%'}} className='rbk-info-workload'>
                                    {recap_detail.workloadByTypes.map((data,i)=>(
                                        <div className='div-flex' key={i}>
                                            <p style={{width:120}}>{data.name}</p>
                                            <p>:&nbsp;{data.percent.toFixed()}%</p>
                                        </div>
                                    ))}
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='rbk-small-top-card'>
                        <div className='div-flex-column div-space-between' style={{padding:10,height:'75%'}}>
                            <p className='rbk-info-title'>Surplus/Deficit</p>
                            <div className='rb-info-content '>
                                {recap_detail.respondent.text===''&&recap_detail.consultant.text==='-'?<h2>-</h2>
                                :
                                    <div className='div-flex div-space-between div-align-center'>
                                        <h2>{recap_detail.surplus}</h2>
                                        <h2>{renderDescSurplus(recap_detail.surplus)}</h2>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                <div className='rbk-small-bottom'>
                    <div className='rbk-small-sd'>
                        <div className='div-flex-column div-space-between' style={{padding:10,height:'75%'}}>
                            <div className='div-flex div-space-between div-align-center'>
                                <p className='rbk-info-title'>Jumlah SDM</p>
                                <p className='rbk-info-fte'>FTE : {recap_detail.fte}</p>
                            </div>
                            <div className='rb-info-content div-flex div-space-between div-align-center'>
                                <img src={Users1} style={{width:30}}/>
                                <h2>{recap_detail.respondent.text===''&&recap_detail.consultant.text==='-'?'-':recap_detail.sdm}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='rbk-small-sd'>
                        <div className='div-flex-column div-space-between' style={{padding:10,height:'75%'}}>
                            <div className='div-flex div-space-between div-align-center position-relative'>
                                <p className='rbk-info-title'>Kebutuhan SDM</p>
                                <img onClick={()=>setbatasfte_modal(!batasfte_modal)} src={TreeDots} style={{width:15,cursor:'pointer'}}/>
                                {batasfte_modal&&<div className='batasfte-modal div-flex div-align-center'>
                                    <InputMask
                                        maskChar={null}
                                        mask="9.99"
                                        onChange={(e)=>setfte(e.target.value)}
                                        value={fte}
                                    >
                                    {() =>
                                        <TextField
                                            label="Batas Atas FTE"
                                            variant='outlined'
                                            size='small'
                                            className={classes.textField}
                                        />
                                    }
                                    </InputMask>
                                    
                                    &nbsp;&nbsp;
                                    <Button onClick={onClickSimpanFteModal} className='btn-remove-capital btn-rounded' size='small' variant='contained' color='primary'>Simpan</Button>
                                </div>}
                            </div>
                            <div className='rb-info-content div-flex div-space-between div-align-center '>
                                {/* <img src={Users3} style={{width:30}}/> */}
                                
                                <div className='need-sdm-info'>
                                    <div className='div-flex' >
                                        <p style={{width:45}}>Total FTE</p>
                                        <p>:&nbsp;{recap_detail.actualFte===0?'-':recap_detail.actualFte.toFixed(3)}</p>
                                    </div>
                                    <div className='div-flex' >
                                        <p style={{width:45}}>Jam Kerja</p>
                                        <p>:&nbsp;{recap_detail.workingHours===0?'-':recap_detail.workingHours.toFixed(3)}/hari</p>
                                    </div>
                                </div>
                                <h2>{recap_detail.respondent.text===''&&recap_detail.consultant.text==='-'?'-':recap_detail.need}</h2>
                            </div>
                        </div>
                        
                    </div>
                    <div className='rbk-small-hasil'>
                        <div className='div-flex-column div-space-between' style={{padding:10,height:'75%'}}>
                            <div className='div-flex div-space-between div-align-center'>
                                <p className='rbk-info-title'>Keterangan Hasil</p>
                                <img src={Edit} style={{width:15,cursor:'pointer'}} onClick={()=>detailKetWla(recap_detail.remarks)}/>
                                {/* <img className='rbk-info-title-blue' onClick={detailKetWla}>Lihat Detail</img> */}
                            </div>
                            <div className='rb-info-content div-flex div-space-between div-align-center'>
                                <p>{recap_detail.respondent.text===''&&recap_detail.consultant.text==='-'?'-':recap_detail.remarks.length>=100?`${recap_detail.remarks.slice(0,80)}...`:recap_detail.remarks}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
