import React,{useState} from 'react'
import Select from 'components/Select'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import { useSelector,useDispatch } from 'react-redux'
import { postKlasifikasiProject } from 'redux/actions/talenta'
export default function Klasifikasi(props) {
    let {klasifikasi_list,filename}=props.modal_data
    const talenta = useSelector(state => state.talenta)
    const dispatch = useDispatch()
    let {project_info}=talenta
    const [state, setstate] = useState({
        list:klasifikasi_list,
        personal:[],
        grade:[],
        x:[],
        y:[]
    })
    const handleDisable=()=>{
        if(_.isEmpty(state.personal)||_.isEmpty(state.grade)||_.isEmpty(state.x)||_.isEmpty(state.y)){
            return true
        }else{
            return false
        }
    }
    const onSave=async ()=>{
        let data={
            projectId:talenta.project_id,
            personal:state.personal.map((d)=>d.id),
            grade:state.grade.map((d)=>d.id),
            x:state.x.map((d)=>d.id),
            y:state.y.map((d)=>d.id),
        }
        let res=await dispatch(postKlasifikasiProject(data))
        if(res){
            props.modal_data.afterPost()
            console.log(`data`, res.data)
        }
    }
    const onChangePersonal=(value,name,reason)=>{
        // let new_list
        // if(Array.isArray(value)){
        //     if(reason==='select-option'){
        //         value.map((v)=>{
        //             new_list=state.list.filter((d)=>{
        //                 return d.id!==v.id
        //             })
        //         })
        //     }else{
        //         if(_.isEmpty(value)){
        //             new_list=[...state[name],...state.list]
        //         }else{
        //             value.map((v)=>{
        //                 new_list=klasifikasi_list.filter((d)=>{
        //                     return d.id!==v.id
        //                 })
                        
        //             })
        //         }
        //     }
        // }else{
        //     if(value){
        //         new_list=state.list.filter((d)=>{
        //             return d.id!==value.id
        //         })
        //     }else{
        //         console.log(`reason`, reason,value)

        //         new_list=[state[name],...state.list]
        //     }
        // }
        // setstate({...state,[name]:value,list:new_list})
        setstate({...state,[name]:value})
    }
    // console.log(`state`, state)
    const renderOptions=()=>{
        let {grade,personal,x,y,list}=state
        let used_options=[]
        let new_options=[]
        grade.map((d)=>{
            used_options.push(d)
        })
        personal.map((d)=>{
            used_options.push(d)
        })
        x.map((d)=>{
            used_options.push(d)
        })
        y.map((d)=>{
            used_options.push(d)
        })
        list.map((l)=>{
            let findIfUsed=used_options.filter(u=>{
                return u.id===l.id
            })
            if(findIfUsed.length===0){
                new_options.push(l)
            }
        })
        console.log(`used_options`, used_options)
        return new_options
    }
    return (
        <div>
            <p className='semi-bold'>Terdapat {klasifikasi_list.length} judul kolom pada <b>{filename}</b></p>
            <p className='semi-bold'><b>Data Grade</b></p>
            <Select
                 onChange={(event,value,reason)=>onChangePersonal(value,'grade',reason)}
                options={renderOptions()}
                value={state.grade}
                getOptionLabel={(option) => option.text}
                label="Judul Kolom Grade"
                multiple
                // disableClearable={true}

                style={{marginBottom:10}}
            />
            <p className='semi-bold'><b>Data Pegawai</b></p>
            <Select

                onChange={(event,value,reason)=>onChangePersonal(value,'personal',reason)}
                options={renderOptions()}
                value={state.personal}
                getOptionLabel={(option) => option.text}
                label="Pilih Judul Kolom"
                multiple
                // disableClearable={true}
                
                filterSelectedOptions
                style={{marginBottom:10}}
            />
            
            <p className='semi-bold'><b>{project_info.xAxis}</b></p>
            <Select
                 onChange={(event,value,reason)=>onChangePersonal(value,'x',reason)}
                options={renderOptions()}
                value={state.x}
                getOptionLabel={(option) => option.text}
                label={project_info.xAxis}
                multiple
                style={{marginBottom:10}}
                // disableClearable={true}

            />
            <p className='semi-bold'><b>{project_info.yAxis}</b></p>
            <Select
                 onChange={(event,value,reason)=>onChangePersonal(value,'y',reason)}
                options={renderOptions()}
                value={state.y}
                getOptionLabel={(option) => option.text}
                label={project_info.yAxis}
                multiple
                style={{marginBottom:10}}
                // disableClearable={true}

            />
            <div style={{textAlign:'right'}}>
                <Button onClick={onSave} disabled={handleDisable()} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
