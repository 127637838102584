import React,{useEffect} from 'react'
import { Button } from '@material-ui/core'
import ProyekInfo from './proyek_info'
import Proyek_employee from './proyek_employee'
import Proyek_bobot from './proyek_bobot'
import Proyek_norma from './proyek_norma'
import { useSelector,useDispatch } from 'react-redux'
import * as actionTalenta from 'redux/actions/talenta'
export default function Detail(props) {
    const dispatch = useDispatch()
    const talenta = useSelector(state => state.talenta)
    let {project_info,bobot,norma_range,norma_category,list_employee}=talenta
    useEffect(() => {
        getBobot()
        getDetailProject()
        getNormaByRange()
        getNormaByCategory()
        let data={
            items:[],
            projectId:talenta.project_id,
            search:'*'
        }
        getEmployee('/1/10',data)
    }, [])
    const getDetailProject=()=>{
        dispatch(actionTalenta.getDetailProject(talenta.project_id))
    }

    const getBobot=()=>{
        dispatch(actionTalenta.getBobot(`/${talenta.project_id}/${props.profile.id}`))

    }
    const getNormaByRange=()=>{
        dispatch(actionTalenta.getNormByRange(`/${talenta.project_id}/${props.profile.id}`))
    }
    const getNormaByCategory=()=>{
        dispatch(actionTalenta.getNormByCategory(`/${talenta.project_id}/${props.profile.id}`))
    }
    const getEmployee=(slug,data)=>{
        dispatch(actionTalenta.getDataKaryawan(slug,data))
    }
    const getReport=()=>{
        dispatch(actionTalenta.getDataReport(talenta.project_id))
    }
    const isShowHitungPetaBtn=()=>{
        let findnot100=[]
        bobot.map((d)=>{
            let x= d.items.filter((i)=>{
                return i.totalX!==100
            })
            let y= d.items.filter((i)=>{
                return i.totalY!==100
            })
            x.map((o)=>{
                findnot100.push(o)
            })
            y.map((o)=>{
                findnot100.push(o)
            })
        })
        if(findnot100.length===0){
            return true
        }else{
            return false
        }
    }
    return (
        <div data-testid="detail-wrapper">
            
            <div className='head-section'>
                <h4><b>Detail Proyek {project_info.name}</b></h4>
                <div className='div-flex'>
                    <Button  onClick={()=>props.tabToggle(props.tab_back?props.tab_back:'list')} style={{ border: '1.5px solid' }} className='btn-remove-capital btn-rounded' variant='outlined' color="primary">Kembali</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={getReport} style={{ border: '1.5px solid' }} className='btn-remove-capital btn-rounded' variant='outlined' color="secondary">Export</Button>
                    &nbsp;&nbsp;&nbsp;
                    {isShowHitungPetaBtn()&&<Button onClick={()=>props.tabToggle('peta')}  className='btn-remove-capital btn-rounded' variant='contained' color="primary">Hitung Peta Talenta</Button>}
                </div>
            </div>
            <ProyekInfo project_info={project_info} {...props}/>
            <br/>
            <Proyek_employee 
                project_info={project_info} list_employee={list_employee} getEmployee={getEmployee} {...props}
                getBobot={getBobot}
                getDetailProject={getDetailProject}
                getNormaByRange={getNormaByRange}
                getNormaByCategory={getNormaByCategory}
            />
            <br/>
            <Proyek_bobot bobot={bobot} getBobot={getBobot}  project_info={project_info} {...props}/>
            <br/>
            <Proyek_norma norma_range={norma_range} norma_category={norma_category} getNormaByCategory={getNormaByCategory} getNormaByRange={getNormaByRange} project_info={project_info} {...props}/>
        </div>
    )
}
