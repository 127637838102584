import React, { Component } from 'react'
import Checklist from 'assets/icon/checklist.png'
import close from 'assets/icon/close.svg'
import Warning from 'assets/icon/Warning.svg'
import {connect} from 'react-redux'
import {modalToggleReset,modalToggle} from 'redux/actions/general'
import { MuiThemeProvider, createTheme, } from '@material-ui/core/styles'
import {Button } from '@material-ui/core'
import {useSelector,useDispatch} from 'react-redux'


export default function Alert() {
    const dispatch = useDispatch()
    const general = useSelector(state => state.general)
    const {modal_data,modal_title,modal_action,modal_component}=general
    const renderFooter=()=>{
        switch (modal_component) { 
            // case 'client':
                // return(
                //     <Button onClick={()=>dispatch(modalToggleReset())}   size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded '>Tutup</Button>
                // )         
            default:
                return(
                    <Button onClick={()=>dispatch(modalToggleReset())}   size='small' color='primary' variant='contained' className='btn-remove-capital btn-rounded '>Tutup</Button>
                ) 
                break;
        }
    }
    return (
        <div className='alert-container'>
            {modal_action==='error_upload'?
            <img src={Warning} style={{width:'40%'}}/>
            :
            <img src={modal_action==='error'?close:Checklist} style={{width:'40%'}}/>}
            <br/>
            <h4>{modal_title}</h4>
            {/* <p>{modal_data.msg}</p> */}
            <div dangerouslySetInnerHTML={{ __html: modal_data.msg }}></div>
                {renderFooter()}
        </div>
    )
}
