import React from 'react'
import { Scatter } from 'react-chartjs-2'

export default function ChartScatter({items,minX,minY}) {
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');
      
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
          tooltipEl.style.borderRadius = '3px';
          tooltipEl.style.color = 'white';
          tooltipEl.style.opacity = 1;
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.transform = 'translate(-50%, 0)';
          tooltipEl.style.transition = 'all .1s ease';
          tooltipEl.style.zIndex = 4;
      
          const table = document.createElement('table');
          table.style.margin = '0px';
      
          tooltipEl.appendChild(table);
          chart.canvas.parentNode.appendChild(tooltipEl);
        }
      
        return tooltipEl;
      };
      
      const externalTooltipHandler = (context) => {
        // Tooltip Element
        const {chart, tooltip} = context;
        const tooltipEl = getOrCreateTooltip(chart);
      
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
      
        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || [];
          const bodyLines = tooltip.body.map(b => b.lines);
      
          const tableHead = document.createElement('thead');
      
          titleLines.forEach(title => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = 0;
      
            const th = document.createElement('th');
            th.style.borderWidth = 0;
            const text = document.createTextNode(title);
      
            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
          });
      
          const tableBody = document.createElement('tbody');
          bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];
      
            const span = document.createElement('span');
            span.style.display = 'none';
      
            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            // tr.style.backgroundColor = 'red';

      
            const td = document.createElement('td');
            // td.style.borderWidth = 0;
            td.style.borderBottom = '1px solid white';
      
            const text = document.createTextNode(body);
      
            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tableBody.appendChild(tr);
          });
      
          const tableRoot = tooltipEl.querySelector('table');
      
          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }
      
          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
        }
      
        const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
      
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
      };
      const options = {
        animation: {
            duration: 0
        },
        scales: {
          x: {
              min:minX,
              beginAtZero:false,
            stepSize: 10,
              grid: {
                  drawTicks: false,
                  display:false,
                  drawBorder: false,
  
              },
              ticks:{
                  display:false,
                  
              },
          },
          y: {
            beginAtZero:false,
            min:minY,
            stepSize: 10,

              grid: {
                  drawTicks: false,
                  display:false,
                  drawBorder: false,
  
              },
              ticks:{
                  display:false
              }
          },
        },
        plugins:{
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: externalTooltipHandler,
            callbacks:{
                label:(tooltipItem,data)=>{
                    // console.log(`tooltipItem`, tooltipItem)
                    return ' '+tooltipItem.raw.name +' '+ tooltipItem.raw.id +' '+ tooltipItem.raw.department
                },
                beforeBody:()=>{
                    return ''
                }
            }
          },
          hover: {mode: null},
          legend: {
              display: false,
          },
        }
      };
      const renderDataSets=(items)=>{
        const data = {
            order: 0,
            datasets: [
              {
                label: 'A dataset',
                data: [],
                backgroundColor: 'white',
                pointRadius: 8,
                pointHoverRadius: 8,
                order: 1,

              },
            ],
          };
        items.map((d)=>{
            data.datasets[0].data.push({
                x:d.valX.toFixed(),
                y:d.valY.toFixed(),
                name:d.personal2,
                id:d.personal1,
                department:d.personal3
            })
        })
        return data
      }
    return (
        <Scatter data={renderDataSets(items)} width="0%" height='0%' options={options} />
    )
}
