import React,{useState} from 'react'
import {Button,TextField} from '@material-ui/core'
import { MuiThemeProvider, createTheme,withStyles, makeStyles} from '@material-ui/core/styles'
import AutoCompleteSelect from 'components/Select'
import {useDispatch,useSelector} from 'react-redux'
import * as clientAction from 'redux/actions/client'
import InputMask from 'react-input-mask'
export default function Tambah_client(props) {
    const dispatch = useDispatch()

    const client = useSelector(state => state.client)
    const [state, setstate] = useState({
        clientId:0,
        company:'',
        industry:null,
        phone:'',
        address:'',
        userId:props.profile.id
    })
    const {classes}=props
    let {list_industry}=client
    const {clientId,company,industry,phone,address,userId}=state

    const onClickSimpan=()=>{
        let data={
            clientId:clientId,
            company:company,
            industryId:industry.id,
            phone:phone,
            address:address,
            userId:userId,
        }
        dispatch(clientAction.postClient(data))
        console.log(`client_detail`, state)
    }
    const handleDisable=()=>{
        if(company===''||industry==null||phone===''||address===''){
            return true
        }else{
            return false
        }
    }
    return (
        <div>
             <TextField
                label="Nama Perusahaan"
                variant='outlined'
                value={state.company}
                size='small'
                className={classes.textField}
                onChange={(e)=>setstate({...state,company:e.target.value})}
            />
            <div style={{width:'100%'}}>
                <AutoCompleteSelect
                    onChange={(event,value)=>setstate({...state,industry:value})}
                    options={list_industry}
                    value={state.industry}
                    getOptionLabel={(option) => option.text}
                    label={<>Industry</>}
                    // multiple
                    // disabled={handleDisable()}
                />
            </div>
            <InputMask
                maskChar={null}
                mask="9999-9999-9999-9999"
                value={state.phone}
                onChange={(e)=>setstate({...state,phone:e.target.value})}
            >
            {() =>
                <TextField
                    label="No. Telepon"
                    variant='outlined'
                   
                    size='small'
                    className={classes.textField}
                   
                />
            }
            </InputMask>
            
            <TextField
                label="Alamat"
                variant='outlined'
                value={state.address}
                size='small'
                multiline
                className={classes.textField}
                onChange={(e)=>setstate({...state,address:e.target.value})}
            />
            
            <div style={{textAlign:'right'}}>
                <Button disabled={handleDisable()} size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
