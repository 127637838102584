import React from 'react'
import './style.css'
import Logo from 'assets/image/OE.png'
import Altius from 'assets/image/Altius.png'
import {withRouter} from 'react-router-dom'

function Index(props) {
    // console.log(`props.profile`, props.profile)
    let url=props.location.pathname
    let wla=url.match('/wla')

    return (
        <nav>
            <div className='hamburger' >
                <div className='hamburger__list'/>
                <div className='hamburger__list'/>
                <div className='hamburger__list'/>
            </div>
            <div className='logo' onClick={()=>window.location.assign('/')}>
                <img src={wla?Logo:props.profile.logo_url?props.profile.logo_url:Altius} style={{width:150,height:50,objectFit:'contain'}}/>
                {/* <div className='vertical-line'></div> */}
                {/* <h4>Knowledge Center</h4> */}
            </div>
        </nav>
    )
}
export default withRouter(Index)