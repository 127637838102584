import {apiCall} from 'service/apiCall'
import {setLoading,modalToggle,setLoadingTable} from './general'
import Cookie from 'universal-cookie'
import {get} from 'lodash'
import * as actionTypes from 'redux/constants/konsultan'
const cookie=new Cookie
const token=cookie.get('login_cookie')
export const resetDetail=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_DETAIL,
        payload:payload
    })
}
export const setKonsultanDetail=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_KONSULTAN_DETAIL,
        payload:payload
    })
}
export const getKonsultan=(slug='/1/10/*')=>async dispatch=>{
    dispatch(setLoadingTable(true))

    let datasent={
        url:`/consultant/wla${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoadingTable(false))

        dispatch({
            type:actionTypes.GET_KONSULTAN,
            payload:{
                konsultan_list:res.data.consultants,
                konsultan_pagination:res.data.info
            }
        })
        return res
        
    }else{
        dispatch(setLoadingTable(false))

    }
}
export const postKonsultan=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/consultant/wla`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Konsultan berhasil disimpan",
                modal_component: "konsultan",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const putKonsultan=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/consultant/wla`,
            method:'PUT',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Konsultan berhasil diupdate",
                modal_component: "konsultan",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const deleteKonsultan=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/consultant/wla${slug}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Konsultan Berhasil dihapus",
                modal_component: "konsultan",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const getKonsultanDropDown=()=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/list/recap/consultant`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))

        dispatch({
            type:actionTypes.GET_KONSULTAN_DROPDOWN,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}