import React from 'react'
import Proyek from 'views/talenta/Proyek'
import Layout from 'components/Layouts'
import Detail from  './detail'
import List from  './list'
import Peta from  './peta'
import { createTheme,MuiThemeProvider } from '@material-ui/core'

const themeButton = createTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#4cc614',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Undex(props) {
    const [tab, settab] = React.useState('list')
    const tabToggle=(key)=>{
        settab(key)
    }
    const addKredit=()=>[
        
    ]
    return (
        <div>
            {/* <Proyek
                {...props}
                is_sa={true}
                addKredit={addKredit}
            /> */}
            <Layout>
            <MuiThemeProvider theme={themeButton}>
            {tab==='list'&&<List tab={tab} tabToggle={tabToggle} {...props} />}
                {tab==='detail'&&<Detail tab={tab} tabToggle={tabToggle} {...props} />}
                {tab==='peta'&&<Peta tab={tab} tabToggle={tabToggle} {...props} />}
            </MuiThemeProvider>
        </Layout>
        </div>
    )
}
