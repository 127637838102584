import React,{useState,useEffect} from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import { useDispatch,useSelector } from 'react-redux'
import { modalToggle } from 'redux/actions/general'
import SearchTable from 'components/SearchTable'
import DataTable from 'components/DataTable'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import * as actionGeneral from 'redux/actions/general'
import * as actionTalenta from 'redux/actions/talenta'
import ContentEditable from 'components/ContentEditable'

const RenderHead=(props)=>(
    <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
        <div className='div-flex div-align-center'>
        <SearchTable
            {...props}
            searchToggle={(value)=>props.searchToggle(value)}
        />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/* <p style={{fontFamily:'Lato,sans-serif',fontWeight:'bold',color:'#cccccc',margin:0,fontSize:12}}>Nama File : {props.filename}</p> */}
        </div>
        <div className='div-flex ' style={{alignItems:'flex-end'}}>
            {props.filter_by_field_value_with_id.length>0&&
            <div >
                <p className='pipeline-filterby'><b>Filter by:</b></p>
                <div style={{display:'flex',flexWrap:'wrap',minWidth:200,maxWidth:400}}>
                    {props.filter_by_field_value_with_id.map((d,i)=>{
                        if(d.value.length>0){
                            return(
                                <p className='pipeline-filterby'>{d.filter.text} : {d.value.map((v)=>v.text).join(' , ')}&nbsp;&nbsp;&nbsp;&nbsp;</p>
                            )
                        }

                    })}
                </div>
            </div>}
            <button onClick={props.filterEmployee} className='card-table__head_btn'>Filter</button>

        </div>
        
        
    </div>
)
const useStyles = makeStyles({
    table: {
      minWidth: 300,
    },
  });
export default function OutBox(props) {
    const classes = useStyles();
    const [isedit, setisedit] = useState(false)
    const [search, setsearch] = useState('')
    const [help, sethelp] = useState(false)
    const dispatch = useDispatch()
    const talenta = useSelector(state => state.talenta)
    const general = useSelector(state => state.general)
    let {out_box,project_info,filter_by_field_value,filter_by_field_value_with_id}=talenta
    let {getOutBox,profile}=props
    // useEffect(() => {
    //     let data={
    //         items:[],
    //         projectId:talenta.project_id,
    //         search:'*'
    //     }
    //     getOutBox('/1/10',data)
    // }, [])
    const searchToggle=_.debounce(async (e)=>{
        setsearch(e)
        let data={
            items:filter_by_field_value,
            projectId:project_info.id,
            search:e.length!==""?e:'*'
        }
        getOutBox(`/1/10`,data)
    },1000)
   
    const togglePagination=(page,pageLength)=>{
        let data={
            items:filter_by_field_value,
            projectId:talenta.project_info.id,
            search:search.length!==""?search:'*'
        }
        getOutBox(`/${page}/${pageLength}`,data)

    }
    const changeFile=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Mengganti File Data`,
            modal_component: "change_file",
            modal_size:400,
            modal_type:'change_file',
            modal_data:{afterPost:(data)=>afterPost(data)},
            modal_action:'change_file'
        }))
    }
    const afterPost=()=>{
        let data={
            items:[],
            projectId:talenta.project_id,
            search:'*'
        }
        getOutBox('/1/10',data)
        dispatch(actionGeneral.modalToggleReset())

    }
    const actionFilter=(items)=>{
        let data={
            items:items,
            projectId:talenta.project_info.id,
            search:'*'
        }
        getOutBox(`/1/10`,data)
        dispatch(actionGeneral.modalToggleReset())
    }
    const onSave=async ()=>{
        if(isedit){
            let payload={
                projectId:project_info.id,
                userId:profile.id,
                items:[]
            }
            out_box.rows.map((row)=>{
                row.items.map((item)=>{
                    payload.items.push(item)
                })
            })
            let res=await dispatch(actionTalenta.postEmployee(payload))
            if(res){
                setisedit(false)
                let data={
                    items:[],
                    projectId:talenta.project_id,
                    search:'*'
                }
                getOutBox('/1/10',data)
            }

        }else{
            setisedit(true)
        }
    }
    const filterEmployee=async ()=>{
        let res=await dispatch(actionTalenta.getFilterField(project_info.id))
        if(res){
            dispatch(actionGeneral.modalToggle({
                modal_open: true,
                modal_title: `Filter Karyawan`,
                modal_component: "filter_by_field",
                modal_size:450,
                modal_type:'filter_by_field',
                modal_data:{
                    action:(items)=>actionFilter(items),
                    
                },
                modal_action:'filter_by_field'
            }))
        }
        
    }
    const onInput=(value,i1,i2)=>{
        // console.log(`value`, value)
        // if(value!==""){
            out_box.rows[i1].items[i2].text=value

        // }else{
        // out_box.rows[i1].items[i2].text=0

        // }
        dispatch(actionTalenta.setTalenta({out_box:out_box}))
    }
    // const onInput=_.debounce(async (value,i1,i2)=>{
        
    //     out_box.rows[i1].items[i2].text=value
    //     dispatch(actionTalenta.setTalenta({out_box:out_box}))
    // },1000)
    const onKeyPress=(e)=>{
        if(e.which===13){
            e.preventDefault()
        }
    }
    // console.log(`filter_by_field_value_with_id`, filter_by_field_value_with_id)
    return (
        <div>
            <div className='head-section'>
                <div className='div-flex div-align-center' style={{position:'relative'}}>
                    <h4 style={{fontWeight:'bold'}}>Daftar Karyawan Diluar Map</h4>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div onMouseLeave={()=>sethelp(false)} onMouseEnter={()=>sethelp(true)} style={{width:20,height:20,display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid #00a1ed',borderRadius:'100%',color:'#00a1ed',cursor:'pointer'}}>?</div>
                    {help&&<div style={{left:265,top:0,width:300,position:'absolute',backgroundColor:'white',boxShadow:'0 3px 6px 0 rgba(119, 119, 119, 0.35)',padding:20,borderRadius:10}}>
                        <p style={{margin:0}}>Berikut merupakan daftar karyawan yang di luar dari parameter pengukuran <b>Peta Talenta</b>. <br/><br/>Hal ini terjadi karena data karyawan tidak masuk kriteria peta. cek kembali data karyawan </p>
                    </div>}
                </div>
                <div className='div-flex'>
                    <Button  onClick={onSave}  className='btn-remove-capital btn-rounded' variant='contained' color="primary">{isedit?"Simpan":"Edit Data"}</Button>
                </div>
            </div>
            <DataTable
                className={classes.table}
                head={out_box.headers}
                body={()=>{
                    return out_box.rows.map((d,i)=>(
                        <TableRow >
                            {d.items.map((item,i2)=>(
                                <TableCell >
                                    {/* {item.text} */}
                                    <ContentEditable 
                                        disabled={!(item.id!==0&&isedit)}
                                        // disabled={!isedit}
                                        html={item.text===""?" ":item.text} 
                                        onKeyDown={onKeyPress}
                                        onPaste={(e)=>{
                                            var clipboardData, pastedData;
                                            e.stopPropagation();
                                            e.preventDefault();
                                            clipboardData = e.clipboardData || window.clipboardData;
                                            pastedData = clipboardData.getData('Text');
                                            onInput(pastedData,i,i2)
                                        }}
                                        onChange={(e)=>onInput(e.target.value,i,i2)} 
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))
                }}
                cardHead={<RenderHead filename={out_box.filename} filter_by_field_value_with_id={filter_by_field_value_with_id} filterEmployee={filterEmployee} {...props}  searchToggle={searchToggle} />}
                loading={general.isLoadingTable}
                pagination={out_box.info}
                togglePagination={togglePagination}
            />
        </div>
    )
}
