import React from 'react'
import {connect} from 'react-redux'
import {Modal} from '@material-ui/core'
import './style.css'
import Close from 'assets/icon/close.svg'
import {modalToggleReset,modalToggle} from 'redux/actions/general'
import Alert from './src/alert'
import ConfirmDelete from 'components/Modal/src/confirm_delete'
import ResetPass from './src/reset_password'
import {isMobile} from 'react-device-detect'
import FilterClient from './src/filter_client'
import TambahProyek from './src/tambah_proyek'
import TambahClient from './src/tambah_client'
import UploadJabatan from './src/upload_jabatan'
import UploadListTugas from './src/upload_list_tugas'
import Jabatan from './src/jabatan'
import Asumsi from './src/asumsi'
import KeteranganWla from './src/keterangan_wla'
import FilterRbk from './src/filter_rbk'
import KodeAkses from './src/kode_akses'
import Verifikasi_wla from './src/verifikasi_wla'
import {useSelector,useDispatch} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Add_talenta_project from './src/add_talenta_project'
import Klasifikasi from './src/klasifikasi'
import Change_file from './src/change_file'
import Norma from './src/norma'
import Talent_map from './src/talent_map'
import Choose_template from './src/choose_template'
import Filter_by_field from './src/filter_by_field'
import Save_template from './src/save_template'
import Add_client from './src/add_client'
import Add_admin from './src/add_admin'
import Add_credit from './src/add_credit'
import Detail_karyawan from './src/detail_karyawan'
import Confirm_rbk from './src/confirm_rbk'
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
          },
          width:'100%',
          marginBottom:15
  },
}));
export default function Index(props) {
    const dispatch = useDispatch()
    const general = useSelector(state => state.general)
    const {modal_data,modal_title,modal_component,modal_type,modal_action,modal_open,modal_size}=general
    const {token,profile}=props
    const classes=useStyles()
    const renderBody=()=>{
        switch (modal_type) {
            case 'confirm':
                return (
                    <>
                        {modal_component==='confirm_delete'&&<ConfirmDelete modalToggleReset={()=>dispatch(modalToggleReset())} modal_title={modal_title} token={token} profile={profile} modal_data={modal_data} modal_action={modal_action}/>}
                    </>
                )
            case 'confirm-rbk':
                return <Confirm_rbk modal_data={modal_data} modal_action={modal_action}/>
            case 'alert':
                return <Alert token={token}/>
            default:
                return(
                        <>
                            <div className='modal-header'>
                                    <h2>{modal_title}</h2>
                                    <img src={Close} style={{width:20}} onClick={()=>dispatch(modalToggleReset())}/>
                            </div>
                            <div className='modal-block'></div>
                            <div className='modal-body' >
                                   {modal_component==='reset_password'&&<ResetPass classes={classes} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='filter_client'&&<FilterClient classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='tambah_proyek'&&<TambahProyek classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='tambah_client'&&<TambahClient classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='upload_jabatan'&&<UploadJabatan classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='upload_list_tugas'&&<UploadListTugas classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='jabatan'&&<Jabatan classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='asumsi'&&<Asumsi classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='keterangan_wla'&&<KeteranganWla classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='filter_rbk'&&<FilterRbk classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='kode_akses'&&<KodeAkses classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='verifikasi_wla'&&<Verifikasi_wla classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='add_talenta_project'&&<Add_talenta_project classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='add_talenta_klasifikasi'&&<Klasifikasi classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='change_file'&&<Change_file classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='norma'&&<Norma classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='talent_map'&&<Talent_map classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='choose_template'&&<Choose_template classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='filter_by_field'&&<Filter_by_field classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='detail_karyawan'&&<Detail_karyawan classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='save_template'&&<Save_template classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='sa_add_client'&&<Add_client classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='sa_add_admin'&&<Add_admin classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='sa_add_credit'&&<Add_credit classes={classes} modalToggleReset={()=>dispatch(modalToggleReset())} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                            </div>
                        </>
                )
        }
    }
    return (
        <div>
            <Modal
                className='modal'
                open={modal_open}
                onClose={()=>dispatch(modalToggleReset())}
            >
                <div className='modal-content' style={{width:isMobile?'100%':modal_size,maxHeight:'95%',overflowY:'auto'}}>
                    {renderBody()}
                </div>
            </Modal>
        </div>
    )
}




