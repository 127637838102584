import React, { useState } from 'react'
import TextField from 'components/TextField'
import { useSelector,useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import * as actionTalenta from 'redux/actions/talenta'
export default function Save_template(props) {
    const dispatch = useDispatch()
    const talenta = useSelector(state => state.talenta)
    let {project_info}=talenta
    const [state, setstate] = useState({
        name:'',
        description:''
    })
    const handleDisable=()=>{
        if(state.name!==""&&state.description!==""){
            return false
        }else{
            return true
        }
    }
    const onSave=()=>{
        let data={
            id:0,
            projectId:project_info.id,
            name:state.name,
            description:state.description
        }
        dispatch(actionTalenta.postTemplate(data,props.profile.id))
    }
    return (
        <div>
            <TextField
                label="Nama Template"
                onChange={(e)=>setstate({...state,name:e.target.value})}
                value={state.name}
                color='primary'
                variant='outlined'
                size='small'
                name='name'
                style={{marginBottom:20}}
            />
            <TextField
                label="Deskripsi Template"
                onChange={(e)=>setstate({...state,description:e.target.value})}
                value={state.description}
                color='primary'
                variant='outlined'
                size='small'
                name='name'
                style={{marginBottom:20}}
                multiline
            />
            <br/>
            <div style={{textAlign:'right'}}>
                <Button onClick={onSave} disabled={handleDisable()} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
