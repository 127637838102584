import React,{useState} from 'react'
import Select from 'components/Select'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import { useSelector,useDispatch } from 'react-redux'
import * as actionSa from 'redux/actions/sa'
import {handleFile} from 'components/handleFile'
import TextField from 'components/TextField'

export default function Add_credit(props) {
    const sa = useSelector(state => state.sa)
    const dispatch = useDispatch()
    const [search, setsearch] = useState('')
    const [loading, setloading] = useState(false)
    const [textSelect,setTextSelect]=useState('Type company name')
    const [state, setstate] = useState({
        clientId:null,
        company:'',
        industry:null,
        phone:'',
        address:'',
        credit:0,
        file:null,
        filename:'',
        file_error:''
    })
    let {client_text_id}=sa
    const selectClient=async (value)=>{
        let res=await dispatch(actionSa.getDetailClient(value.id))
        if(res){
            setstate({
                ...state,
                clientId:value,
                company:res.data.company,
                industry:res.data.industry,
                phone:res.data.phone,
                address:res.data.address,

            })
        }
    }
    const searchToggle=_.debounce(async (value)=>{
        setsearch(value)

        if(value!==search&&value.length>0){
            setloading(true)
            let res=await dispatch(actionSa.getClientListTextId(`/0/1/2000/${value}`))
            if(_.isEmpty(res)){
                setTextSelect('No options')
            }
            setloading(false)
        }
        
    },1000)
    const handleDisable=()=>{
        return false
    }
    const onSave=async ()=>{
        let fd = new FormData()
        fd.append('Id',state.clientId.id)
        fd.append('Credit',state.credit)
        fd.append('Files',state.file)
        fd.append('UserId',props.profile.id)
        let res=await dispatch(actionSa.postClientCredit(fd))
        // if(res){
        //     props.modal_data.afterPost()
        // }
    }
    return (
        <div>
                <Select
                    color='primary'
                    noOptionsText={textSelect}
                    loading={loading}
                    disableClearable={true}
                    onChange={(event,value)=>selectClient(value)}
                    options={client_text_id}
                    getOptionLabel={(option) => option.text}
                    onInputChange={(event,e)=>searchToggle(e)}
                    label={<>Nama Klien</>}
                />

            <TextField
                label="Jumlah Kredit"
                onChange={(e)=>setstate({...state,credit:e.target.value})}
                value={state.credit}
                color='primary'
                variant='outlined'
                size='small'
                name='name'
                type="number"
                style={{marginBottom:20}}
            />
           
            <div style={{textAlign:'right'}}>
                <Button onClick={onSave} disabled={handleDisable()} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
