import React from 'react'
import Detail from 'views/talenta/Proyek/detail/detail'
export default function Detail_talent(props) {
    return (
        <div>
            <Detail
                {...props}
                tab_back="detail"
            />
        </div>
    )
}
