import React from 'react'
import {withRouter,useLocation } from 'react-router-dom'
import {Helmet} from "react-helmet";

function Index(props) {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
    const query = useQuery();
    React.useEffect(() => {
        let act=query.get('act')
        if(act==='wla'){
            window.location.assign('/wla')
            
        }
        if(act==='talenta'){
            window.location.assign('/manajementalenta')
        }
    }, [])
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Apps ONE GML</title>
                <meta
                    name="description"
                    content="Powered by ONE GML"
                /> 
                <link rel="canonical" href="https://apps.onegml.com" />
            </Helmet>
        </div>
    )
}

export default withRouter(Index)
