import * as actionTypes from "../constants/konsultan";
const initialState={
    konsultan_list:[],
    konsultan_list_drowdown:[],
    konsultan_pagination:{
        page:0,
        perPage:10,
        total:0
    },
    konsultan_detail:{
        userId:0,
        name:'',
        email:'',
        phone:'',
    },
}
export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.GET_KONSULTAN:
            return{
                ...state,
                konsultan_list:action.payload.konsultan_list,
                konsultan_pagination:action.payload.konsultan_pagination
            }
        case actionTypes.GET_KONSULTAN_DROPDOWN:
            return{
                ...state,
                konsultan_list_drowdown:action.payload,
            }
        case actionTypes.SET_KONSULTAN_DETAIL:
            return{
                ...state,
                konsultan_detail:{...state.konsultan_detail,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        
        case actionTypes.RESET_DETAIL:
            return{
                ...state,
                konsultan_detail:{
                    userId:0,
                    name:'',
                    email:'',
                    phone:'',
                },
            }
        default:
            return state;
    }
}

