import React from 'react'
import { Tooltip } from '@material-ui/core'
import { Scatter } from 'react-chartjs-2'
import ChartScatter from './ChartScatter'
export default function Titik(props) {
    let {peta_sebaran,getSebaranDots,project_info,filter_by_field_value}=props
    React.useEffect(() => {
        let data={
            items:filter_by_field_value,
            projectId:project_info.id,
            search:'*'
        }
        // alert('hii')
    //    getSebaranDots(data)
    }, [])
    return (
        <div>
            {peta_sebaran.length>0&&<div className='sebaran-wrapper'>
                <div className='sebaran-container'>
                    <h3 className='kinerja-text' >{project_info.yAxis}</h3>
                    <h3 className='potensial-text' >{project_info.xAxis}</h3>
                    <div className='box-sebaran-grid'>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',top:0,left:16}}><i>{peta_sebaran[2].cell.maxY!==0?peta_sebaran[2].cell.maxY:'~'}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',top:180,left:16}}><i>{peta_sebaran[2].cell.minY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:16,top:375}}><i>{peta_sebaran[0].cell.maxY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',bottom:40,left:16}}><i>{peta_sebaran[0].cell.minY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',bottom:10,left:40}}><i>{peta_sebaran[0].cell.minX}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:'35%',bottom:10}}><i>{peta_sebaran[0].cell.maxX}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:'66%',bottom:10}}><i>{peta_sebaran[6].cell.minX}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:'96%',bottom:10}}><i>{peta_sebaran[6].cell.maxX!==0?peta_sebaran[6].cell.maxX:'~'}</i></p>
                        
                        <div className='box-sebaran-div-dots' style={{backgroundColor:peta_sebaran[2].cell.bgColor}}>
                            <ChartScatter minX={peta_sebaran[2].cell.minX} items={peta_sebaran[2].items}/>
                        </div>
                        <div className='box-sebaran-div-dots' style={{backgroundColor:peta_sebaran[5].cell.bgColor}}>
                            <ChartScatter items={peta_sebaran[5].items} />

                        </div>
                        <div className='box-sebaran-div-dots' style={{backgroundColor:peta_sebaran[8].cell.bgColor}}>
                            <ChartScatter items={peta_sebaran[8].items} />

                        </div>
                        <div className='box-sebaran-div-dots' style={{backgroundColor:peta_sebaran[1].cell.bgColor}}>
                            <ChartScatter minX={peta_sebaran[1].cell.minX} items={peta_sebaran[1].items} />

                        </div>
                        <div className='box-sebaran-div-dots' style={{backgroundColor:peta_sebaran[4].cell.bgColor}}>
                            <ChartScatter items={peta_sebaran[4].items} />

                        </div>
                        <div className='box-sebaran-div-dots' style={{backgroundColor:peta_sebaran[7].cell.bgColor}}>
                            <ChartScatter items={peta_sebaran[7].items} />

                        </div>
                        <div className='box-sebaran-div-dots' style={{backgroundColor:peta_sebaran[0].cell.bgColor}}>
                            <ChartScatter minX={peta_sebaran[0].cell.minX} minY={peta_sebaran[0].cell.minY} items={peta_sebaran[0].items} />

                        </div>
                        <div className='box-sebaran-div-dots' style={{backgroundColor:peta_sebaran[3].cell.bgColor}}>
                            <ChartScatter minY={peta_sebaran[3].cell.minY} items={peta_sebaran[3].items} />
                        </div>
                        <div className='box-sebaran-div-dots' style={{backgroundColor:peta_sebaran[6].cell.bgColor}}>
                            <ChartScatter minY={peta_sebaran[6].cell.minY} items={peta_sebaran[6].items} />
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}
