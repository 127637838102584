import React,{useState,useEffect} from 'react'
import {Button,TextField} from '@material-ui/core'
import {useDispatch,useSelector} from 'react-redux'
import * as proyekAction from 'redux/actions/proyek'
export default function Asumsi(props) {
    const dispatch = useDispatch()
    let {modal_data}=props
    const [state, setstate] = useState({
        nama:'',
        jumlah_hari:0,
        jam_kerja:0,
        allowance:0,
        id:0
    })
    useEffect(() => {
        if(modal_data.data!==null){
            setstate({
                nama:modal_data.data.name,
                jumlah_hari:modal_data.data.annualWorkingDay,
                jam_kerja:modal_data.data.hoursPerDay,
                allowance:modal_data.data.allowance,
                id:modal_data.data.id
            })
        }
    }, [])
    const onClickSimpan=async ()=>{
        let data={
            id:state.id,
            name:state.nama,
            hoursPerDay:parseFloat(state.jam_kerja),
            annualWorkingDay:parseFloat(state.jumlah_hari),
            allowance:parseFloat(state.allowance),
            userId:props.profile.id,
            projectId:modal_data.projectId
        }
        let res
        if(modal_data.action==='add'){
            res=await dispatch(proyekAction.postWke(data))
        }
        if(modal_data.action==='edit'){
            res=await dispatch(proyekAction.putWke(data))
        }
        if(res){
            dispatch(proyekAction.getWke(`/${modal_data.projectId}`))
        }
        // console.log(`data`, data)
    }
    const renderDisable=()=>{
        if(modal_data.action==='detail'){
            return true
        }else{
            return false
        }
    }
    const {classes}=props
    return (
        <div>
            <TextField
                label="Nama Jenis WKE"
                variant='outlined'
                value={state.nama}
                size='small'
                className={classes.textField}
                disabled={renderDisable()}
                onChange={(e)=>setstate({...state,nama:e.target.value})}
            />
            <TextField
                label="Jumlah hari kerja / Tahun"
                variant='outlined'
                value={state.jumlah_hari}
                size='small'
                className={classes.textField}
                disabled={renderDisable()}
                onChange={(e)=>setstate({...state,jumlah_hari:e.target.value})}
            />
            <TextField
                label="Jam Kerja / Hari"
                variant='outlined'
                value={state.jam_kerja}
                type="number"
                size='small'
                className={classes.textField}
                disabled={renderDisable()}
                onChange={(e)=>setstate({...state,jam_kerja:e.target.value})}
            />
            <TextField
                label="Allowance / Hari ( dalam menit)"
                variant='outlined'
                value={state.allowance}
                size='small'
                className={classes.textField}
                disabled={renderDisable()}
                onChange={(e)=>setstate({...state,allowance:e.target.value})}
            />
            <div style={{textAlign:'right'}}>
                <Button disabled={renderDisable()} size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
