import React from 'react'

export default function Nama(props) {
    let {peta_sebaran,getSebaranDots,detailKaryawan,project_info,filter_by_field_value,sebaran_label}=props
    React.useEffect(() => {
        let data={
            items:filter_by_field_value,
            projectId:project_info.id,
            search:'*'
        }
    //    getSebaranDots(data)
    }, [])
    // console.log(`peta_sebaran`, peta_sebaran)
    return (
        <div>
            {peta_sebaran.length>0&&
            <div className='sebaran-wrapper'>
                <div className='sebaran-container'>
                <h3 className='kinerja-text' >{project_info.yAxis}</h3>
                    <h3 className='potensial-text' >{project_info.xAxis}</h3>
                    <div className='box-sebaran-grid'>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',top:0,left:16}}><i>{peta_sebaran[2].cell.maxY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',top:180,left:16}}><i>{peta_sebaran[2].cell.minY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:16,top:375}}><i>{peta_sebaran[0].cell.maxY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',bottom:40,left:16}}><i>{peta_sebaran[0].cell.minY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',bottom:10,left:40}}><i>{peta_sebaran[0].cell.minX}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:'35%',bottom:10}}><i>{peta_sebaran[0].cell.maxX}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:'66%',bottom:10}}><i>{peta_sebaran[6].cell.minX}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:'96%',bottom:10}}><i>{peta_sebaran[6].cell.maxX}</i></p>

                        <div className='box-sebaran-nama' style={{backgroundColor:peta_sebaran[2].cell.bgColor}}>
                            <div style={{width:'100%',padding:10,backgroundColor:'white',border:'1px solid #cccccc'}}>
                                <p style={{margin:0,fontSize:12,fontWeight:600,color:peta_sebaran[2].cell.bgColor}}>{peta_sebaran[2].cell.label}</p>
                            </div>
                            {peta_sebaran[2].items.map((item,i)=>(
                                <div onClick={()=>detailKaryawan({...item,boxName:peta_sebaran[2].cell.label,label:sebaran_label})} style={{padding:'10px 10px 0 10px'}} key={i}>
                                    <p style={{margin:0,fontSize:12,fontWeight:600,color:'#252525'}}>{item.personal2}</p>
                                    <div style={{width:'100%',margin:'10px 0 0 0',height:1,backgroundColor:'#252525'}}></div>
                                </div>
                            ))}
                        </div>
                        <div className='box-sebaran-nama' style={{backgroundColor:peta_sebaran[5].cell.bgColor}}>
                        <div style={{width:'100%',padding:10,backgroundColor:'white',border:'1px solid #cccccc'}}>
                                <p style={{margin:0,fontSize:12,fontWeight:600,color:peta_sebaran[5].cell.bgColor}}>{peta_sebaran[5].cell.label}</p>
                            </div>
                            {peta_sebaran[5].items.map((item,i)=>(
                                <div onClick={()=>detailKaryawan({...item,boxName:peta_sebaran[5].cell.label,label:sebaran_label})} style={{padding:'10px 10px 0 10px'}} key={i}>
                                    <p style={{margin:0,fontSize:12,fontWeight:600,color:'#252525'}}>{item.personal2}</p>
                                    <div style={{width:'100%',margin:'10px 0 0 0',height:1,backgroundColor:'#252525'}}></div>
                                </div>
                            ))}
                        </div>
                        <div className='box-sebaran-nama' style={{backgroundColor:peta_sebaran[8].cell.bgColor}}>
                        <div style={{width:'100%',padding:10,backgroundColor:'white',border:'1px solid #cccccc'}}>
                                <p style={{margin:0,fontSize:12,fontWeight:600,color:peta_sebaran[8].cell.bgColor}}>{peta_sebaran[8].cell.label}</p>
                            </div>
                            {peta_sebaran[8].items.map((item,i)=>(
                                <div onClick={()=>detailKaryawan({...item,boxName:peta_sebaran[8].cell.label,label:sebaran_label})} style={{padding:'10px 10px 0 10px'}} key={i}>
                                    <p style={{margin:0,fontSize:12,fontWeight:600,color:'#252525'}}>{item.personal2}</p>
                                    <div style={{width:'100%',margin:'10px 0 0 0',height:1,backgroundColor:'#252525'}}></div>
                                </div>
                            ))}
                        </div>
                        <div className='box-sebaran-nama' style={{backgroundColor:peta_sebaran[1].cell.bgColor}}>
                        <div style={{width:'100%',padding:10,backgroundColor:'white',border:'1px solid #cccccc'}}>
                                <p style={{margin:0,fontSize:12,fontWeight:600,color:peta_sebaran[1].cell.bgColor}}>{peta_sebaran[1].cell.label}</p>
                            </div>
                            {peta_sebaran[1].items.map((item,i)=>(
                                <div onClick={()=>detailKaryawan({...item,boxName:peta_sebaran[1].cell.label,label:sebaran_label})} style={{padding:'10px 10px 0 10px'}} key={i}>
                                    <p style={{margin:0,fontSize:12,fontWeight:600,color:'#252525'}}>{item.personal2}</p>
                                    <div style={{width:'100%',margin:'10px 0 0 0',height:1,backgroundColor:'#252525'}}></div>
                                </div>
                            ))}
                        </div>
                        <div className='box-sebaran-nama' style={{backgroundColor:peta_sebaran[4].cell.bgColor}}>
                        <div style={{width:'100%',padding:10,backgroundColor:'white',border:'1px solid #cccccc'}}>
                                <p style={{margin:0,fontSize:12,fontWeight:600,color:peta_sebaran[4].cell.bgColor}}>{peta_sebaran[4].cell.label}</p>
                            </div>
                            {peta_sebaran[4].items.map((item,i)=>(
                                <div onClick={()=>detailKaryawan({...item,boxName:peta_sebaran[4].cell.label,label:sebaran_label})} style={{padding:'10px 10px 0 10px'}} key={i}>
                                    <p style={{margin:0,fontSize:12,fontWeight:600,color:'#252525'}}>{item.personal2}</p>
                                    <div style={{width:'100%',margin:'10px 0 0 0',height:1,backgroundColor:'#252525'}}></div>
                                </div>
                            ))}
                        </div>
                        <div className='box-sebaran-nama' style={{backgroundColor:peta_sebaran[7].cell.bgColor}}>
                        <div style={{width:'100%',padding:10,backgroundColor:'white',border:'1px solid #cccccc'}}>
                                <p style={{margin:0,fontSize:12,fontWeight:600,color:peta_sebaran[7].cell.bgColor}}>{peta_sebaran[7].cell.label}</p>
                            </div>
                            {peta_sebaran[7].items.map((item,i)=>(
                                <div onClick={()=>detailKaryawan({...item,boxName:peta_sebaran[7].cell.label,label:sebaran_label})} style={{padding:'10px 10px 0 10px'}} key={i}>
                                    <p style={{margin:0,fontSize:12,fontWeight:600,color:'#252525'}}>{item.personal2}</p>
                                    <div style={{width:'100%',margin:'10px 0 0 0',height:1,backgroundColor:'#252525'}}></div>
                                </div>
                            ))}
                        </div>
                        <div className='box-sebaran-nama' style={{backgroundColor:peta_sebaran[0].cell.bgColor}}>
                        <div style={{width:'100%',padding:10,backgroundColor:'white',border:'1px solid #cccccc'}}>
                                <p style={{margin:0,fontSize:12,fontWeight:600,color:peta_sebaran[0].cell.bgColor}}>{peta_sebaran[0].cell.label}</p>
                            </div>
                            {peta_sebaran[0].items.map((item,i)=>(
                                <div onClick={()=>detailKaryawan({...item,boxName:peta_sebaran[0].cell.label,label:sebaran_label})} style={{padding:'10px 10px 0 10px'}} key={i}>
                                    <p style={{margin:0,fontSize:12,fontWeight:600,color:'#252525'}}>{item.personal2}</p>
                                    <div style={{width:'100%',margin:'10px 0 0 0',height:1,backgroundColor:'#252525'}}></div>
                                </div>
                            ))}
                        </div>
                        <div className='box-sebaran-nama' style={{backgroundColor:peta_sebaran[3].cell.bgColor}}>
                        <div style={{width:'100%',padding:10,backgroundColor:'white',border:'1px solid #cccccc'}}>
                                <p style={{margin:0,fontSize:12,fontWeight:600,color:peta_sebaran[3].cell.bgColor}}>{peta_sebaran[3].cell.label}</p>
                            </div>
                            {peta_sebaran[3].items.map((item,i)=>(
                                <div onClick={()=>detailKaryawan({...item,boxName:peta_sebaran[3].cell.label,label:sebaran_label})} style={{padding:'10px 10px 0 10px'}} key={i}>
                                    <p style={{margin:0,fontSize:12,fontWeight:600,color:'#252525'}}>{item.personal2}</p>
                                    <div style={{width:'100%',margin:'10px 0 0 0',height:1,backgroundColor:'#252525'}}></div>
                                </div>
                            ))}
                        </div>
                        <div className='box-sebaran-nama' style={{backgroundColor:peta_sebaran[6].cell.bgColor}}>
                        <div style={{width:'100%',padding:10,backgroundColor:'white',border:'1px solid #cccccc'}}>
                                <p style={{margin:0,fontSize:12,fontWeight:600,color:peta_sebaran[6].cell.bgColor}}>{peta_sebaran[6].cell.label}</p>
                            </div>
                            {peta_sebaran[6].items.map((item,i)=>(
                                <div onClick={()=>detailKaryawan({...item,boxName:peta_sebaran[6].cell.label,label:sebaran_label})} style={{padding:'10px 10px 0 10px'}} key={i}>
                                    <p style={{margin:0,fontSize:12,fontWeight:600,color:'#252525'}}>{item.personal2}</p>
                                    <div style={{width:'100%',margin:'10px 0 0 0',height:1,backgroundColor:'#252525'}}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}
