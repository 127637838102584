import React from 'react'
import '../style.css'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import {Button } from '@material-ui/core'
const themeButton = createTheme({ 
    palette: { 
        primary: {
            main:'#ffc466',
        },
        secondary: {
            main:'#ff6e79',
            contrastText: '#FFFFFF',
        }
    } 
})

export default function Confirm_delete(props) {
    let {modal_data,modal_action,profile,token}=props
    return (
        <div>
            <div className='confirm-container'>
                <h4>Delete {modal_data.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: modal_data.msg }}></div>
                <div className='card-footer'>
                    <MuiThemeProvider theme={themeButton}>
                        <Button onClick={()=>props.modalToggleReset()}   size='small' color='primary' className='btn-remove-capital'>Tidak, batalkan</Button>
                        <Button onClick={()=>modal_data.action()}   size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded '>Ya, hapus</Button>
                    </MuiThemeProvider>
                </div>
            </div>
        </div>
    )
}

