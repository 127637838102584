import {apiCall} from 'service/apiCall'
import {setLoading,modalToggle,setLoadingTable} from './general'
import Cookie from 'universal-cookie'
import {get} from 'lodash'
import * as actionTypes from 'redux/constants/responden'
const cookie=new Cookie
const token=cookie.get('login_cookie')
export const setTab=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_TAB,
        payload:payload
    })
}
export const resetResponden=()=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_RESPONDEN,
    })
}
export const setRespondenColumn=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.GET_RESPONDENT_COLUMN,
        payload:payload
    })
}
export const setJabatanOptions=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.GET_JOB_TITLE,
        payload:payload
    })
}
export const setRespondenInfo=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_RESPONDEN_INFO,
        payload:payload
    })
}
export const getWorksUnit=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/respondent/workunit${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))

        dispatch({
            type:actionTypes.GET_WORKS_UNIT,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const getJobs=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/respondent/jobtitle${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        
        dispatch({
            type:actionTypes.GET_JOB_TITLE,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const postRespondenInfo=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/respondent/info`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(setRespondenInfo({projectId:res.data.projectId}))
            dispatch(setRespondenInfo({jobTitle:res.data.jobTitle}))
            dispatch(setRespondenInfo({shortName:res.data.shortname}))
            dispatch(setRespondenInfo({workUnit:res.data.workUnit}))
            dispatch(setRespondenInfo({hirarki_id:res.data.hierarchy}))
            dispatch(setRespondenInfo({tasks:res.data.tasks}))
            dispatch(setRespondenInfo({jobDetailId:res.data.jobDetailId}))
            dispatch(setRespondenInfo({mcb:res.data.mcb}))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Data diri berhasil tersimpan",
                modal_component: "data_diri",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const getJobsDetail=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/respondent/info${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        // dispatch(setRespondenInfo({employees:res.data.employees}))
        // dispatch(setRespondenInfo({location:res.data.location}))
        // dispatch(setRespondenInfo({batch:res.data.batch}))
        
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const getRespondenColumns=(username)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/respondent/columns/${username}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        let new_column=[]
        res.data.map((d)=>{
            new_column.push({
                column:d,
                options:[],
                value:null
            })
        })
        dispatch({
            type:actionTypes.GET_RESPONDENT_COLUMN,
            payload:new_column
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const getRespondenColumnsOptions=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/list/options${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        dispatch({
            type:actionTypes.GET_RESPONDENT_COLUMN,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}

export const getDinamycOption=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/respondent/nextoptions${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        
        // dispatch({
        //     type:actionTypes.GET_JOB_TITLE,
        //     payload:res.data
        // })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}