import React,{useState} from 'react'
import { Button } from '@material-ui/core'
import {handleFile} from 'components/handleFile'
import { createTheme,MuiThemeProvider } from '@material-ui/core'
import { useSelector,useDispatch } from 'react-redux'
import * as actionTalenta from 'redux/actions/talenta'
const themeButton = createTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#ff7165',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Change_file(props) {
    const talenta = useSelector(state => state.talenta)
    const dispatch = useDispatch()
    let {project_info}=talenta
    const [state, setstate] = useState({
        filename:'',
        file:null,
        file_error:'',
    })
    const handleFiles=async (evt)=>{
        let handle=await handleFile(evt)
        if(handle.file_error===null){
            setstate({
                ...state,
                filename:handle.file_name,
                file:handle.file,
                file_error:''
            })
        }else{
            setstate({
                ...state,
                filename:'',
                file:null,
                file_error:handle.file_error
            })
        }
    }
    const handleDisable=()=>{
        if(state.filename!==""){
            return false
        }else{
            return true
        }
    }
    const onSave=async ()=>{
        let fd = new FormData()
        fd.append('Id',project_info.id)
        fd.append("Files",state.file)
        fd.append("UserId",props.profile.id)
        let res=await dispatch(actionTalenta.postChangeFileProject(fd))
        if(res){
            props.modal_data.afterPost()
        }
    }
    return (
        <div>
            <p style={{marginTop:0,color:'#777777',fontSize:14}}>Data karyawan di dalam file yang di-upload akan ditambahkan ke dalam project <span style={{color:'#252525',fontWeight:'bold'}}>{project_info.name}</span></p>
            <p style={{marginTop:0,marginBottom:5,color:'#777777',fontSize:14}}><span style={{color:'#252525',fontWeight:'bold'}}>File peta talenta</span></p>
            <div className='div-flex div-space-between div-align-center'>
                <p style={{marginTop:0,fontWeight:'bold',color:'#aaaaaa',fontSize:12,maxWidth:300}} >{state.filename!==""?state.filename:"Belum ada file yang diupload"}</p>
                <Button component="label" variant="text" color="primary" className="btn-remove-capital" size="small">
                    {state.filename!==""?"Ganti File":"Upload File"}
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e)=>handleFiles(e)}
                    />
                </Button>
            </div>
            <div style={{textAlign:'right',marginTop:5}}>
                <MuiThemeProvider theme={themeButton}>
                    <Button onClick={props.modalToggleReset}  className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Batal</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={onSave} disabled={handleDisable()} className='btn-rounded btn-remove-capital' color="secondary" variant="contained" size="small">Simpan</Button>
                </MuiThemeProvider>
                
            </div>
            <br/>
        </div>
    )
}
