import React,{useState} from 'react'
import Select from 'components/Select'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import { useSelector,useDispatch } from 'react-redux'
import * as actionSa from 'redux/actions/sa'
import {handleFile} from 'components/handleFile'
import TextField from 'components/TextField'

export default function Add_client(props) {
    const sa = useSelector(state => state.sa)
    const dispatch = useDispatch()
    const [search, setsearch] = useState('')
    const [loading, setloading] = useState(false)
    const [textSelect,setTextSelect]=useState('Type company name')
    const [state, setstate] = useState({
        clientId:null,
        company:'',
        industry:null,
        phone:'',
        address:'',
        credit:0,
        file:null,
        filename:'',
        file_error:''
    })
    let {client_text_id}=sa
    const selectClient=async (value)=>{
        let res=await dispatch(actionSa.getDetailClient(value.id))
        if(res){
            setstate({
                ...state,
                clientId:value,
                company:res.data.company,
                industry:res.data.industry,
                phone:res.data.phone,
                address:res.data.address,

            })
        }
        console.log(`res.data`, res.data)
    }
    const searchToggle=_.debounce(async (value)=>{
        setsearch(value)

        if(value!==search&&value.length>0){
            setloading(true)
            let res=await dispatch(actionSa.getClientListTextId(`/0/1/2000/${value}`))
            if(_.isEmpty(res)){
                setTextSelect('No options')
            }
            setloading(false)
        }
        
    },1000)
    const handleFiles=async (evt)=>{
        let handle=await handleFile(evt)
        if(handle.file_error===null){
            setstate({
                ...state,
                filename:handle.file_name,
                file:handle.file,
                file_error:''
            })
        }else{
            setstate({
                ...state,
                filename:'',
                file:null,
                file_error:handle.file_error
            })
        }
    }
    const handleDisable=()=>{
        return false
    }
    const onSave=async ()=>{
        let fd = new FormData()
        fd.append('Id',state.clientId.id)
        fd.append('Credit',state.credit)
        fd.append('Files',state.file)
        fd.append('UserId',props.profile.id)
        let res=await dispatch(actionSa.postClientCredit(fd))
        if(res){
            props.modal_data.afterPost()
        }
        console.log(`state`, state)
    }
    return (
        <div>
            <div className='div-flex div-space-between div-align-center'>
                <div style={{width:'70%'}}>
                    <Select
                        color='primary'
                        noOptionsText={textSelect}
                        loading={loading}
                        disableClearable={true}
                        onChange={(event,value)=>selectClient(value)}
                        options={client_text_id}
                        getOptionLabel={(option) => option.text}
                        onInputChange={(event,e)=>searchToggle(e)}
                        label={<>Nama Klien</>}
                    />
                </div>
                <Button onClick={props.modal_data.addClient} style={{marginBottom:20}} variant="text" className="btn-remove-capital" size="small" color="primary">Tambah Klien</Button>

            </div>
            <TextField
                label="Jumlah Kredit"
                onChange={(e)=>setstate({...state,credit:e.target.value})}
                value={state.credit}
                color='primary'
                variant='outlined'
                size='small'
                name='name'
                type="number"
                style={{marginBottom:20}}
            />
            <p className='semi-bold'>File logo perusahaan</p>
            <div className='div-flex div-space-between div-align-center'>
                <p style={{maxWidth:300,fontWeight:state.filename!==""?'bold':'normal'}} className='semi-bold-without-margin'>{state.filename!==""?state.filename:"Belum ada file yang diupload"}</p>
                <Button component="label" variant="text" color="primary" className="btn-remove-capital" size="small">
                    {state.filename!==""?"Ganti File":"Upload File"}
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e)=>handleFiles(e)}
                    />
                </Button>
            </div>
            <br/>
            <div style={{textAlign:'right'}}>
                <Button onClick={onSave} disabled={handleDisable()} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
