import React,{useState,useEffect} from 'react'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,
    FormHelperText,InputAdornment,Table,TableHead,TableCell,TableRow,TableBody,FormControlLabel,Checkbox,makeStyles} from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import {modalToggle,modalToggleReset} from 'redux/actions/general'
import {useSelector,useDispatch} from 'react-redux'
import Edit from 'assets/icon/edit.svg'
import close from 'assets/icon/close.svg'
import Eye from 'assets/icon/eye.svg'

import SearchImg from 'assets/icon/Search.png'
import {debounce} from 'lodash'
import * as clientAction from 'redux/actions/client'
import * as proyekAction from 'redux/actions/proyek'

import Skeleton from 'components/Skeleton'
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import moment from 'moment'
import InputMask from 'react-input-mask'
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
            },
            width:'100%',
            marginBottom:15
    },
    checkbox:{
        margin:0,
        [`& root`]: {
        margin:0,
        },
    },
    search: {
    [`& fieldset`]: {
        borderRadius: 8,
        height:30,
        margin: theme.spacing(1),
        },
        width:150,
        height:10,
        marginBottom:40
    },
    input:{
        fontSize:14,
        margin:3      
        }
}));
export default function Detail(props) {
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const client = useSelector(state => state.client)
    const general = useSelector(state => state.general)
    const proyek = useSelector(state => state.proyek)
    let {client_detail,list_industry}=client
    let {proyek_list,proyek_pagination}=proyek

    let {tab}=props
    useEffect(() => {
        dispatch(clientAction.getProjectByClient(`/${client_detail.clientId}/1/10/*`))
    }, [])
    const classes=useStyles()
    const back=()=>{
        props.tabToggle('detail')
    }
    const handleDisable=()=>{
        if(props.tab==='detail_client'){
            return true
        }else{
            return false
        }
    }
    const handleDisableBtn=()=>{
        if(client_detail.industry!==null){
            return false
        }else{
            return true
        }
    }
    const onClickSimpan=async ()=>{
        if(tab==='add'||tab==='edit'){
            let data={
                clientId:client_detail.clientId,
                company:client_detail.company,
                industryId:client_detail.industry.id,
                phone:client_detail.phone,
                address:client_detail.address,
                userId:props.profile.id,
            }
            dispatch(clientAction.putClient(data))
        }else{
            props.tabToggle('edit')
        }

        
        
    }
    const renderTitle=()=>{
        if(tab==='add'){
            return 'Tambah klien '
        }
        if(tab==='edit'){
            return 'Edit klien '
        }
        if(tab==='detail'){
            return 'Detail klien '
        }
    }

    const tambahProyek=async ()=>{
        let res= await dispatch(clientAction.getClientListForDropDown())
        res= await dispatch(proyekAction.getHierarchyListForDropDown())
        if(res){
            dispatch(modalToggle({
                modal_open: true,
                modal_title: `Tambah Proyek`,
                modal_component: "tambah_proyek",
                modal_size:500,
                modal_data:null,
                modal_action:'tambah_proyek'
            }))
        }
        
    }
    const renderColor=(number)=>{
        if(number>=0){
            return '#a8e090'
        }else{
            return '#ff6e79'
        }
    }
    const renderColorStatus=(status)=>{
        if(status==='Proses'){
            return '#ffb865'
        }else if(status==='Menunggu'){
            return '#ff6e79'
        }else{
            return '#a8e090'
        }
    }
    const detail_proyek=async (data)=>{
        let res= await dispatch(proyekAction.getDetailProject(`/${data.id}`))
        
        if(res){
            props.tabToggle('detail')

        }
    }
    const togglePagination=(page,pageLength)=>{
        dispatch(clientAction.getProjectByClient(`/${client_detail.clientId}/${page}/${pageLength}/${search!==''?search:'*'}`))
    }
    const searchToggle=debounce(async (value)=>{
        setSearch(value)
        await dispatch(clientAction.getProjectByClient(`/${client_detail.clientId}/1/10/${value!==''?value:'*'}`))
    },1000)
    const action_delete=async (data)=>{
        // let res=await dispatch(deleteClient(`/${data.clientId}/${props.profile.id}`))
        // if(res){
        //     dispatch(getClientList(`/0/1/10/*`))
        // }
        
    }
    const delete_proyek=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Wla',
                action:()=>action_delete(data),
                msg:`<p>Apakah anda yakin menghapus ${data.name} </p>`
            },
            modal_action:'delete_wla'
        }))
    }
    return (
        <div>
            <div className='head-section'>
                <div>
                    <h4 >{renderTitle()}</h4>
                </div>
                <div className='div-flex'>
                    <Button onClick={back} variant='outlined' color='primary' className='btn-rounded btn-remove-capital btn-action btn-outlined' >Kembali</Button>
                    {tab==='add'&&<Button disabled={handleDisableBtn()} onClick={onClickSimpan} variant='contained' color='primary' className='btn-rounded btn-remove-capital btn-action' >Simpan</Button>}
                    {tab==='edit'&&<Button onClick={onClickSimpan} variant='contained' color='primary' className='btn-rounded btn-remove-capital btn-action' >Simpan</Button>}
                    {tab==='detail'&&<Button onClick={onClickSimpan} variant='contained' color='primary' className='btn-rounded btn-remove-capital btn-action' >Edit</Button>}

                    
                </div>
            </div>
            <div className='card-content' style={{padding:20}}>
                <p className='semi-bold'>Informasi Perusahaan Klien</p>
                <div className='div-flex div-space-between' style={{justifyContent:'space-between'}}>
                    <div style={{width:'45%'}}>
                        <TextField
                            label={<>Nama Perusahaan</>}
                            variant='outlined'
                            value={client_detail.company}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(clientAction.setDetailClient({company:e.target.value}))}

                        />
                        <AutoCompleteSelect
                            onChange={(event,value)=>dispatch(clientAction.setDetailClient({industry:value}))}
                            options={list_industry}
                            value={client_detail.industry}
                            getOptionLabel={(option) => option.text}
                            label={<>Industry</>}
                            disabled={handleDisable()}
                        />
                                               
                        
                        
                    </div>
                    <div style={{width:'45%'}}>
                        <InputMask
                            maskChar={null}
                            mask="9999-9999-9999-9999"
                            value={client_detail.phone}
                            disabled={handleDisable()}

                            onChange={(e)=>dispatch(clientAction.setDetailClient({phone:e.target.value}))}
                        >
                        {() =>
                            <TextField
                                label={<>Phone No.</>}
                                // type="number"
                                variant='outlined'
                                
                                size='small'
                                className={classes.textField}
                                

                            />
                        }
                        </InputMask>

                        
                         <TextField
                            label={<>Alamat</>}
                            // type="number"
                            variant='outlined'
                            value={client_detail.address}
                            size='small'
                            disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(clientAction.setDetailClient({address:e.target.value}))}

                        />

                    </div>
                </div>
            </div>
            <br/>
            <div className='head-section'>
                <div>
                    <h4 >Daftar Proyek WLA</h4>
                </div>
                <div className='div-flex'>
                    <Button onClick={tambahProyek} variant='contained' color='primary' className='btn-rounded btn-remove-capital btn-action' >Tambah Proyek</Button>
                    
                </div>
            </div>
            <div className='card-content' style={{padding:20}}>
            <div >
                <div className='card-table__head'>
                    <TextField
                        placeholder="Cari"
                        variant='outlined'
                        size='small'
                        inputProps={{
                            className:classes.input
                        }}
                        InputLabelProps={{shrink: false}}
                        className={classes.search}
                        onChange={(e)=>searchToggle(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <img alt="search" src={SearchImg} style={{width:15}}/>
                            </InputAdornment>
                            ),
                        }}
                    />
                    <Button onClick={null} color="primary" className='btn-remove-capital' variant="text">Filter</Button>
                </div>
                <div className='card-table__content'>
                <Table  size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left" style={{width:10}}>No.</TableCell>
                        <TableCell align="left"style={{width:150}} >Nama Proyek</TableCell>
                        <TableCell align="left"  style={{width:150}}>Nama Perusahaan</TableCell>
                        <TableCell align="left" style={{width:10}}>Jumlah Jabatan</TableCell>
                        <TableCell align="left" style={{width:10}}>Jumlah SDM</TableCell>
                        <TableCell align="left" style={{width:10}}>Kebutuhan SDM</TableCell>
                        <TableCell align="left" style={{width:10}}>Surplus/Deficit</TableCell>
                        <TableCell align="center" style={{width:10}}>Status</TableCell>
                        <TableCell align="center">Aksi</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {general.isLoadingTable?
                        <Skeleton count={9}/>
                    :
                    proyek_list.length<1?
                    <TableRow>
                        <TableCell colSpan={9} style={{textAlign:'center'}}>Belum ada proyek</TableCell>
                    </TableRow>
                    :proyek_list.map((data,i)=>(
                        <TableRow key={i}>
                            <TableCell >{i+1}</TableCell>
                            <TableCell >{data.name}</TableCell>
                            <TableCell>{data.company}</TableCell>
                            <TableCell align='center'>{data.jobTitles}</TableCell>
                            <TableCell align='center'>{data.sdm}</TableCell>
                            <TableCell align='center'>{data.need}</TableCell>
                            <TableCell align='center' style={{color:renderColor(data.surplus),fontWeight:'bold'}}>{data.surplus}</TableCell>
                            <TableCell align='center'  style={{color:renderColorStatus(data.status),fontWeight:'bold'}}>{data.status}</TableCell>
                            <TableCell align="center">
                                <div >
                                <img src={Eye} onClick={()=>detail_proyek(data)} className='icon-action'/>
                                {/* <img src={Edit} onClick={()=>edit_proyek(data)} className='icon-action'/> */}
                                <img src={close} onClick={()=>delete_proyek(data)} className='icon-action'/>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))
                    
                    }
                    </TableBody>
                </Table>
                <div className='card-table__pagination'>
                {proyek_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={proyek_pagination.page}
                            pageLength={proyek_pagination.perPage}
                            totalRecords={proyek_pagination.total}
                            totalRecords={proyek_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    }
            </div>
                </div>
            </div>
            </div>
        </div>
    )
}
