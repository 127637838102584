import React from 'react'
import { useSelector } from 'react-redux'
import AutoCompleteSelect from 'components/Select'
import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import * as actionTalenta from 'redux/actions/talenta'
export default function Filter_employee(props) {
    const dispatch = useDispatch()
    const talenta = useSelector(state => state.talenta)
    const [filter_by_field, setfilter_by_field] = React.useState([])
    React.useEffect(() => {
        let new_filter=[]
        talenta.filter_by_field.map((d)=>{
            new_filter.push({...d,value:[]})
        })
        if(talenta.filter_by_field_value_with_id.length>0){
            setfilter_by_field(talenta.filter_by_field_value_with_id)
        }else{
            setfilter_by_field(new_filter)

        }
    }, [])
    const onChange=(value,i)=>{
        filter_by_field[i].value=value
        setfilter_by_field([...filter_by_field])
    }
    const onSave=async ()=>{
        let new_item=[]

        filter_by_field.map((d)=>{
            if(d.value.length>0){
                new_item.push({
                    id:d.filter.id,
                    filters:d.value.map((i)=>i.text)
                })

            }
        })
        dispatch(actionTalenta.setTalenta({filter_by_field_value:new_item}))
        dispatch(actionTalenta.setTalenta({filter_by_field_value_with_id:filter_by_field}))
        props.modal_data.action(new_item)
    }
    const handleDisable=()=>{
        return false
    }
    return (
        <div>
            {filter_by_field.map((d,i)=>(
                <AutoCompleteSelect
                    key={i}
                    onChange={(event,value)=>onChange(value,i)}
                    options={d.items}
                    value={d.value}
                    getOptionLabel={(option) => option.text}
                    label={d.filter.text}
                    disableClearable
                    style={{marginBottom:10}}
                    multiple

                />
            ))}
            <div style={{textAlign:'right'}}>
                <Button onClick={onSave} disabled={handleDisable()} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
