import React,{useState,useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Button} from '@material-ui/core'

import Bg from 'assets/image/done_wla.png'
import './style.css'
import {logout} from 'redux/actions/auth'
export default function Selesai(props) {
    
    const dispatch=useDispatch()
    const clickKeluar=async ()=>{
        await props.settab('data_diri')
        dispatch(logout('wla'))
    }
    
    return (
        <div>
            <div className='welcome-wrapper'>
                <div className='welcome-text hide-mobile'>
                    <div className='hide-mobile'>
                    <br/><br/><br/><br/><br/>
                    </div>
                    <div className='welcome-mobile hide-mobile'>
                        <h2>Hi, <span>{props.responden_info.name}</span></h2>
                        <p>Anda telah selesai melakukan proses Workload Analysis. Terimakasih untuk waktunya selamat beraktifitas kembali</p>
                        <Button onClick={clickKeluar}  className='hide-mobile' style={{textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Kembali ke halaman Login</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <Button onClick={clickKeluar}  className='hide-mobile' style={{textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Download Hasil</Button> */}
                    </div>
                   

                </div>
                <div className='hide-mobile' style={{textAlign:'center'}}>
                    <img src={Bg} style={{width:'70%'}}/>
                </div>
                <div className='show-mobile' style={{height:'100%'}}>
                <div className=' welcome-text' style={{margin:'auto',height:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
                    <div className='welcome-mobile' style={{textAlign:'center'}}>
                        <div style={{textAlign:'center'}}>
                        <img src={Bg} style={{width:'80%'}}/>
                        </div>
                        
                        <br/><br/>
                        <h2>Hi, <span>{props.profile.userName}</span></h2>
                        <p>Anda telah selesai melakukan proses Workload Analysis. Terimakasih untuk waktunya selamat beraktifitas kembali</p>
                        <br/>
                        <div style={{textAlign:'center'}}>
                            <Button onClick={clickKeluar}   style={{width:150,textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Keluar</Button>
                            {/* <Button onClick={clickKeluar}   style={{textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Download Hasil</Button> */}
                        </div>
                        
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
