import React, {useState} from 'react'
import DataDiri from './data_diri'
import FormWla from './form_wla'
import Selesai from './selesai'
import './style.css'
import { makeStyles } from '@material-ui/core/styles'
import Cookie from 'universal-cookie'
import Modal from 'components/Modal'
import Loading from 'components/Loading'
import {useSelector,useDispatch} from 'react-redux'
import {setTab} from 'redux/actions/responden'
import {Helmet} from "react-helmet";

const cookie=new Cookie
const profile=cookie.get('profile_cookie')
const token=cookie.get('login_cookie')

const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
            },
            width:'100%',
            marginBottom:15
    },
    
}));
const useStyles2 = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
            },
            width:'100%',
            // marginBottom:15
    },
    
}));
export default function Index() {
    const dispatch = useDispatch()
    const responden = useSelector(state => state.responden)
    let {tab,responden_info}=responden
    const classes=useStyles()
    const classes2=useStyles2()
    const settab=(key)=>{
        dispatch(setTab(key))
    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Workload Analysis</title>
                <meta
                    name="description"
                    content="Workload Analysis powered by ONE GML"
                /> 
                <link rel="icon" href="/gml.png" />

                <link rel="canonical" href="https://apps.onegml.com/wla" />
            </Helmet>
            <Loading/>
            <Modal token={token} profile={profile}/>
            {tab==='data_diri'&&
            <DataDiri
                classes={classes}
                profile={profile}
                settab={settab}
            />}
            {tab==='form_wla'&&
            <FormWla
                classes={classes2}
                profile={profile}
                settab={settab}

            />}
            {tab==='selesai'&&
            <Selesai
                classes={classes2}
                profile={profile}
                settab={settab}
                responden_info={responden_info}

            />}
        </div>
    )
}
