import React from 'react'

export default function Persentase(props) {
    let {peta_sebaran,getSebaranPersentase,project_info,filter_by_field_value}=props
    React.useEffect(() => {
        let data={
            items:filter_by_field_value,
            projectId:project_info.id,
            search:'*'
        }
       getSebaranPersentase(data)
    }, [])
    return (
        <div>
            {peta_sebaran.length>0&&
            <div className='sebaran-wrapper'>
                <div className='sebaran-container'>
                <h3 className='kinerja-text' >{project_info.yAxis}</h3>
                    <h3 className='potensial-text' >{project_info.xAxis}</h3>
                    <div className='box-sebaran-grid'>
                    <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',top:0,left:16}}><i>{peta_sebaran[2].cell.maxY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',top:180,left:16}}><i>{peta_sebaran[2].cell.minY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:16,top:375}}><i>{peta_sebaran[0].cell.maxY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',bottom:40,left:16}}><i>{peta_sebaran[0].cell.minY}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',bottom:10,left:40}}><i>{peta_sebaran[0].cell.minX}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:'35%',bottom:10}}><i>{peta_sebaran[0].cell.maxX}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:'66%',bottom:10}}><i>{peta_sebaran[6].cell.minX}</i></p>
                        <p style={{margin:0,fontSize:16,fontWeight:'bold',position:'absolute',left:'96%',bottom:10}}><i>{peta_sebaran[6].cell.maxX}</i></p>

                        <div className='box-sebaran-div' style={{backgroundColor:peta_sebaran[2].cell.bgColor}}>
                            <p style={{margin:0,marginBottom:10,fontSize:40,color:'white'}}>{peta_sebaran[2].percent.toFixed(0)}%</p>
                            <p style={{margin:0,fontSize:20,color:'white'}}>{peta_sebaran[2].number} Karyawan</p>
                            <p style={{color:'white',margin:0,fontSize:12}}>{peta_sebaran[2].cell.label}</p>
                        </div>
                        <div className='box-sebaran-div' style={{backgroundColor:peta_sebaran[5].cell.bgColor}}>
                            <p style={{margin:0,marginBottom:10,fontSize:40,color:'white'}}>{peta_sebaran[5].percent.toFixed(0)}%</p>
                            <p style={{margin:0,fontSize:20,color:'white'}}>{peta_sebaran[5].number} Karyawan</p>
                            <p style={{color:'white',margin:0,fontSize:12}}>{peta_sebaran[5].cell.label}</p>
                        </div>
                        <div className='box-sebaran-div' style={{backgroundColor:peta_sebaran[8].cell.bgColor}}>
                        <p style={{margin:0,marginBottom:10,fontSize:40,color:'white'}}>{peta_sebaran[8].percent.toFixed(0)}%</p>
                            <p style={{margin:0,fontSize:20,color:'white'}}>{peta_sebaran[8].number} Karyawan</p>
                            <p style={{color:'white',margin:0,fontSize:12}}>{peta_sebaran[8].cell.label}</p>
                        </div>
                        <div className='box-sebaran-div' style={{backgroundColor:peta_sebaran[1].cell.bgColor}}>
                        <p style={{margin:0,marginBottom:10,fontSize:40,color:'white'}}>{peta_sebaran[1].percent.toFixed(0)}%</p>
                            <p style={{margin:0,fontSize:20,color:'white'}}>{peta_sebaran[1].number} Karyawan</p>
                            <p style={{color:'white',margin:0,fontSize:12}}>{peta_sebaran[1].cell.label}</p>
                        </div>
                        <div className='box-sebaran-div' style={{backgroundColor:peta_sebaran[4].cell.bgColor}}>
                        <p style={{margin:0,marginBottom:10,fontSize:40,color:'white'}}>{peta_sebaran[4].percent.toFixed(0)}%</p>
                            <p style={{margin:0,fontSize:20,color:'white'}}>{peta_sebaran[4].number} Karyawan</p>
                            <p style={{color:'white',margin:0,fontSize:12}}>{peta_sebaran[4].cell.label}</p>
                        </div>
                        <div className='box-sebaran-div' style={{backgroundColor:peta_sebaran[7].cell.bgColor}}>
                        <p style={{margin:0,marginBottom:10,fontSize:40,color:'white'}}>{peta_sebaran[7].percent.toFixed(0)}%</p>
                            <p style={{margin:0,fontSize:20,color:'white'}}>{peta_sebaran[7].number} Karyawan</p>
                            <p style={{color:'white',margin:0,fontSize:12}}>{peta_sebaran[7].cell.label}</p>
                        </div>
                        <div className='box-sebaran-div' style={{backgroundColor:peta_sebaran[0].cell.bgColor}}>
                        <p style={{margin:0,marginBottom:10,fontSize:40,color:'white'}}>{peta_sebaran[0].percent.toFixed(0)}%</p>
                            <p style={{margin:0,fontSize:20,color:'white'}}>{peta_sebaran[0].number} Karyawan</p>
                            <p style={{color:'white',margin:0,fontSize:12}}>{peta_sebaran[0].cell.label}</p>
                        </div>
                        <div className='box-sebaran-div' style={{backgroundColor:peta_sebaran[3].cell.bgColor}}>
                        <p style={{margin:0,marginBottom:10,fontSize:40,color:'white'}}>{peta_sebaran[3].percent.toFixed(0)}%</p>
                            <p style={{margin:0,fontSize:20,color:'white'}}>{peta_sebaran[3].number} Karyawan</p>
                            <p style={{color:'white',margin:0,fontSize:12}}>{peta_sebaran[3].cell.label}</p>
                        </div>
                        <div className='box-sebaran-div' style={{backgroundColor:peta_sebaran[6].cell.bgColor}}>
                        <p style={{margin:0,marginBottom:10,fontSize:40,color:'white'}}>{peta_sebaran[6].percent.toFixed(0)}%</p>
                            <p style={{margin:0,fontSize:20,color:'white'}}>{peta_sebaran[6].number} Karyawan</p>
                            <p style={{color:'white',margin:0,fontSize:12}}>{peta_sebaran[6].cell.label}</p>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}
