import React from 'react'
import './style.css'
import Navbar from 'components/Navbar'
import Sidebar from 'components/Sidebar'
import Modal from 'components/Modal'
import Cookie from 'universal-cookie'
import Loading from 'components/Loading'
import {isMobile} from 'react-device-detect'
import AdminInMobile from 'views/warning/admin_in_mobile'

const cookie=new Cookie()

export default function Index(props) {
    const token=cookie.get('login_cookie')
    const profile=cookie.get('profile_cookie')
    if(isMobile){
        return <AdminInMobile/>
    }else{
        return (
            <div>
                <Navbar profile={profile}/>
                    <Sidebar token={token} profile={profile}/>
                    <Loading/>
                    <div className='content-wrapper'>
                        <Modal token={token} profile={profile}/>
                        {props.children}
                        
                    </div>
            </div>
        )
    }
    
}

