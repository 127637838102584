import React ,{useEffect,useState}from 'react'
import {useSelector,useDispatch} from 'react-redux'

import {Button,TextField,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import Skeleton from 'components/Skeleton'
import { MuiThemeProvider, createTheme,withStyles, makeStyles} from '@material-ui/core/styles'
import SearchImg from 'assets/icon/Search.png'
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce} from 'lodash'
import {modalToggle} from 'redux/actions/general'
import {getClientList,resetDetail,getIndustry,setDetailClient,deleteClient,setFilterClient} from 'redux/actions/client'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import close from 'assets/icon/close.svg'
const useStyles = makeStyles(theme => ({
    search: {
        [`& fieldset`]: {
            borderRadius: 8,
            height:30,
            margin: theme.spacing(1),
          },
          width:200,
          height:10,
          marginBottom:40
  },
  input:{
    fontSize:14,
    margin:3      
  }
}));
export default function List(props) {
    const dispatch=useDispatch()
    const classes=useStyles()
    const client = useSelector(state => state.client)
    const general = useSelector(state => state.general)
    const [search, setSearch] = useState('')
    const {client_list,client_pagination,list_industry,client_filter}=client
    useEffect(()=>{
        if(list_industry.length<1){
            dispatch(getIndustry())
        }
        // // if(client_list.length<1){
            dispatch(getClientList(`/0/1/10/*`))
        // // }
    },[])
    const searchToggle=debounce(async (value)=>{
        setSearch(value)
        await dispatch(getClientList(`/0/1/10/${value!==''?value:'*'}`))
    },1000)
    const togglePagination=(page,pageLength)=>{
        let new_industry=[]

        client_filter.industry.map((data)=>{
            new_industry.push(data.id)
        })
        dispatch(getClientList(`/${new_industry.length>0?new_industry.join(','):0}/${page}/${pageLength}/${search!==''?search:'*'}`))
    }
    const afterPostFilter=(data)=>{
        dispatch(setFilterClient({industry:data}))
    }
    const filterData=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Filter`,
            modal_component: "filter_client",
            modal_size:300,
            modal_data:{industry:client_filter.industry,afterPostFilter:(data)=>afterPostFilter(data)},
            modal_action:'filter_client'
        }))
    }
    const detail_client=async (data)=>{
        dispatch(setDetailClient({clientId:data.id}))
        dispatch(setDetailClient({company:data.company}))
        dispatch(setDetailClient({phone:data.phone}))
        dispatch(setDetailClient({address:data.address}))
        dispatch(setDetailClient({industry:data.industry}))
        props.tabToggle('detail')
        
    }
    const edit_client=async (data)=>{
        dispatch(setDetailClient({clientId:data.id}))
        dispatch(setDetailClient({company:data.company}))
        dispatch(setDetailClient({phone:data.phone}))
        dispatch(setDetailClient({address:data.address}))
        dispatch(setDetailClient({industry:data.industry}))
        props.tabToggle('edit')
    }
    const action_delete=async (data)=>{
        let res=await dispatch(deleteClient(`/${data.id}/${props.profile.id}`))
        if(res){
            dispatch(getClientList(`/0/1/10/*`))
        }
        
    }
    const delete_client=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Client',
                userId:data.id,
                clientId:data.clientId,
                action:()=>action_delete(data),
                msg:`<p>Apakah anda yakin menghapus ${data.company} </p>`
            },
            modal_action:'delete_client'
        }))
    }
    return (
        <div>
            <div className='head-section'>
                <div>
                    <h4 style={{marginBottom:0,marginTop:0}}>Manajemen Klien</h4>
                </div>
            </div>
            <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table__head'>
                        <TextField
                            placeholder="Cari"
                            variant='outlined'
                            size='small'
                            InputLabelProps={{shrink: false}}
                            className={classes.search}
                            inputProps={{
                                className:classes.input
                            }}
                            onChange={(e)=>searchToggle(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <img alt="search" src={SearchImg} style={{width:15}}/>
                                </InputAdornment>
                                ),
                            }}
                        />
                        <Button onClick={filterData} color="primary" className='btn-remove-capital' variant="text">Filter</Button>
                    </div>
                    <div className='card-table__content'>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                        <TableCell align="left" >No.</TableCell>
                        <TableCell align="left" style={{width:250}}>Nama Perusahaan</TableCell>
                        <TableCell align="left"  style={{width:100}}>Jenis Industri</TableCell>
                        <TableCell align="left">No. Telepon</TableCell>
                        <TableCell align="center" >Jumlah Proyek WLA</TableCell>
                        <TableCell align="center">Aksi</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {general.isLoadingTable?
                                <Skeleton count={6}/>
                            :
                            client_list.length<1?
                            <TableRow>
                                <TableCell colSpan={7} style={{textAlign:'center'}}>Belum ada client</TableCell>
                            </TableRow>
                            :client_list.map((data,i)=>(
                                <TableRow key={i}>
                                    <TableCell >{i+1}</TableCell>
                                    <TableCell >{data.company}</TableCell>
                                    <TableCell>{data.industry.text}</TableCell>
                                    <TableCell>{data.phone}</TableCell>
                                    <TableCell align='center'>{data.wlas}</TableCell>
                                    <TableCell align="center">
                                        <div >
                                        <img src={Eye} onClick={()=>detail_client(data)} className='icon-action'/>
                                        <img src={Edit} onClick={()=>edit_client(data)} className='icon-action'/>
                                        {/* <img src={close} onClick={()=>delete_client(data)} className='icon-action'/> */}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                            
                            }
                        </TableBody>
                    </Table>
                    <div className='card-table__pagination'>
                    {client_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={client_pagination.page}
                            pageLength={client_pagination.perPage}
                            totalRecords={client_pagination.total}
                            totalRecords={client_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    }
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
