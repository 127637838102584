import React,{useEffect,useState} from 'react'
import {TextField,Button} from '@material-ui/core'
import {useSelector,useDispatch} from 'react-redux'
import * as proyekAction from 'redux/actions/proyek'
export default function Keterangan_wla(props) {
    const dispatch = useDispatch()
    const [remarks, setremarks] = useState('')
    let {classes,modal_data}=props
    const proyek = useSelector(state => state.proyek)
    const {recap_detail}=proyek
    useEffect(() => {
        setremarks(modal_data.remarks)
    }, [])
    const onClickSimpan=async ()=>{
        let data={
            jobTitleId:recap_detail.jobTitleId,
            jobDetailId:recap_detail.jobDetailId,
            Remarks:remarks,
            userId:props.profile.id
        }
        let res= await dispatch(proyekAction.postRemarks(data))
        if(res){
            modal_data.action()
        }
    }
    return (
        <div>
            <TextField
                 label="Keterangan"
                 variant='outlined'
                 value={remarks}
                 onChange={e=>setremarks(e.target.value)}
                 size='small'
                 className={classes.textField}
                 multiline
            />
            <div style={{textAlign:'right'}}>
                <Button onClick={onClickSimpan} className='btn-remove-capital btn-rounded' size='small' variant='contained' color='primary'>Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
