import * as actionTypes from "../constants/responden";
import Cookie from 'universal-cookie'
const cookie=new Cookie
const profile=cookie.get('profile')
const initialState={
    works_unit:[],
    jobs:[],
    // detail_jobs:null,
    responden_info:{
        name:'',
        work_unit:null,
        job:null,
        employees:'',
        location:'',
        projectId:null,
        jobTitle:'',
        shortName:'',
        workUnit:'',
        batch:'',
        hirarki_id:null,
        tasks:[],
        jobTitleId:null
    },
    tab:'data_diri',
    respondent_column:[]
}
export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.GET_WORKS_UNIT:
            return{
                ...state,
                works_unit:action.payload
            }
        case actionTypes.GET_RESPONDENT_COLUMN:
            return{
                ...state,
                respondent_column:action.payload
            }
        case actionTypes.RESET_RESPONDEN:
            return{
                ...state,
                jobs:[],
                responden_info:{
                    name:'',
                    work_unit:null,
                    job:null,
                    employees:'',
                    location:'',
                    projectId:null,
                    jobTitle:'',
                    shortName:'',
                    workUnit:'',
                    batch:'',
                    hirarki_id:null,
                    tasks:[]
                },
                tab:'data_diri',
                respondent_column:[]

            }
        case actionTypes.SET_TAB:
            return{
                ...state,
                tab:action.payload
            }
        case actionTypes.GET_JOB_TITLE:
            return{
                ...state,
                jobs:action.payload
            }
        case actionTypes.SET_RESPONDEN_INFO:
            return{
                ...state,
                responden_info:{...state.responden_info,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        default:
            return state;
    }
}

