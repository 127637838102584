import React from 'react'
import Close from 'assets/icon/close.svg'
export default function Modal(props) {
    let {modal_size,modal_title,modalToggleReset,body}=props
    return (
        <div className='modal-content' style={{right:0,zIndex:2,width:modal_size,position:'absolute'}}>
            <div className='modal-header'>
                <h2>{modal_title}</h2>
                <img src={Close} style={{width:20}} onClick={()=>modalToggleReset()}/>
            </div>
            <div className='modal-block'></div>
            <div  >
            {body}
            </div>
        </div>
    )
}
