import * as actionTypes from "../constants/proyek";
const initialState={
    hierarchy_list_for_dropdown:[],
    proyek_list:[],
    wke_list:[],
    proyek_pagination:{
        page:0,
        perPage:10,
        total:0
    },
    proyek_detail:{
        projectId:0,
        workUnits:0,
        jobTitles:0,
        sdm:0,
        need:0,
        surplus:0,
        status:'',
        name:'',
        company:'',
        fte:0,

    },

    recap_list:[],
    recap_export:null,
    recap_pagination:{
        page:0,
        perPage:10,
        total:0
    },
    recap_filter:{
        consultantIds:[],
        batch:0,
        kodesdm:null,
        sdm:0,
        kodeneed:null,
        need:0,
        surplus:0,
        status:0,
    },
    kodesdm_list:[
        {
            id:1,
            text:'<'
        },
        {
            id:2,
            text:'<='
        },
        {
            id:3,
            text:'='
        },
        {
            id:4,
            text:'>'
        },
        {
            id:5,
            text:'>='
        },
    ],
    list_surplus:[
        {
            id:1,
            text:'Surplus'
        },
        {
            id:2,
            text:'Deficit'
        },
        {
            id:3,
            text:'Equal'
        },
    ],
    list_status:[
        {
            id:1,
            text:'Menunggu'
        },
        {
            id:2,
            text:'Proses'
        },
        {
            id:3,
            text:'Selesai'
        },
        {
            id:4,
            text:'Terverifikasi'
        },
    ],
    recap_detail:null,
    work_unit:[],
    tab:'list',
    recap_column:[],
    recap_detail_column:[]
    // info
}
export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.RESET_PROYEK:
            return{
                ...state,
                recap_detail:null,
                tab:'list',
                recap_column:[],
                recap_detail_column:[]
            }
        case actionTypes.GET_HIERARCHY_FOR_DROPDOWN:
            return{
                ...state,
                hierarchy_list_for_dropdown:action.payload
            }
        case actionTypes.GET_RECAP_COLUMN:
            return{
                ...state,
                recap_column:action.payload
            }
        case actionTypes.GET_RECAP_DETAIL_COLUMN:
            return{
                ...state,
                recap_detail_column:action.payload
            }
        case actionTypes.GET_RECAP_DETAIL:
            return{
                ...state,
                recap_detail:action.payload
            }
        case actionTypes.SET_TAB:
            return{
                ...state,
                tab:action.payload
            }
        case actionTypes.GET_WORK_UNIT:
            return{
                ...state,
                work_unit:action.payload
            }
        case actionTypes.GET_WKE:
            return{
                ...state,
                wke_list:action.payload
            }
        case actionTypes.SET_PROYEK_DETAIL:
            return{
                ...state,
                proyek_detail:{...state.proyek_detail,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.GET_PROYEK:
            return{
                ...state,
                proyek_list:action.payload.proyek_list,
                proyek_pagination:action.payload.proyek_pagination
            }
        case actionTypes.GET_RECAP_LIST:
            return{
                ...state,
                recap_list:action.payload.recap_list,
                recap_pagination:action.payload.recap_pagination,
                recap_export:action.payload.recap_export
            }
        case actionTypes.SET_RECAP_FILTER:
            return{
                ...state,
                recap_filter:{...state.recap_filter,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.RESET_RECAP_FILTER:
            return{
                ...state,
                recap_filter:{
                    consultantIds:[],
                    batch:0,
                    kodesdm:null,
                    sdm:0,
                    kodeneed:null,
                    need:0,
                    surplus:0,
                    status:0,
                },
            }
        default:
            return state;
    }
}

