import React ,{useState} from 'react'
import {Button} from '@material-ui/core'
import {handleFile} from 'components/handleFile'
import {useDispatch,useSelector} from 'react-redux'
import {wlaUploadFile} from 'redux/actions/proyek'
import { MuiThemeProvider, createTheme,withStyles, } from '@material-ui/core/styles'

const themeButton = createTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Assignment_peserta(props) {
    const dispatch=useDispatch()
    const proyek = useSelector(state => state.proyek)
    let {proyek_detail}=proyek
    const [file,setFile]=useState({
        filename:'',
        file:null,
        file_error:''
    })
    const handleFiles=async (evt)=>{
        let handle=await handleFile(evt)
        if(handle.file_error===null){
            setFile({
                filename:handle.file_name,
                file:handle.file,
                file_error:''
            })
        }else{
            setFile({
                filename:'',
                file:null,
                file_error:handle.file_error
            })
        }
    }
    const downloadTemplate=()=>{
        window.open(`/template_upload.zip`,'Download')
    }
    const onUpload=async ()=>{
        let fd=new FormData()
        fd.append('projectId',proyek_detail.projectId)
        fd.append('userId',props.profile.id)
        fd.append('files',file.file)
        let res= await dispatch(wlaUploadFile(fd))
        if(res){
            await props.modal_data.action()
            await props.modal_data.action2()
        }
    }
    return (
        <div>
            <p style={{margin:0,color:'#cccccc',fontSize:12}}>Nama file (format file harus csv)<span style={{color:'red'}}>*</span></p>
            <div className='div-flex div-space-between div-align-center'>
                <p style={{margin:0,fontSize:14,color:file.file_error!==''?'red':'#252525',fontWeight:500,width:200,wordWrap:'break-word'}}>
                    
                    {file.file_error===''?file.filename!==''?file.filename:'Belum ada file di-upload':file.file_error}
                </p>
                <MuiThemeProvider theme={themeButton}>
                {file.file!==null?
                    <div className='div-flex'>
                        <Button component="label" size="small" style={{padding:0,margin:0}} color="secondary" variant="text" className='btn-remove-capital'>
                            Ganti
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e)=>handleFiles(e)}
                            />
                        </Button>
                        &nbsp;
                        <Button onClick={onUpload} color="primary" variant="text" className='btn-remove-capital'>Upload</Button>
                    </div>
                :
                <Button component="label" size="small" style={{padding:0,margin:0}} color="primary" variant="text" className='btn-remove-capital'>
                    Pilih file
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e)=>handleFiles(e)}
                    />
                </Button>
                }
                </MuiThemeProvider>
            </div>
            <p style={{marginBottom:0,fontSize:14,color:'#252525',fontWeight:500}}>Proses upload jabatan dilakukan dengan mengupload data jabatan. <span onClick={downloadTemplate} style={{color:'#00a1ed',cursor:'pointer'}}>Download format</span></p>
            {/* <Button onClick={downloadTemplate} color="primary" variant="text" className='btn-remove-capital'>Download format</Button> */}

        </div>
    )
}
