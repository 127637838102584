import React, { useState } from 'react'
import {Button} from '@material-ui/core'
import {useSelector,useDispatch} from 'react-redux'
let data=[
    {
        id:1,
        projectId:0,
        name:'TEsdfasdf',
        description:'Desc test asdfjadf'
    },
    {
        id:2,
        projectId:0,
        name:'TEsdfasdf',
        description:'Desc test asdfjadf'
    },
    {
        id:3,
        projectId:0,
        name:'TEsdfasdf',
        description:'Desc test asdfjadf'
    },
    {
        id:4,
        projectId:0,
        name:'TEsdfasdf',
        description:'Desc test asdfjadf'
    },
    
    
]
export default function Choose_template(props) {
    const talenta = useSelector(state => state.talenta)
    let {list_template,project_info}=talenta
    let {profile,modal_data}=props
    const [choosen_template, setchoosen_template] = useState(null)
    const onSave=()=>{
        modal_data.actions(`/${profile.id}/${project_info.id}/${choosen_template.id}`,choosen_template)
    }
    const handleDisable=()=>{
        if(choosen_template!==null){
            return false
        }else{
            return true
        }
    }
    return (
        <div>
            {list_template.map((d,i)=>(
                <div key={i} onClick={()=>setchoosen_template(d)} className={`choose-template ${(choosen_template!==null&&d.id===choosen_template.id)&&'choosen'}`} style={{cursor:'pointer'}}>
                    <p style={{fontWeight:'bold',color:'#252525',fontSize:14,margin:'0 0 5px 0'}}>{d.name}</p>
                    <p style={{fontSize:12,margin:'0 0 10px 0'}}>{d.description}</p>
                </div>
            ))}
            <div style={{textAlign:'right'}}>
                <Button onClick={onSave} disabled={handleDisable()} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
