import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import Layout from 'components/Layouts'

import {Button,TextField,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import Skeleton from 'components/Skeleton'
import { makeStyles} from '@material-ui/core/styles'
import SearchImg from 'assets/icon/Search.png'
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce} from 'lodash'
import {modalToggle} from 'redux/actions/general'
import {getKonsultan,setKonsultanDetail,postKonsultan,resetDetail,putKonsultan,deleteKonsultan} from 'redux/actions/konsultan'
import Edit from 'assets/icon/edit.svg'
import close from 'assets/icon/close.svg'
import InputMask from 'react-input-mask'
const useStyles = makeStyles(theme => ({
    search: {
        [`& fieldset`]: {
            borderRadius: 8,
            height:30,
            margin: theme.spacing(1),
          },
          width:200,
          height:10,
          marginBottom:40
  },
  input:{
    fontSize:14,
    margin:3      
  },
  textField: {
    [`& fieldset`]: {
        borderRadius: 8,
        },
        width:'100%',
        marginBottom:15
},
}));
export default function Index() {
    const classes=useStyles()
    const dispatch = useDispatch()
    const general = useSelector(state => state.general)
    const konsultan = useSelector(state => state.konsultan)
    const [search, setSearch] = useState('')
    const [tab, settab] = useState('add')
    const {konsultan_list,konsultan_pagination,konsultan_detail}=konsultan

    useEffect(() => {
        dispatch(getKonsultan(`/1/10/*`))
    }, [])
    const searchToggle=debounce(async (value)=>{
        setSearch(value)
        await dispatch(getKonsultan(`/1/10/${value!==''?value:'*'}`))
    },1000)
    const togglePagination=(page,pageLength)=>{
        dispatch(getKonsultan(`/${page}/${pageLength}/${search!==''?search:'*'}`))
    }
    const onClickSimpan=async ()=>{
        let data=konsultan_detail
        if(tab=='add'){
            let res=await dispatch(postKonsultan(data))
            if(res){
                dispatch(resetDetail())
                dispatch(getKonsultan())
            }
        }
        if(tab=='edit'){
            let res=await dispatch(putKonsultan(data))
            if(res){
                settab('add')
                dispatch(resetDetail())
                dispatch(getKonsultan())
            }
        }
    }
    const edit_konsultan=(data)=>{
        settab('edit')
        dispatch(setKonsultanDetail({userId:data.userId}))
        dispatch(setKonsultanDetail({name:data.name}))
        dispatch(setKonsultanDetail({email:data.email}))
        dispatch(setKonsultanDetail({phone:data.phone}))
    }
    const onDeleteKonsultan=async (id)=>{
        let res= await dispatch(deleteKonsultan(`/${id}`))
        if(res){
            dispatch(getKonsultan())
        }
    }
    const delete_konsultan=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Konsultan',
                userId:data.id,
                clientId:data.clientId,
                action:()=>onDeleteKonsultan(data.userId),
                msg:`<p>Apakah anda yakin menghapus ${data.name} </p>`
            },
            modal_action:'delete_konsultan'
        }))
    }

    return (
        <div>
            <Layout>
            <div className='div-flex div-space-between'>
                <div style={{width:'63%'}}>
                    <div className='head-section'>
                        <div>
                            <h4 style={{marginBottom:0,marginTop:0}}>Daftar Konsultan</h4>
                        </div>
                    </div>
                    <div className='card-content'>
                        <div className='card-table'>
                            <div className='card-table__head'>
                                <TextField
                                    placeholder="Cari"
                                    variant='outlined'
                                    size='small'
                                    InputLabelProps={{shrink: false}}
                                    className={classes.search}
                                    inputProps={{
                                        className:classes.input
                                    }}
                                    onChange={(e)=>searchToggle(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <img alt="search" src={SearchImg} style={{width:15}}/>
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className='card-table__content'>
                            <Table  size="small" aria-label="a dense table">
                                <TableHead>
                                <TableRow>
                                <TableCell align="left" style={{width:10}}>No.</TableCell>
                                <TableCell align="left" style={{width:100}}>Nama Konsultan</TableCell>
                                <TableCell align="left"  style={{width:70}}>Email</TableCell>
                                <TableCell align="left">No. Telepon</TableCell>
                                <TableCell align="center">Aksi</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {general.isLoadingTable?
                                        <Skeleton count={5}/>
                                    :
                                    konsultan_list.length<1?
                                    <TableRow>
                                        <TableCell colSpan={5} style={{textAlign:'center'}}>Belum ada konsultan</TableCell>
                                    </TableRow>
                                    :konsultan_list.map((data,i)=>(
                                        <TableRow key={i}>
                                            <TableCell >{i+1}</TableCell>
                                            <TableCell >{data.name}</TableCell>
                                            <TableCell >{data.email}</TableCell>
                                            <TableCell>{data.phone}</TableCell>
                                            <TableCell align="center">
                                                <div >
                                                <img src={Edit} onClick={()=>edit_konsultan(data)} className='icon-action'/>
                                                <img src={close} onClick={()=>delete_konsultan(data)} className='icon-action'/>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    
                                    }
                                </TableBody>
                            </Table>
                            <div className='card-table__pagination'>
                            {konsultan_pagination!==null&&
                            <TablePagination
                                    className="card-pagination"
                                    type="reduced"
                                    page={konsultan_pagination.page}
                                    pageLength={konsultan_pagination.perPage}
                                    totalRecords={konsultan_pagination.total}
                                    totalRecords={konsultan_pagination.total}
                                    onPageChange={({ page, pageLength }) => {
                                        togglePagination(page,pageLength)
                                    }}
                                    prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                    nextPageRenderer={() => <img src={CevronRight}/>}
                                />
                            }
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width:'35%'}}>
                    <div className='head-section'>
                        <div>
                            <h4 style={{marginBottom:0,marginTop:0}}>Tambah Konsultan</h4>
                        </div>
                    </div>
                    <div className='card-content' style={{padding:20}}>
                        <TextField
                            label={<>Nama Konsultan</>}
                            // type="number"
                            variant='outlined'
                            value={konsultan_detail.name}
                            size='small'
                            // disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setKonsultanDetail({name:e.target.value}))}

                        />
                        <TextField
                            label={<>Email</>}
                            // type="number"
                            variant='outlined'
                            value={konsultan_detail.email}
                            size='small'
                            // disabled={handleDisable()}
                            className={classes.textField}
                            onChange={(e)=>dispatch(setKonsultanDetail({email:e.target.value}))}

                        />
                        <InputMask
                            maskChar={null}
                            mask="9999-9999-9999-9999"
                            value={konsultan_detail.phone}
                            // disabled={handleDisable()}

                            onChange={(e)=>dispatch(setKonsultanDetail({phone:e.target.value}))}

                        >
                        {() =>
                            <TextField
                            label={<>No.Telepon</>}
                            variant='outlined'
                            
                            size='small'
                            // disabled={handleDisable()}
                            className={classes.textField}

                        />
                        }
                        </InputMask>
                        
                        <div style={{textAlign:'right'}}>
                        <Button size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >{tab==='add'?'Simpan':'Update'}</Button>
                        </div>
                    </div>
                </div>
            </div>
            </Layout>
        </div>
    )
}
