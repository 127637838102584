import React,{useEffect,useState} from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import * as actionTalenta from 'redux/actions/talenta'
import * as actionGeneral from 'redux/actions/general'
import SearchTable from 'components/SearchTable'
import Close from 'assets/icon/close.svg'
import Eye from 'assets/icon/eye.png'
import _ from 'lodash'
import DataTable from 'components/DataTable'
import { useSelector,useDispatch } from 'react-redux'
const RenderHead=(props)=>(
    <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
        <SearchTable
            {...props}
            searchToggle={(value)=>props.searchToggle(value)}
        />
        
    </div>
)
export default function List(props) {
    const [search, setsearch] = useState('')
    let {profile}=props
    const talenta = useSelector(state => state.talenta)
    const general = useSelector(state => state.general)
    const dispatch = useDispatch()
    useEffect(() => {
        getProyek(`/${profile.id}/1/10/*`)
    }, [])
    const getProyek=async (slug)=>{
        let res=await dispatch(actionTalenta.getProyekList(slug))
        return res
    }
    const searchToggle=_.debounce(async (e)=>{
        setsearch(e)
        getProyek(`/${profile.id}/1/10/${e.length>0?e:'*'}`)
    },1000)
    const togglePagination=(page,pageLength)=>{
        getProyek(`/${profile.id}/${page}/${pageLength}/${search.length>0?search:'*'}`)
    }
    const saveKlasifikasi=()=>{
        props.tabToggle('detail')
        dispatch(actionGeneral.modalToggleReset())
    }
    const afterPost=async (data)=>{
        getProyek(`/${profile.id}/1/10/*`)
        // dispatch(actionTalenta.setTalenta({project_info:{
            // id:data.id,
            // name:data.name,
            // year:data.year,
            // employees:0
        // }
        // }))
        dispatch(actionTalenta.setTalenta({project_info:{
            id:data.id,
            name:data.name,
            year:data.year,
            employees:0,
            description:data.description,
            xAxis:data.xAxis,
            yAxis:data.yAxis
        }}))
        let klasifikasi=await dispatch(actionTalenta.getKlasifikasiList(data.id))
        if(klasifikasi){
            dispatch(actionGeneral.modalToggle({
                modal_open: true,
                modal_title: `Klasifikasi data ${data.name}`,
                modal_component: "add_talenta_klasifikasi",
                modal_size:450,
                modal_type:'add_talenta_klasifikasi',
                modal_data:{
                    afterPost:()=>saveKlasifikasi(),
                    klasifikasi_list:klasifikasi,
                    filename:data.filename
                },
                modal_action:'add_talenta_klasifikasi'
            }))
        }
        
    }
    const addKegiatan=async ()=>{
        dispatch(actionGeneral.modalToggle({
            modal_open: true,
            modal_title: `Tambah Kegiatan`,
            modal_component: "add_talenta_project",
            modal_size:450,
            modal_type:'add_talenta_project',
            modal_data:{afterPost:(data)=>afterPost(data)},
            modal_action:'add_talenta_project'
        }))
        // let klasifikasi=await dispatch(actionTalenta.getKlasifikasiList(1))
        // if(klasifikasi){
        //     dispatch(actionGeneral.modalToggle({
        //         modal_open: true,
        //         modal_title: `Klasifikasi data asdf`,
        //         modal_component: "add_talenta_klasifikasi",
        //         modal_size:450,
        //         modal_type:'add_klasifikasi',
        //         modal_data:{
        //             afterPost:()=>saveKlasifikasi(),
        //             klasifikasi_list:klasifikasi
        //         },
        //         modal_action:'add_klasifikasi'
        //     }))
        // }
    }
    const detailProyek=(data)=>{
        dispatch(actionTalenta.setTalenta({project_id:data.id}))
        props.tabToggle('detail')
    }
    const deleteAction=async (data)=>{
        let res=await dispatch(actionTalenta.deleteTalentaProject(`/${data.id}/${profile.id}`))
        if(res){
            getProyek(`/${profile.id}/1/10/*`)
        }
    }
    const deleteProyek=(data)=>{
        dispatch(actionGeneral.modalToggle({
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Kegiatan',
                action:()=>deleteAction(data),
                msg:`<p>Yakin hapus <b>${data.name}</b> ?</p>`
            },
            modal_action:'delete_talenta_project'
        }))
    }
    return (
        <div data-testid="list-wrapper">
            <div className='head-section'>
                <h4 data-testid="heading"><b>Daftar Kegiatan Perhitungan Talent Management</b></h4>
                {props.is_sa?
                <Button onClick={props.addKredit} className='btn-remove-capital btn-rounded' variant='contained' color="primary">Buat Kredit</Button>
                :
                <Button onClick={addKegiatan} className='btn-remove-capital btn-rounded' variant='contained' color="primary">Tambah Kegiatan</Button>
                }
            </div>
            <DataTable
                head={['No.','Nama Kegiatan','Tahun','Deskripsi','Jumlah Karyawan','Aksi']}
                body={()=>{
                    return talenta.list_proyek.map((data,i)=>(
                        <TableRow key={i} data-testid="proyek-item">
                            <TableCell>{i+1}</TableCell>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>{data.year}</TableCell>
                            <TableCell>{data.description.length>50?`${data.description.slice(0,50)}..`:data.description}</TableCell>
                            <TableCell>{data.employees}</TableCell>
                            <TableCell align="right">
                                <img src={Eye} onClick={()=>detailProyek(data)} className='icon-action'/>
                                <img src={Close} onClick={()=>deleteProyek(data)} className='icon-action'/>
                            </TableCell>
                        </TableRow>
                    ))
                }}
                cardHead={<RenderHead {...props}  searchToggle={searchToggle} />}
                loading={general.isLoadingTable}
                pagination={talenta.pagination}
                togglePagination={togglePagination}
            />
        </div>
    )
}
