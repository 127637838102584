import React,{useState,useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {Button,TextField,FormControl,FormGroup,FormControlLabel,Checkbox,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody,TableContainer,Paper} from '@material-ui/core'
import Institution from 'assets/icon/Institution.svg'
import Users3 from 'assets/icon/Users3.svg'
import Users1 from 'assets/icon/Users1.svg'
import UserTie from 'assets/icon/UserTie.svg'
import LevelDown from 'assets/icon/LevelDown.svg'
import LevelUp from 'assets/icon/LevelUp.svg'
import Equal from 'assets/icon/Equal.svg'

import Skeleton from 'components/Skeleton'
import { MuiThemeProvider, createTheme,withStyles, makeStyles} from '@material-ui/core/styles'
import SearchImg from 'assets/icon/Search.png'
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce,isEmpty} from 'lodash'
import {modalToggle} from 'redux/actions/general'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import DownloadExcel from 'assets/icon/DownloadExcel.svg'
import close from 'assets/icon/close.svg'
import InputMask from 'react-input-mask'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

import * as proyekAction from 'redux/actions/proyek'
import * as konsultanAction from 'redux/actions/konsultan'
import * as hirarkiAction from 'redux/actions/hirarki'

import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const useStyles = makeStyles(theme => ({
    search: { 
        [`& fieldset`]: {
            borderRadius: 8,
            height:30,
            margin: theme.spacing(1),
          },
          width:200,
          height:10,
          marginBottom:30
  },
  textField: {
    [`& fieldset`]: {
        borderRadius: 8,
      },
      width:'100%',
    // "& .MuiInputBase-root.Mui-disabled": {
    //     color: " #777777",
    // }
    //   marginBottom:15
},
  input:{
    fontSize:14,
    margin:3      
  },
  paper: {
    boxShadow:'unset'
  },
}));
export default function Detail(props) {
    const classes=useStyles()
    const proyek = useSelector(state => state.proyek)
    const general = useSelector(state => state.general)
    const hirarki = useSelector(state => state.hirarki)
    const [search, setsearch] = useState('')
    const [batasfte_modal, setbatasfte_modal] = useState(false)
    let {proyek_detail,recap_column,wke_list,recap_filter,recap_list,recap_pagination}=proyek

    const [fte, setfte] = useState(proyek_detail.fte)
    const dispatch = useDispatch()
    const {consultantIds,batch,kodesdm,sdm,kodeneed,need,surplus,status}=recap_filter
    useEffect(() => {
        let new_konsultan=[]
        consultantIds.map((data)=>{
            new_konsultan.push(data.id)
        })
        dispatch(proyekAction.getWke(`/${proyek_detail.projectId}`))
        dispatch(proyekAction.getRecapList(`/${proyek_detail.projectId}/${new_konsultan.length>0?new_konsultan.join(','):0}/${batch}/${kodesdm===null?0:kodesdm}/${sdm}/${kodeneed===null?0:kodeneed}/${need}/${surplus===0?0:surplus.id}/${status===0?0:status.id}/${recap_pagination.page}/${recap_pagination.perPage}/*`))
        dispatch(proyekAction.getWorkUnit(`/${proyek_detail.projectId}`))
        dispatch(proyekAction.getRecapColumns(proyek_detail.projectId))
        dispatch(konsultanAction.getKonsultanDropDown())
    }, [])
    console.log(`recap_column`, recap_column)
    const renderColorCardstatus=(stat)=>{
        if(stat==='Prosess'){
            return '#ffb865'
        }
        if(stat==='Menunggu'){
            return '#ff6e79'
        }
        if(stat==='Selesai'){
            return 'rgb(168, 224, 144)'
        }

    }
    const renderColor=(number)=>{
        if(proyek_detail.surplus===0){
            return '#252525'
        }else{
            if(number>=0){
                return '#a8e090'
            }else{
                return '#ff6e79'
            }
        }
        
    }
    const renderColorStatus=(status)=>{
        if(status==='Proses'){
            return '#ffb865'
        }else if(status==='Menunggu'){
            return '#ff6e79'
        }else{
            return '#a8e090'
        }
    }
    const renderIconSurplus=(number)=>{
        if(number>0){
            return LevelUp
        }else if(number <0){
            return LevelDown
        }else{
            return Equal
        }
    }
    const searchToggle=debounce(async (value)=>{
        await dispatch(proyekAction.getRecapList(`/${proyek_detail.projectId}/0/0/0/0/0/0/0/0/1/10/${value!==''?value:'*'}`))
        setsearch(value)
        // await dispatch(getClientList(`/0/1/10/${value!==''?value:'*'}`))
    },1000)
    const togglePagination=(page,pageLength)=>{
        let new_konsultan=[]
        consultantIds.map((data)=>{
            new_konsultan.push(data.id)
        })
        dispatch(proyekAction.getRecapList(`/${proyek_detail.projectId}/${new_konsultan.length>0?new_konsultan.join(','):0}/${batch}/${kodesdm===null?0:kodesdm}/${sdm}/${kodeneed===null?0:kodeneed}/${need}/${surplus===0?0:surplus.id}/${status===0?0:status.id}/${page}/${pageLength}/${search!==''?search:'*'}`))
        // dispatch(proyekAction.getProyekList(`/${page}/${pageLength}/${search!==''?search:'*'}`))
    }
    const filterData=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Filter Daftar Recap Beban kerja`,
            modal_component: "filter_rbk",
            modal_size:400,
            modal_data:null,
            modal_action:'filter_rbk'
        }))
    }
    const detail_rbk=async (data)=>{
        let res= await dispatch(proyekAction.getDetailRecap(`/${data.id}/0`))
        
        if(res){
            let {tasks,hierarchy}=res.data
            // console.log(`res`, res)
            let result
            // let {tasks,hierarchy}=recap_detail
            // if(!isEmpty(tasks)){
            //     if(hierarchy===1){
            //         result =await dispatch(hirarkiAction.setConfigHirarkiIfTaskNotEmptyAndId1(tasks))
            //     }else{
    
            //         result=await dispatch(hirarkiAction.setConfigHirarkiIfTaskNotEmptyAndId2(tasks))
            //     }
            // }else{
            //     if(hierarchy===1){
            //         result=await dispatch(hirarkiAction.setConfigHirarkiIfTaskEmptyAndId1())
            //     }else{
            //         result=await dispatch(hirarkiAction.setConfigHirarkiIfTaskEmptyAndId2())
            //     }
            // }
            props.tabToggle('detail_rbk')

        }
    }
    
    const action_delete_rbk=async (data)=>{
        let res=await dispatch(proyekAction.deleteRecap(`/${data.id}/${props.profile.id}`))
        if(res){
            dispatch(proyekAction.getRecapList(`/${proyek_detail.projectId}/0/0/0/0/0/0/0/0/1/10/*`))
            dispatch(proyekAction.getDetailProject(`/${proyek_detail.projectId}`))
        }
        
    }
    const delete_rbk=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Jabatan',
                // userId:data.id,
                // clientId:data.clientId,
                action:()=>action_delete_rbk(data),
                msg:`<p>Apakah anda yakin menghapus ${data.jobTitle} </p>`
            },
            modal_action:'delete_jabatan'
        }))
    }
    const uploadJabatan=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Upload Jabatan`,
            modal_component: "upload_jabatan",
            modal_size:350,
            modal_data:{
                action:()=>dispatch(proyekAction.getRecapList(`/${proyek_detail.projectId}/0/0/0/0/0/0/0/0/1/10/*`)),
                action2:()=>dispatch(proyekAction.getDetailProject(`/${proyek_detail.projectId}`))
            },
            modal_action:'upload_jabatan'
        }))
    }
    const uploadListTugas=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Upload List Tugas`,
            modal_component: "upload_list_tugas",
            modal_size:350,
            modal_data:{
                action:()=>dispatch(proyekAction.getRecapList(`/${proyek_detail.projectId}/0/0/0/0/0/0/0/0/1/10/*`)),
                action2:()=>dispatch(proyekAction.getDetailProject(`/${proyek_detail.projectId}`))
            },
            modal_action:'upload_list_tugas'
        }))
    }
    const addJabatan=async ()=>{
        let res= await dispatch(proyekAction.getAddJabatanColumn(proyek_detail.projectId))
        // res= await dispatch(proyekAction.getHierarchyListForDropDown())
        if(res){
            // console.log(`res.data`, res.data)
            dispatch(modalToggle({
                modal_open: true,
                modal_title: `Tambah Jabatan`,
                modal_component: "jabatan",
                modal_size:450,
                modal_data:{
                    action:()=>dispatch(proyekAction.getRecapList(`/${proyek_detail.projectId}/0/0/0/0/0/0/0/0/1/10/*`)),
                    action2:()=>dispatch(proyekAction.getDetailProject(`/${proyek_detail.projectId}`)),
                    columns:res.data
                },
                modal_action:'add_jabatan'
            }))
        }
    }
    const tambahAsumsi=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Tambah Asumsi Waktu Kerja Efektif`,
            modal_component: "asumsi",
            modal_size:450,
            modal_data:{projectId:proyek_detail.projectId,data:null,action:'add'},
            modal_action:'add_asumsi'
        }))
    }
    const editAsumsi=(data)=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Edit Asumsi Waktu Kerja Efektif`,
            modal_component: "asumsi",
            modal_size:450,
            modal_data:{projectId:proyek_detail.projectId,data:data,action:'edit'},
            modal_action:'edit_asumsi'
        }))
    }
    const detailAsumsi=(data)=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Detail Asumsi Waktu Kerja Efektif`,
            modal_component: "asumsi",
            modal_size:450,
            modal_data:{projectId:proyek_detail.projectId,data:data,action:'detail'},
            modal_action:'detail_asumsi'
        }))
    }
    const actionDeleteAsumsi=async (data)=>{
        let res=await dispatch(proyekAction.deleteWKE(`/${data.id}/${props.profile.id}`))
        if(res){
            dispatch(proyekAction.getWke(`/${proyek_detail.projectId}`))
        }
        
    }
    const deleteAsumsi=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Asumsi',
                // userId:data.id,
                // clientId:data.clientId,
                action:()=>actionDeleteAsumsi(data),
                msg:`<p>Apakah anda yakin menghapus WKE ${data.name} </p>`
            },
            modal_action:'delete_wke'
        }))
    }
    const detailClient=()=>{
        props.tabToggle('detail_client')
    }
    const kode_akses=async (data)=>{
        let res= await dispatch(proyekAction.getKey(`/${proyek_detail.projectId}/${props.profile.id}`))
        if(res){
            dispatch(modalToggle({
                modal_open: true,
                modal_title: `Kode Akses`,
                modal_component: "kode_akses",
                modal_size:350,
                modal_data:res.data,
                modal_action:'kode_akses'
            }))
        }
        
    }
    const onClickSimpanFteModal=async ()=>{
        let res= await dispatch(proyekAction.updateFteProject(`/${proyek_detail.projectId}/${fte}/${props.profile.id}`))
        if(res){
            dispatch(proyekAction.getDetailProject(`/${proyek_detail.projectId}`))
            setbatasfte_modal(false)
        }
    }
    const renderNameFileExcel=()=>{
        return `Recap Beban Kerja Proyek ${proyek_detail.name}-${proyek_detail.company}`
    }
    const get_excel=async (data)=>{
        await dispatch(hirarkiAction.getPeriod())
        await dispatch(hirarkiAction.getType(`/${proyek.proyek_detail.projectId}`))
        let res= await dispatch(proyekAction.getDetailRecap(`/${data.id}/0`))
       

        if(res){
            let {hierarchy}=res.data

            dispatch(proyekAction.exportRbk(res.data,hirarki.type,proyek_detail))

        }
    }
    const exportAll=async ()=>{
        var zip = new JSZip();
        await dispatch(hirarkiAction.getPeriod())
        await dispatch(hirarkiAction.getType(`/${proyek.proyek_detail.projectId}`))

        await dispatch(proyekAction.getRecapList(`/${proyek_detail.projectId}/0/0/0/0/0/0/0/0/1/100/*`))
        await Promise.all(recap_list.map(async (data,i)=>{
            let res= await dispatch(proyekAction.getDetailRecap(`/${data.id}/0`))
            if(res){
                let buf=await dispatch(proyekAction.exportAll(res.data,hirarki.type,proyek_detail))
                zip.file(`${proyek_detail.company}-${res.data.jobTitle} (${res.data.shortname}).xlsx`, buf);
                return zip
            }
        }))
        zip.generateAsync({type:"blob"})
        .then(function(content) {
            saveAs(content, `Recap Beban Kerja - ${proyek_detail.company} (${proyek_detail.name})`);
        });
        
       
    }
    return (
        <div>
            {batasfte_modal&&<div className='backdoor' onClick={()=>setbatasfte_modal(false)}></div>}
            <div className='head-section'>
                <div>
                    <h4 style={{marginBottom:0,marginTop:0}}>Detail Proyek {proyek_detail.name} - {proyek_detail.company}</h4>
                </div>
                <div className='div-flex'>
                    <Button onClick={()=>props.tabToggle('detail')} color='primary' className='btn-rounded btn-remove-capital btn-action btn-outlined' variant="outlined">Kembali</Button>
                    <Button onClick={detailClient} color='primary' className='btn-rounded btn-remove-capital btn-action btn-outlined' variant="outlined">Detail Client</Button>
                    <ExcelFile filename={renderNameFileExcel()} element={<Button color='secondary' className='btn-rounded2 btn-remove-capital btn-action btn-outlined' variant="outlined">Export</Button>}>
                        <ExcelSheet dataSet={proyek.recap_export} name={`wla`}/>
                    </ExcelFile>
                    
                    <Button onClick={kode_akses} color='primary' className='btn-rounded btn-remove-capital btn-action' variant="contained">Kode Akses</Button>
                </div>
            </div>
            <div className='detail-abk-card-wrapper'>
                <div className='detail-abk-card'>
                    <p className='detail-abk-card-title'>Jumlah unit kerja / Departemen</p>
                    <div className='div-flex div-space-between'>
                        <img src={Institution} style={{width:30}}/>
                        <h2 className='card-value-abk'>{proyek_detail.workUnits===0?'-':proyek_detail.workUnits}</h2>
                    </div>
                </div>
                <div className='detail-abk-card'>
                    <p className='detail-abk-card-title'>Jumlah Jabatan</p>
                    <div className='div-flex div-space-between'>
                        <img src={UserTie} style={{width:30}}/>
                        <h2 className='card-value-abk'>{proyek_detail.jobTitles===0?'-':proyek_detail.jobTitles}</h2>
                    </div>
                </div>
                <div className='detail-abk-card'>
                    <p className='detail-abk-card-title'>Jumlah SDM</p>
                    <div className='div-flex div-space-between'>
                        <img src={Users1} style={{width:30}}/>
                        <h2 className='card-value-abk'>{proyek_detail.sdm===0?'-':proyek_detail.sdm}</h2>
                    </div>
                </div>
                <div className='detail-abk-card'>
                    <p className='detail-abk-card-title'>Kebutuhan SDM</p>
                    <div className='div-flex div-space-between'>
                        <img src={Users3} style={{width:30}}/>
                        <h2 className='card-value-abk'>{proyek_detail.need===0?'-':proyek_detail.need}</h2>
                    </div>
                </div>
                <div className='detail-abk-card'>
                    <p className='detail-abk-card-title'>Surplus/Deficit</p>
                    <div className='div-flex div-space-between'>
                        {proyek_detail.jobTitles!==0&&<img src={renderIconSurplus(proyek_detail.surplus)} style={{width:30}}/>}
                        <h2 className='card-value-abk-surplus' style={{color:renderColor(proyek_detail.surplus)}}>{proyek_detail.surplus===0?'-':proyek_detail.surplus}</h2>
                    </div>
                </div>
                <div className='detail-abk-card'>
                    <p className='detail-abk-card-title'>Status</p>
                    <div className='div-flex ' style={{justifyContent:'flex-end'}}>
                        <h2 className='card-value-abk' style={{color:renderColorCardstatus(proyek_detail.status)}}>{proyek_detail.status===''?'-':proyek_detail.status}</h2>
                    </div>
                </div>
            </div>
            <br/><br/>
            <div className='head-section'>
                <div>
                    <h4 style={{marginBottom:0,marginTop:0}}>Daftar Recap Beban kerja</h4>
                </div>
                <div className='div-flex'>
                    <Button onClick={uploadJabatan} color='primary' className='btn-rounded btn-remove-capital btn-action' variant="contained">Upload Jabatan</Button>
                    {/* <Button onClick={uploadListTugas} color='primary' className='btn-rounded btn-remove-capital btn-action' variant="contained">Upload List Tugas</Button> */}
                    <Button onClick={addJabatan} color='primary' className='btn-rounded btn-remove-capital btn-action' variant="contained">Tambah Jabatan</Button>
                </div>
            </div>
            <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table__head' style={{marginBottom:10}}>
                        <div className='div-flex div-align-center'>
                        <TextField
                            placeholder="Cari"
                            variant='outlined'
                            size='small'
                            InputLabelProps={{shrink: false}}
                            className={classes.search}
                            inputProps={{
                                className:classes.input
                            }}
                            onChange={(e)=>searchToggle(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <img alt="search" src={SearchImg} style={{width:15}}/>
                                </InputAdornment>
                                ),
                            }}
                        />
                        &nbsp;&nbsp;
                        <div className='div-flex div-align-center'>
                            <p className='semi-bold-without-margin'>Default FTE : {proyek_detail.fte}</p>
                            &nbsp;&nbsp;&nbsp;
                            <div className='position-relative'>
                                <img src={Edit} onClick={()=>setbatasfte_modal(!batasfte_modal)} style={{width:15,cursor:"pointer"}}/>

                                {batasfte_modal&&<div className='batasfte-modal div-flex div-align-center'>
                                    <InputMask
                                        maskChar={null}
                                        mask="9.99"
                                        onChange={(e)=>setfte(e.target.value)}
                                        value={fte}
                                    >
                                    {() =>
                                        <TextField
                                            label="Batas Atas FTE"
                                            variant='outlined'
                                            size='small'
                                            className={classes.textField}
                                        />
                                    }
                                    </InputMask>
                                    
                                    &nbsp;&nbsp;
                                    <Button onClick={onClickSimpanFteModal} className='btn-remove-capital btn-rounded' size='small' variant='contained' color='primary'>Simpan</Button>
                                </div>}
                            </div>
                        </div>
                        </div>
                        <div className='div-flex' style={{position:'relative'}}>
                        <Button onClick={exportAll} color="secondary" className='btn-remove-capital' variant="text">Export Semua</Button>
                        &nbsp;&nbsp;
                        <Button onClick={filterData} color="primary" className='btn-remove-capital' variant="text">Filter</Button>

                        </div>
                    </div>
                    <div className='card-table__content'>
                    <TableContainer className={classes.paper} component={Paper}>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                        <TableCell align="center" style={{width:10}}>Unit Kerja / Departemen</TableCell>
                        <TableCell align="center" style={{width:10}}>Nama Jabatan</TableCell>
                        {/* <TableCell align="center" style={{width:10}} >Hirarki Tugas</TableCell> */}
                        {/* <TableCell align="center" style={{width:10}}>Konsultan</TableCell> */}
                        {/* <TableCell align="center" style={{width:10}}>Batch</TableCell> */}
                        {recap_column.map((d,i)=>(
                            <TableCell key={i} align="center" style={{width:10}}>{d.text}</TableCell>

                        ))}
                        <TableCell align="center" style={{width:10}}>Jml SDM</TableCell>
                        <TableCell align="center" style={{width:10}}>Kebutuhan SDM</TableCell>
                        <TableCell align="center" style={{width:10}}>Surplus/ Deficit</TableCell>
                        <TableCell align="center" style={{width:10}}>Status</TableCell>
                        <TableCell align="center" style={{width:100}}>Aksi</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {general.isLoadingTable?
                                <Skeleton count={10}/>
                            :
                            recap_list.length<1?
                            <TableRow>
                                <TableCell colSpan={10} style={{textAlign:'center'}}>Belum ada recap beban kerja</TableCell>
                            </TableRow>
                            :recap_list.map((data,i)=>(
                                <TableRow key={i}>
                                    <TableCell align="center">{data.shortname===''?'-':data.shortname}</TableCell>
                                    <TableCell align="center">{data.jobTitle===''?'-':data.jobTitle}</TableCell>
                                    {/* <TableCell align="center">{data.hierarchy===1?'TU-P':'TU-DT-P'}</TableCell> */}
                                    {/* <TableCell align="center">{data.consultant===''?'-':data.consultant}</TableCell> */}
                                    {/* <TableCell align="center">{data.batch===''?'-':data.batch}</TableCell> */}
                                    {data.columns.map((c,i)=>(
                                        <TableCell key={i} align="center">{c.data}</TableCell>

                                    ))}
                                    <TableCell align="center">{data.sdm}</TableCell>
                                    <TableCell align="center">{data.need}</TableCell>
                                    <TableCell align="center" style={{color:renderColor(data.surplus),fontWeight:'bold'}}>{data.surplus}</TableCell>
                                    <TableCell align="center" style={{color:renderColorStatus(data.status),fontWeight:'bold'}}>{data.status}</TableCell>
                                    <TableCell align="center">
                                        <div >
                                        <img src={DownloadExcel} onClick={()=>get_excel(data)} className='icon-action'/>
                                        <img src={Eye} onClick={()=>detail_rbk(data)} className='icon-action'/>
                                        <img src={close} onClick={()=>delete_rbk(data)} className='icon-action'/>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                            
                            }
                        </TableBody>
                    </Table>
                    </TableContainer>

                    <div className='card-table__pagination'>
                    {recap_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={recap_pagination.page}
                            pageLength={recap_pagination.perPage}
                            totalRecords={recap_pagination.total}
                            totalRecords={recap_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    }
                </div>
                    </div>
                </div>
            </div>
            <br/><br/>
            <div className='head-section'>
                <div>
                    <h4 style={{marginBottom:0,marginTop:0}}>Daftar Asumsi Waktu Kerja Efektif</h4>
                </div>
                <div className='div-flex'>
                    <Button onClick={tambahAsumsi} color='primary' className='btn-rounded btn-remove-capital btn-action' variant="contained">Tambah Asumsi</Button>
                </div>
            </div>
            <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table__content'>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                        <TableCell align="left" style={{width:10}}>No.</TableCell>
                        <TableCell align="left" style={{width:150}}>Nama Jenis WKE</TableCell>
                        <TableCell align="left"  >Jumlah Jam Kerja</TableCell>
                        <TableCell align="left" >Jumlah Hari Kerja</TableCell>
                        <TableCell align="left" >Allowance</TableCell>
                        <TableCell >Total WKE</TableCell>
                        <TableCell align="center">Aksi</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {general.isLoadingTable?
                                <Skeleton count={7}/>
                            :
                            wke_list.length<1?
                            <TableRow>
                                <TableCell colSpan={7} style={{textAlign:'center'}}>Belum ada WKE</TableCell>
                            </TableRow>
                            :wke_list.map((data,i)=>(
                                <TableRow key={i}>
                                    <TableCell >{i+1}</TableCell>
                                    <TableCell >{data.name}</TableCell>
                                    <TableCell>{data.hoursPerDay} jam/hari</TableCell>
                                    <TableCell>{data.annualWorkingDay}/tahun</TableCell>
                                    <TableCell>{data.allowance} Menit</TableCell>
                                    <TableCell  style={{fontWeight:'bold'}}>{data.totalWKE} Menit</TableCell>
                                    <TableCell align="center">
                                        <div >
                                        <img src={Eye} onClick={()=>detailAsumsi(data)} className='icon-action'/>
                                        <img src={Edit} onClick={()=>editAsumsi(data)} className='icon-action'/>
                                        <img src={close} onClick={()=>deleteAsumsi(data)} className='icon-action'/>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                            
                            }
                        </TableBody>
                    </Table>
                    <div className='card-table__pagination'>
                    {/* {client_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={client_pagination.page}
                            pageLength={client_pagination.perPage}
                            totalRecords={client_pagination.total}
                            totalRecords={client_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    } */}
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
