import React, { useState,useEffect } from 'react'
import DataTable from 'components/DataTable'
import { TableRow,TableCell } from '@material-ui/core'
import SearchTable from 'components/SearchTable'
import _ from 'lodash'
import { useSelector,useDispatch } from 'react-redux'
import * as actionSa from 'redux/actions/sa'
import * as actionTalenta from 'redux/actions/talenta'
import * as actionGeneral from 'redux/actions/general'
import Close from 'assets/icon/close.svg'
import Eye from 'assets/icon/eye.png'
const RenderHead=(props)=>(
    <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
        <SearchTable
            {...props}
            searchToggle={(value)=>props.searchToggle(value)}
        />
    </div>
)
export default function List_talent(props) {
    const sa = useSelector(state => state.sa)
    const dispatch = useDispatch()
    const [search, setsearch] = useState('')
    let {profile}=props
    let {client_detail,list_proyek,pagination}=sa
    useEffect(() => {
        getProyek(`/${client_detail.id}/1/10/*`)
    }, [])
    const getProyek=async (slug)=>{
        let res=await dispatch(actionSa.getSaProyekList(slug))
        return res
    }
    const searchToggle=_.debounce(async (e)=>{
        setsearch(e)
        getProyek(`/${client_detail.id}/1/10/${e.length>0?e:'*'}`)
    },1000)
    const togglePagination=(page,pageLength)=>{
        getProyek(`/${client_detail.id}/${page}/${pageLength}/${search.length>0?search:'*'}`)
    }
    const detailProyek=(data)=>{
        dispatch(actionTalenta.setTalenta({project_id:data.id}))
        props.tabToggle('detail_talent')
    }
    const deleteAction=async (data)=>{
        let res=await dispatch(actionTalenta.deleteTalentaProject(`/${data.id}/${profile.id}`))
        if(res){
            getProyek(`/${client_detail.id}/1/10/*`)
        }
    }
    const deleteProyek=(data)=>{
        dispatch(actionGeneral.modalToggle({
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Kegiatan',
                action:()=>deleteAction(data),
                msg:`<p>Yakin hapus <b>${data.name}</b> ?</p>`
            },
            modal_action:'delete_talenta_project'
        }))
    }
    return (
        <div>
            <div className='head-section'>
                <h4><b>Daftar Kegiatan Talent Maping</b></h4>
            </div>
            <br/>
            <DataTable
                head={['No.','Nama Kegiatan','Tahun','Deksripsi','Jumlah Karyawan','Aksi']}
                body={()=>{
                    return list_proyek.map((data,i)=>(
                        <TableRow key={i} data-testid="proyek-item">
                            <TableCell>{i+1}</TableCell>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>{data.year}</TableCell>
                            <TableCell>{data.description.length>50?`${data.description.slice(0,50)}..`:data.description}</TableCell>
                            <TableCell>{data.employees}</TableCell>
                            <TableCell align="right">
                                <img src={Eye} onClick={()=>detailProyek(data)} className='icon-action'/>
                                <img src={Close} onClick={()=>deleteProyek(data)} className='icon-action'/>
                            </TableCell>
                        </TableRow>
                    ))
                }}
                cardHead={<RenderHead {...props}  searchToggle={searchToggle} />}
                loading={false}
                pagination={pagination}
                togglePagination={togglePagination}
            />
            <br/>

        </div>
    )
}
