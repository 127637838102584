import React,{useState} from 'react'
import TextField from 'components/TextField'
import { DatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import AutoCompleteSelect from 'components/Select'
import { Button } from '@material-ui/core';
import {handleFile} from 'components/handleFile'
import { postTalentaProject } from 'redux/actions/talenta';
import { useDispatch } from 'react-redux';
export default function Add_talenta_project(props) {
    const dispatch = useDispatch()
    const [open_picker, setOpen] = useState(false)
    const [state, setstate] = useState({
        year:{
            id:moment().year(),
            value:moment(),
            text:moment().year().toString()
        },
        filename:'',
        file:null,
        file_error:'',
        nama:'',
        deskripsi:'',
        sumbuX:'Potensial',
        sumbuY:'Kinerja'
    })
    const onChangeDate=(date)=>{
        setstate({
            ...state,
            year:{
                id:moment(date).year(),
                value:moment(date),
                text:moment(date).year().toString()
            }
        })
    }
    const handleDisable=()=>{
        if(state.nama!==""&&state.filename!==""&&state.deskripsi!==""&&state.sumbuX!==""&&state.sumbuY!==""){
            return false
        }else{
            return true
        }
    }
    const handleFiles=async (evt)=>{
        let handle=await handleFile(evt)
        if(handle.file_error===null){
            setstate({
                ...state,
                filename:handle.file_name,
                file:handle.file,
                file_error:''
            })
        }else{
            setstate({
                ...state,
                filename:'',
                file:null,
                file_error:handle.file_error
            })
        }
    }
    const onSave=async ()=>{
        let fd= new FormData()
        fd.append('id',0)
        fd.append('Name',state.nama)
        fd.append('Description',state.deskripsi)
        fd.append('Year',state.year.id)
        fd.append('Xaxis',state.sumbuX)
        fd.append('Yaxis',state.sumbuY)
        fd.append('Files',state.file)
        fd.append('UserId',props.profile.id)
        let res= await dispatch(postTalentaProject(fd))
        if(res){
            props.modal_data.afterPost({...res.data,filename:state.filename})

        }
    }
    const onChange=(e)=>{
        let {name,value}=e.target
        setstate({...state,[name]:value})
    }
    return (
        <div>
            <TextField
                label="Nama Kegiatan"
                onChange={(e)=>setstate({...state,nama:e.target.value})}
                value={state.nama}
                color='primary'
                variant='outlined'
                size='small'
                name='name'
                style={{marginBottom:20}}
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    openTo="year"
                    format="dd/MM/yyyy"
                    label="Date of birth"
                    views={["year"]}
                    value={moment()}
                    onChange={(date)=>onChangeDate(date)}
                    open={open_picker}
                    onAccept={()=>setOpen(!open_picker)}
                    onClose={()=>setOpen(!open_picker)}
                    TextFieldComponent={()=>(
                        <TextField
                            label="Tahun"
                            value={state.year.text}
                            color='primary'
                            variant='outlined'
                            size='small'
                            name='name'
                            onFocus={()=>setOpen(true)}
                            style={{marginBottom:20}}
                        />
                    )}
                />
            </MuiPickersUtilsProvider>
            
            <TextField
                label="Deskripsi Kegiatan"
                onChange={onChange}
                value={state.deskripsi}
                color='primary'
                variant='outlined'
                size='small'
                name='deskripsi'
                style={{marginBottom:20}}
                multiline
            />
            <p className='semi-bold'>Penamaan Sumbu Manajemen Talenta</p>
            <div className='div-flex div-space-between'>
                <TextField
                    label="Sumbu X"
                    onChange={(e)=>setstate({...state,sumbuX:e.target.value})}

                    value={state.sumbuX}
                    color='primary'
                    variant='outlined'
                    size='small'
                    name='name'
                    style={{marginBottom:20}}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    label="Sumbu Y"
                    onChange={(e)=>setstate({...state,sumbuY:e.target.value})}

                    value={state.sumbuY}
                    color='primary'
                    variant='outlined'
                    size='small'
                    name='name'
                    style={{marginBottom:20}}
                />
            </div>
            <p className='semi-bold'>File peta talenta</p>
            <div className='div-flex div-space-between div-align-center'>
                <p style={{maxWidth:300,fontWeight:state.filename!==""?'bold':'normal'}} className='semi-bold-without-margin'>{state.filename!==""?state.filename:"Belum ada file yang diupload"}</p>
                <Button component="label" variant="text" color="primary" className="btn-remove-capital" size="small">
                    {state.filename!==""?"Ganti File":"Upload File"}
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e)=>handleFiles(e)}
                    />
                </Button>
            </div>
            <br/>
            <div style={{textAlign:'right'}}>
                <Button onClick={onSave} disabled={handleDisable()} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
