import axios from "axios";
import { URL} from './base_url'
import {refreshToken} from '../redux/actions/auth'
import Cookie from 'universal-cookie'
import { setError ,modalToggle} from "redux/actions/general";
const cookie=new Cookie()



export const apiCall=({method,url,data='',special_alert})=>async(dispatch)=>{
    let findTalentaRoute=url.toLowerCase().match('/talent')
    try{
        const response=await axios({
            method:method,
            url:URL+url,
            data:data.data||"",
            auth:data.auth,
            headers:data.headers,
            timeout:5400000
        })
        return response
    }catch(error){
        let response=error.response
        if(response!==undefined){
            if(error.response.status===401){
                let refresh=cookie.get('refresh_cookie')
                let token=cookie.get('login_cookie')
                if(response.data!==''){
                    dispatch(setError(response.data))
                   
                }else{
                    dispatch(refreshToken(token,refresh,findTalentaRoute?'talenta':'wla'))
                }
            }else if(error.response.status===400){
                console.log(`url`, url)
                if(url==='/wla/abk'){
                    dispatch(modalToggle({ 
                        modal_open: true,
                        modal_title: "",
                        modal_component: "",
                        modal_size:400,
                        modal_type:'alert',
                        modal_data:{
                            msg:`<p>${error.response.data.error}</p>`
                        },
                        modal_action:'error'
                    }))
                }else if(url==='/wla/respondent/info'){
                    dispatch(modalToggle({ 
                        modal_open: true,
                        modal_title: "",
                        modal_component: "",
                        modal_size:400,
                        modal_type:'alert',
                        modal_data:{
                            msg:`<p>${error.response.data.error}</p>`
                        },
                        modal_action:'error'
                    }))
                }else{
                    // alert('asdf')
                    if(special_alert){
                        dispatch(setError(error.response.data.error))
                        // console.log(`error.response.data.error`, error.response.data.error)
                    }else{
                        dispatch(modalToggle({ 
                            modal_open: true,
                            modal_title: "",
                            modal_component: "",
                            modal_size:400,
                            modal_type:'alert',
                            modal_data:{
                                msg:'<p>Opps, An error occurred,input field correctly or please contact Support team 🙏</p>'
                            },
                            modal_action:'error'
                        }))
                    }
                    
                }
                
                
                return error
               
            }else{
                dispatch(modalToggle({ 
                    modal_open: true,
                    modal_title: "",
                    modal_component: "",
                    modal_size:400,
                    modal_type:'alert',
                    modal_data:{
                        msg:'<p>Opps, An error occurred,check your connection or please contact Support team 🙏</p>'
                    },
                    modal_action:'error'
                }))
            }
        }else{
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "",
                modal_component: "",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:'<p>Opps, An error occurred,check your connection or please contact Support team 🙏</p>'
                },
                modal_action:'error'
            }))
        }
        return error
    }
}