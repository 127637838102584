import * as actionType from 'redux/constants/sa'
import * as actionGeneral from './general'
import {apiCall} from 'service/apiCall'
import _ from "lodash";
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import moment from 'moment'
import { saveAs } from 'file-saver'

import Cookie from 'universal-cookie'
const cookie=new Cookie()
const token=cookie.get('login_cookie')
const profile=cookie.get('profile_cookie')

export const setSa=(obj)=>async dispatch=>{
    dispatch({
        type:actionType.SET_SA,
        payload:obj
    })
    return obj
}

export const getClientList=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoadingTable(true))
    let dataReq={
        url:`/Sa/client/talent${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoadingTable(false))
        dispatch(setSa({client_list:res.data.clients}))
        dispatch(setSa({client_pagination:res.data.info}))
        return res
    }else{
        dispatch(actionGeneral.setLoadingTable(false))
        return res

    }
}
export const getClientListTextId=(slug)=>async(dispatch)=>{
    // await dispatch(actionGeneral.setLoadingTable(true))
    let dataReq={
        url:`/Sa/client/talent${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        let new_list=[]
        res.data.clients.map((d)=>{
            new_list.push({id:d.clientId,text:d.company})
        })
        // dispatch(actionGeneral.setLoadingTable(false))
        dispatch(setSa({client_text_id:new_list}))
        return res.data.clients
    }else{
        // dispatch(actionGeneral.setLoadingTable(false))
        return res

    }
}

export const deleteClient=(slug)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:`/sa/client${slug}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===204){
            dispatch(actionGeneral.setLoading(false))
            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Client berhasil dihapus",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
        }
}

export const getDetailClient=(clientId)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Sa/talent/client/${clientId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setSa({client_detail:res.data}))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
        // return res

    }
}
export const getAdminClient=(clientId)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Sa/talent/admin/${clientId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setSa({client_admin:res.data}))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
        // return res

    }
}

export const postClientCredit=(data)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:`/sa/talent/credit`,
            method:'POST',
            data:{data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===204){
            dispatch(actionGeneral.setLoading(false))
            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Client terupdate",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
        }
}
export const putClient=(data)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:`/sa/talent/client`,
            method:'PUT',
            data:{data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===200){
            dispatch(actionGeneral.setLoading(false))
            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Client terupdate",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
        }
}
export const postAdmin=(data)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:`/Sa/talent/admin`,
            method:'post',
            data:{data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===204){
            dispatch(actionGeneral.setLoading(false))
            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Admin terupdate",
                modal_component: "admin",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
        }
}
export const resetAdmin=(userId)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:`/Profile/reset/${userId}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===204){
            dispatch(actionGeneral.setLoading(false))
            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Admin telah direset",
                modal_component: "admin",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
        }
}

export const addClient=(data)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:`/Sa/talent/client`,
            method:'POST',
            data:{data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===200){
            dispatch(actionGeneral.setLoading(false))
            dispatch({
                type:actionType.SET_CLIENT_ID,
                payload:res.data.clientId
            })
            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Client tersimpan",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
        }
}


export const getSaProyekList=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Sa/talent/list${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoading(false))
       
        dispatch(setSa({list_proyek:res.data.items}))
        dispatch(setSa({pagination:res.data.info}))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
        // return res

    }
}
export const getHistoryCredit=(projectId)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/sa/talent/credit/history/${projectId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoading(false))
       
        dispatch(setSa({list_history:res.data}))
        // dispatch(setSa({pagination:res.data.info}))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
        // return res

    }
}
