import React, { Component } from 'react'
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import './style.css'
import {useSelector} from 'react-redux'
const override = css`
    position: fixed;
    z-index: 100000;
    height: 100vh;
    width: 100%;
    background-color: #00000085;
    display: flex; 
    justify-content: center;
    align-items: center;
    top:0
`;
export default function Index() {
    const general = useSelector(state => state.general)
    const {isLoading}=general

    return (
        <BeatLoader
                    size={30}
                    margin={2}
                    color={"#00a1ed"}
                    loading={isLoading}
                    css={override}
                />
    )
}
