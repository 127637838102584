import React,{useState,useEffect} from 'react'
import {Button} from '@material-ui/core'
import {useSelector,useDispatch} from 'react-redux'
import TextField from 'components/TextField'
import * as actionSa from 'redux/actions/sa'
export default function Add_admin(props) {
    const dispatch = useDispatch()
    const [admins, setadmin] = useState({
        userId:props.profile.id,
        name:'',
        email:'',
        password:''
    })
    const sa = useSelector(state => state.sa)
    let {client_detail}=sa
    let {modal_data}=props

    useEffect(() => {
        console.log('modal_data', modal_data)
       
    }, [])
    const onClickSimpan=async ()=>{
        let data={
            userId:admins.userId,
            clientId:client_detail.id,
            admins:[
                ...modal_data.client_admin,
                {
                    name:admins.name,
                    password:admins.password,
                    email:admins.email
                }
            ]
        }
        let res=await dispatch(actionSa.postAdmin(data))
        if(res){
            modal_data.afterPostAdmin()
        }
    }
    const handleDisable=()=>{
        if(admins.password.length>0){
            if(admins.password.length>=6){
                return false
            }else{
                return true
            }
        }else{
            if(props.modal_action!=='edit_admin'){
                return true
            }else{
                return false
            }
        }
    }
    return (
        <div>
            <TextField
                label={<>Nama</>}
                variant='outlined'
                value={admins.name}
                size='small'
                style={{marginBottom:20}}
                onChange={(e)=>setadmin({...admins,name:e.target.value})}
            />
            <TextField
                label={<>Username/Email</>}
                variant='outlined'
                value={admins.email}
                size='small'
                type="email"
                style={{marginBottom:20}}
                onChange={(e)=>setadmin({...admins,email:e.target.value})}
            />
            <TextField
                label={<>Password</>}
                variant='outlined'
                value={admins.password}
                size='small'
                placeholder="min 6 character"
                // type="password"
                style={{marginBottom:20}}
                onChange={(e)=>setadmin({...admins,password:e.target.value})}
            />
            <div style={{textAlign:'right'}}>
                <Button disabled={handleDisable()} size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
