export const GET_HIERARCHY_FOR_DROPDOWN='GET_HIERARCHY_FOR_DROPDOWN'
export const GET_PROYEK='GET_PROYEK'
export const GET_WKE='GET_WKE'
export const SET_PROYEK_DETAIL='SET_PROYEK_DETAIL'


export const GET_RECAP_LIST='GET_RECAP_LIST'
export const SET_RECAP_FILTER='SET_RECAP_FILTER'
export const RESET_RECAP_FILTER='RESET_RECAP_FILTER'

export const GET_RECAP_DETAIL='GET_RECAP_DETAIL'
export const GET_WORK_UNIT='GET_WORK_UNIT'
export const SET_TAB='SET_TAB'

export const RESET_PROYEK='RESET_PROYEK'
export const GET_RECAP_COLUMN='GET_RECAP_COLUMN'
export const GET_RECAP_DETAIL_COLUMN='GET_RECAP_DETAIL_COLUMN'