import React,{useState,useEffect} from 'react'
import Institution from 'assets/icon/Institution.svg'
import Users3 from 'assets/icon/Users3.svg'
import { Button } from '@material-ui/core'
import Modal from '../modal'
import TextField from 'components/TextField'
import * as actionTalenta from 'redux/actions/talenta'
import { useDispatch } from 'react-redux'
export default function Proyek_info(props) {
    let {project_info,profile}=props

    const dispatch = useDispatch()
    const [desc, setdesc] = useState(project_info.description)

    const [modal_desc, setmodal_desc] = useState(false)
    useEffect(() => {
        setdesc(project_info.description)
    }, [])
    const onSave=async ()=>{
        let data={
            id:project_info.id,
            text:desc
        }
        let res=await dispatch(actionTalenta.postDeskripsi(data,profile.id))
        if(res){
            dispatch(actionTalenta.setTalenta({
                project_info:{...project_info,description:desc}
            }))
        }
    }
    return (
        <div>
        {modal_desc&&<div onClick={()=>setmodal_desc(false)} className='backdoor'></div>}

        <div className='proyek-info-wrapper'>
            <div className='box-company'>
                <p className='box-title'>Nama Perusahaan</p>
                <div className='div-flex div-align-center'>
                    <img src={Institution}/>
                    &nbsp;&nbsp;&nbsp;
                    <h3>{project_info.name}</h3>
                </div>
            </div>
            <div className='box-year'>
                <p className='box-title'>Tahun</p>
                <h3>{project_info.year}</h3>

            </div>
            <div className='box-desc' style={{position:'relative'}}>
                {modal_desc&&<Modal
                    modal_title="Deskripsi"
                    modal_size={400}
                    modalToggleReset={()=>setmodal_desc(false)}
                    body={
                        <div style={{padding:'0 20px 15px 20px'}}>
                            <TextField
                                label="Deskripsi"
                                onChange={(e)=>setdesc(e.target.value)}
                                value={desc}
                                color='primary'
                                variant='outlined'
                                size='small'
                                name='name'
                                style={{marginBottom:20}}
                                multiline
                            />
                            <div style={{textAlign:'right'}}>
                                <Button onClick={onSave} disabled={false} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
                            </div>
                        </div>
                    }
                />}
                <div className='div-flex div-space-between div-align-center'>
                    <p className='box-title'>Deskripsi</p>
                    <p onClick={()=>setmodal_desc(true)} style={{margin:'0 0 10px 0',cursor:'pointer',color:'#00a1ed',fontWeight:'bold',fontSize:14,fontFamily:'Lato,sans-serif'}}>Lihat lebih lengkap</p>
                </div>
                <p className='desc-content' >{project_info.description.length>95?`${project_info.description.slice(0,95)}..`:project_info.description}</p>
            </div>
            <div className='box-employee'>
                <p className='box-title'>Jumlah Karyawan</p>
                <div className='div-flex div-align-center'>
                    <img src={Users3}/>
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                    <h3>{project_info.employees}</h3>
                </div>
            </div>
        </div>
        </div>

    )
}
