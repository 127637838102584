import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Info from "./info";
import { makeStyles } from "@material-ui/core/styles";
import { modalToggle } from "redux/actions/general";
import HirarkiItem from "components/HirarkiItem";
import * as proyekAction from "redux/actions/proyek";
import * as hirarkiAction from "redux/actions/hirarki";
import { isEmpty, orderBy } from "lodash";

const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 8,
    },
    width: "100%",
  },
  textField2: {
    [`& fieldset`]: {
      borderRadius: 8,
    },
    width: "100%",
  },
  cssLabel: {
    color: "green",
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  root: {
    "&$disabled $notchedOutline": {
      borderColor: "red",
      color: "red",
    },
    "&$disabled ": {
      color: "red",
    },

    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  root2: {
    "&$disabled $notchedOutline": {
      borderColor: "#777777",
      color: "#777777",
    },
    "&$disabled ": {
      color: "#777777",
    },

    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  disabled: {},
  notchedOutline: {},
}));
export default function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const proyek = useSelector((state) => state.proyek);
  const hirarki = useSelector((state) => state.hirarki);
  const [responden_modal, setresponden_modal] = useState(false);
  const [wke_modal, setwke_modal] = useState(false);
  const [wke, setwke] = useState(null);
  const [fte, setfte] = useState(null);
  const [respondenId, setrespondenId] = useState(null);
  const [batasfte_modal, setbatasfte_modal] = useState(false);
  const [urutan, seturutan] = useState(1);
  const { recap_detail, proyek_detail, recap_column, recap_detail_column } =
    proyek;
  const { hirarki_wla, type } = hirarki;
  console.log("tes", hirarki_wla);
  useEffect(() => {
    setwke(recap_detail.wke.id);
    setfte(recap_detail.fte);
    dispatch(hirarkiAction.getPeriod());
    dispatch(hirarkiAction.getType(`/${proyek.proyek_detail.projectId}`));
    configHirarki();
    // dispatch(proyekAction.getDetailRecapCollumns(recap_detail.jobTitleId))
  }, [recap_detail]);
  const configHirarki = async () => {
    let result;
    let { tasks, hierarchy, mcb } = recap_detail;
    console.log("tasks", tasks);

    if (!isEmpty(tasks)) {
      if (hierarchy === 1) {
        result = await dispatch(
          hirarkiAction.setConfigHirarkiIfTaskNotEmptyAndId1(tasks, type, mcb)
        );
      } else {
        result = await dispatch(
          hirarkiAction.setConfigHirarkiIfTaskNotEmptyAndId2(tasks, type, mcb)
        );
      }
    } else {
      if (hierarchy === 1) {
        result = await dispatch(
          hirarkiAction.setConfigHirarkiIfTaskEmptyAndId1(mcb)
        );
      } else {
        result = await dispatch(
          hirarkiAction.setConfigHirarkiIfTaskEmptyAndId2(mcb)
        );
      }
    }
  };
  const editJabatan = () => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: `Edit Infromasi Posisi/Jabatan Kerja`,
        modal_component: "jabatan",
        modal_size: 450,
        modal_data: {
          recap_detail,
          action: () =>
            dispatch(
              proyekAction.getDetailRecap(`/${recap_detail.jobTitleId}/0`)
            ),
        },
        modal_action: "edit_jabatan",
      })
    );
  };
  const detailKetWla = (remarks) => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: `Keterangan Hasil WLA`,
        modal_component: "keterangan_wla",
        modal_size: 350,
        modal_data: {
          remarks: remarks,
          action: () =>
            dispatch(
              proyekAction.getDetailRecap(`/${recap_detail.jobTitleId}/0`)
            ),
        },
        modal_action: "keterangan_wla",
      })
    );
  };
  const onClickRespondenModalItem = async (id) => {
    let res = await dispatch(
      proyekAction.getDetailRecap(`/${recap_detail.jobTitleId}/${id}`)
    );
    if (res) {
      setrespondenId(id);
      setresponden_modal(false);
    }
  };
  const onClickWkeModalItem = async () => {
    let res = await dispatch(
      proyekAction.getDetailRecapByWke(
        `/${recap_detail.jobTitleId}/${recap_detail.jobDetailId}/${wke}/${props.profile.id}`
      )
    );
    if (res) {
      setwke_modal(false);
    }
  };
  const onClickSimpanFteModal = async () => {
    let res = await dispatch(
      proyekAction.getDetailRecapByFte(
        `/${recap_detail.jobTitleId}/${recap_detail.jobDetailId}/${fte}/${props.profile.id}`
      )
    );
    if (res) {
      setbatasfte_modal(false);
    }
  };

  const onClickSimpanHirarki = async () => {
    let new_tasks = [];
    let new_hirarki_wla = orderBy(hirarki_wla, ["no"], ["asc"]);
    if (recap_detail.mcb === false) {
      if (recap_detail.hierarchy === 1) {
        new_hirarki_wla.map((data) => {
          let new_prosess = [];
          data.child.map((child) => {
            new_prosess.push({
              processId: child.id,
              process: child.title,
              periodId:
                child.detail.periode === null ? 0 : child.detail.periode?.id,
              quantity:
                child.detail.kuantitas === ""
                  ? 0
                  : parseInt(child.detail.kuantitas),
              duration:
                child.detail.durasi === "" ? 0 : parseInt(child.detail.durasi),
              employees:
                child.detail.pegawai === ""
                  ? 0
                  : parseInt(child.detail.pegawai),
              typeId:
                child.detail.jenis_tugas === null
                  ? 0
                  : child.detail.jenis_tugas.id,
              remarks: child.detail.keterangan,
            });
          });
          new_tasks.push({
            taskId: data.id,
            task: data.title,
            children: [],
            processes: new_prosess,
          });
        });
      } else {
        new_hirarki_wla.map((data) => {
          let new_children = [];
          data.child.map((child) => {
            let new_prosess = [];
            child.child.map((prossess) => {
              new_prosess.push({
                processId: prossess.id,
                process: prossess.title,
                periodId:
                  prossess.detail.periode === null
                    ? 0
                    : prossess.detail.periode.id,
                quantity:
                  prossess.detail.kuantitas === ""
                    ? 0
                    : parseInt(prossess.detail.kuantitas),
                duration:
                  prossess.detail.durasi === ""
                    ? 0
                    : parseInt(prossess.detail.durasi),
                employees:
                  prossess.detail.pegawai === ""
                    ? 0
                    : parseInt(prossess.detail.pegawai),
                typeId:
                  prossess.detail.jenis_tugas === null
                    ? 0
                    : prossess.detail.jenis_tugas.id,
                remarks: prossess.detail.keterangan,
              });
            });
            new_children.push({
              taskId: child.id,
              task: child.title,
              processes: new_prosess,
            });
          });
          new_tasks.push({
            taskId: data.id,
            task: data.title,
            children: new_children,
          });
        });
      }
    }

    if (recap_detail.mcb === true) {
      new_hirarki_wla.map((data) => {
        let new_children = [];
        data.child.map((mcb) => {
          let new_mcb = [];
          new_mcb.push({
            id: mcb.detail.id,
            taskId: data.id,
            bebanKerja:
              mcb.detail.bebanKerja === null
                ? 0
                : parseInt(mcb.detail.bebanKerja),
            skr: mcb.detail.skr === null ? 0 : parseInt(mcb.detail.skr),
            wpt: 0,
            satuanBebanKerja: mcb.detail.satuanBebanKerja,
            satuanSKR: mcb.detail.satuanSKR,
            satuanWPT: mcb.detail.satuanWPT,
          });
          let new_mcb_object = {};
          for (let i = 0; i < new_mcb.length; i++) {
            Object.assign(new_mcb_object, new_mcb[i]);
          }
          new_children.push({
            id: mcb.id,
            task: mcb.title,
            mcb: new_mcb_object,
            processes: [],
          });
        });
        new_tasks.push({
          id: data.id,
          task: data.title,
          children: new_children,
        });
      });
    }

    let new_data = {
      jobDetailId: recap_detail.jobDetailId,
      tasks: new_tasks,
      userId: props.profile.id,
    };
    let res = await dispatch(hirarkiAction.postJawabanResponden(new_data));
    if (res) {
      props.tabToggle("detail_rbk");
      dispatch(proyekAction.getDetailRecap(`/${recap_detail.jobTitleId}/0`));
      dispatch(
        modalToggle({
          modal_open: true,
          modal_title: "Hirarki Element Tugas Tersimpan",
          modal_component: "hirarki",
          modal_size: 400,
          modal_type: "alert",
          modal_data: {
            msg: `<p>Hirarki Element Tugas berhasil di simpan pada Jabatan ${recap_detail.jobTitle} pada proyek ${proyek_detail.name} untuk ${proyek_detail.company}</p> `,
            ...res.data,
          },
          modal_action: "success",
        })
      );
    }
  };
  const editRbk = () => {
    props.tabToggle("edit_rbk");
  };
  const onClickVerifikasi = () => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: `Verifikasi Hasil WLA`,
        modal_component: "verifikasi_wla",
        modal_size: 350,
        modal_data: {
          msg: `<p>Apakah anda yakin ingin memverifikasi hasil WLA untuk Jabatan  <b>(${recap_detail.shortname}) - ${recap_detail.jobTitle}</b> dengan responden <b>${recap_detail.respondent.text}</b>.</p>`,
          action: () =>
            dispatch(
              proyekAction.verifyHirarki(
                `/${recap_detail.jobTitleId}/${recap_detail.jobDetailId}/${props.profile.id}`
              )
            ),
        },
        modal_action: "verifikasi_wla",
      })
    );
  };
  const onExport = () => {
    dispatch(proyekAction.exportRbk(recap_detail, hirarki.type, proyek_detail));
  };
  const onChangeUrutan = (id) => {
    let new_wla;
    if (id === 1) {
      new_wla = orderBy(hirarki_wla, ["no"], ["asc"]);
    }
    if (id === 2) {
      new_wla = orderBy(hirarki_wla, ["beban_kerja"], ["desc"]);
    }
    if (id === 3) {
      new_wla = orderBy(hirarki_wla, ["beban_kerja"], ["asc"]);
    }

    dispatch(hirarkiAction.setHirarki(new_wla));
    seturutan(id);
  };
  console.log(`proyek_detail`, proyek_detail);
  return (
    <div>
      {responden_modal && (
        <div
          className="backdoor"
          onClick={() => setresponden_modal(false)}
        ></div>
      )}
      {wke_modal && (
        <div className="backdoor" onClick={() => setwke_modal(false)}></div>
      )}
      {batasfte_modal && (
        <div
          className="backdoor"
          onClick={() => setbatasfte_modal(false)}
        ></div>
      )}
      <div className="head-section">
        <div>
          <h4 style={{ marginBottom: 0, marginTop: 0 }}>
            Detail Recap Beban Kerja {recap_detail.jobTitle}
          </h4>
        </div>
        <div className="div-flex">
          <Button
            onClick={() => props.tabToggle("detail")}
            color="primary"
            className="btn-rounded btn-remove-capital btn-action"
            variant="outlined"
          >
            Kembali
          </Button>
          &nbsp;
          <Button
            onClick={onExport}
            color="secondary"
            className="btn-rounded2 btn-remove-capital btn-action btn-outlined"
            variant="outlined"
          >
            Export
          </Button>
        </div>
      </div>
      {recap_detail !== null && (
        <Info
          classes={classes}
          wke_modal={wke_modal}
          setwke_modal={setwke_modal}
          responden_modal={responden_modal}
          setresponden_modal={setresponden_modal}
          batasfte_modal={batasfte_modal}
          setbatasfte_modal={setbatasfte_modal}
          editJabatan={editJabatan}
          detailKetWla={detailKetWla}
          recap_detail={recap_detail}
          onClickRespondenModalItem={onClickRespondenModalItem}
          onClickWkeModalItem={onClickWkeModalItem}
          wke={wke}
          setwke={setwke}
          fte={fte}
          setfte={setfte}
          onClickSimpanFteModal={onClickSimpanFteModal}
          recap_column={recap_column}
          recap_detail_column={recap_detail_column}
        />
      )}
      <br />
      <div className="card-content" style={{ padding: 15 }}>
        <div className="div-flex div-space-between div-align-center">
          <div className="div-flex div-align-center">
            <p className="hirarki-title">Hirarki Element Tugas</p>
            &nbsp;&nbsp;&nbsp;
            <p
              style={{
                margin: 0,
                color: "#777777",
                fontSize: 13,
                fontWeight: 500,
              }}
            >
              Terakhir diedit oleh : {recap_detail.lastUpdatedBy.text}
            </p>
          </div>
          <div className="div-flex">
            {props.tab === "edit_rbk" && (
              <Button
                onClick={onClickSimpanHirarki}
                className="btn-remove-capital"
                size="small"
                variant="text"
                color="primary"
              >
                Simpan
              </Button>
            )}
            {props.tab === "detail_rbk" &&
              recap_detail.status !== "Terverifikasi" &&
              recap_detail.respondent.text !== "" && (
                <Button
                  onClick={onClickVerifikasi}
                  className="btn-remove-capital"
                  size="small"
                  variant="text"
                  color="primary"
                >
                  Verifikasi Hasil
                </Button>
              )}
            &nbsp;
            {props.tab === "detail_rbk" && (
              <Button
                onClick={editRbk}
                className="btn-remove-capital"
                size="small"
                variant="text"
                color="primary"
              >
                Edit
              </Button>
            )}
            &nbsp;
            <div className="div-flex div-align-center">
              <p className="hirarki-urutan">Urutan</p>
              &nbsp;&nbsp;
              <FormControl
                style={{ width: 150 }}
                size="small"
                variant="outlined"
                className={classes.textField}
              >
                <InputLabel id="demo-simple-select-label">Urutan</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={urutan}
                  onChange={(e) => onChangeUrutan(e.target.value)}
                  labelWidth={45}
                >
                  <MenuItem value={1}>Nomor Tugas</MenuItem>
                  <MenuItem value={2}>Beban Kerja Terbesar</MenuItem>
                  <MenuItem value={3}>Beban Kerja Terkecil</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="hirarki-wrapper">
          <HirarkiItem
            is_verify={recap_detail.status === "Terverifikasi" ? true : false}
            is_edited={props.tab === "detail_rbk" ? false : true}
            tasks={recap_detail.tasks}
            hirarki_id={recap_detail.hierarchy}
            classes={classes}
          />
        </div>
        <div style={{ textAlign: "right" }}>
          <div className="div-flex" style={{ justifyContent: "flex-end" }}>
            {props.tab === "edit_rbk" && (
              <Button
                onClick={onClickSimpanHirarki}
                className="btn-remove-capital"
                size="small"
                variant="text"
                color="primary"
              >
                Simpan
              </Button>
            )}
            {props.tab === "detail_rbk" &&
              recap_detail.status !== "Terverifikasi" &&
              recap_detail.respondent.text !== "" && (
                <Button
                  onClick={onClickVerifikasi}
                  className="btn-remove-capital"
                  size="small"
                  variant="text"
                  color="primary"
                >
                  Verifikasi Hasil
                </Button>
              )}
            &nbsp;
            {props.tab === "detail_rbk" && (
              <Button
                onClick={editRbk}
                className="btn-remove-capital"
                size="small"
                variant="text"
                color="primary"
              >
                Edit
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
