import React from 'react'

export default function Detail_karyawan(props) {
    let {modal_data}=props
    console.log(`modal_data`, modal_data)
    
    return (
        <div>
            <p className='semi-bold'>Posisi Kotak : {modal_data.placement}</p>
            {modal_data.headers.map((d,i)=>(
                <div className='div-flex' key={i}>
                    <p className='semi-bold' style={{width:100}}>{d}</p>
                    <p className='semi-bold' style={{width:250}}>:&nbsp;{modal_data.data[i].text}</p>
                </div>
            ))}
            
        </div>
    )
}
