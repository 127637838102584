import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Collapse,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";
import AutoCompleteSelect from "components/Select";
import Cookie from "universal-cookie";
import { useSelector } from "react-redux";
const cookie = new Cookie();
const profile = cookie.get("profile_cookie");
const HirarkiForm = (props) => {
  let {
    classes,
    periode,
    type,
    onChangeSub,
    hirarkiIndex1,
    hirarkiIndex2,
    hirarkiIndex3,
    detail,
    is_edited,
    mcb,
    isDetail,
  } = props;
  const [keterangan, setketerangan] = useState(detail?.keterangan);
  const proyek = useSelector((state) => state.proyek);
  useEffect(() => {
    // console.log(`detail?.keterangan`, detail?.keterangan)
    setketerangan(detail?.keterangan);
  }, [detail]);
  let periodes = [
    { id: 1, text: "Harian", value: 228 },
    { id: 2, text: "Mingguan", value: 52 },
    { id: 3, text: "Bulanan", value: 12 },
    { id: 4, text: "Triwulanan", value: 4 },
    { id: 5, text: "Semesteran", value: 2 },
    { id: 6, text: "Tahunan", value: 1 },
  ];
  let findPeriodeText = (periode) => {
    if (periode !== null) {
      if (proyek.recap_detail !== null && periode?.id === 1) {
        return {
          id: 1,
          text: "Harian",
          value: proyek.recap_detail?.wke.annualWorkingDays,
        };
      } else {
        let filter = periodes.filter((f) => {
          return f.id === periode?.id;
        });
        if (filter.length > 0) {
          return filter[0];
        } else {
          return { id: 0, text: "" };
        }
      }
    } else {
      return { id: 0, text: "" };
    }
  };
  // let total_beban_kerja=detail.periode.id===1?proyek.recap_detail.wke.annualWorkingDays:findPeriodeText(detail.periode).value*detail.kuantitas*detail.durasi*detail.pegawai
  let total_beban_kerja =
    findPeriodeText(detail?.periode).value *
    detail?.kuantitas *
    detail?.durasi *
    detail?.pegawai;
  // console.log('kk',findPeriodeText(detail.periode),detail.periode)
  // console.log("props", mcb);
  return (
    <>
      {mcb === true ? (
        <div style={{ width: "93%", marginBottom: 20 }}>
          <div className="div-flex" style={{ marginBottom: 10 }}>
            <div>
              <TextField
                disabled={!is_edited}
                label="Beban Kerja"
                variant="outlined"
                type="number"
                placeholder="0"
                value={detail?.bebanKerja}
                size="small"
                className={classes.textField}
                onChange={(e) =>
                  onChangeSub(
                    "bebanKerja",
                    e.target.value,
                    hirarkiIndex1,
                    hirarkiIndex2,
                    hirarkiIndex3
                  )
                }
              />
              &nbsp;&nbsp;&nbsp;
              <TextField
                disabled={!is_edited}
                label="Satuan Beban Kerja"
                variant="outlined"
                type="text"
                placeholder="Satuan Beban Kerja"
                value={detail?.satuanBebanKerja}
                size="small"
                className={classes.textField}
                onChange={(e) =>
                  onChangeSub(
                    "satuanBebanKerja",
                    e.target.value,
                    hirarkiIndex1,
                    hirarkiIndex2,
                    hirarkiIndex3
                  )
                }
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <TextField
                disabled={!is_edited}
                label="Standard Kemampuan Rata-Rata"
                variant="outlined"
                type="number"
                placeholder="0"
                value={detail?.skr}
                size="small"
                className={classes.textField}
                onChange={(e) =>
                  onChangeSub(
                    "skr",
                    e.target.value,
                    hirarkiIndex1,
                    hirarkiIndex2,
                    hirarkiIndex3
                  )
                }
              />
              &nbsp;&nbsp;&nbsp;
              <TextField
                disabled={!is_edited}
                label="Satuan SKR"
                variant="outlined"
                type="text"
                placeholder="Satuan SKR"
                value={detail?.satuanSKR}
                size="small"
                className={classes.textField}
                onChange={(e) =>
                  onChangeSub(
                    "satuanSKR",
                    e.target.value,
                    hirarkiIndex1,
                    hirarkiIndex2,
                    hirarkiIndex3
                  )
                }
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <TextField
                disabled
                label="Waktu Penyelesaian Tugas"
                variant="outlined"
                type="number"
                placeholder="0"
                value={detail?.wpt}
                size="small"
                className={classes.textField}
                onChange={(e) =>
                  onChangeSub(
                    "wpt",
                    e.target.value,
                    hirarkiIndex1,
                    hirarkiIndex2,
                    hirarkiIndex3
                  )
                }
              />
              &nbsp;&nbsp;&nbsp;
              <TextField
                disabled={!is_edited}
                label="Satuan WPT"
                variant="outlined"
                type="text"
                placeholder="Satuan WPT"
                value={detail?.satuanWPT}
                size="small"
                className={classes.textField}
                onChange={(e) =>
                  onChangeSub(
                    "satuanWPT",
                    e.target.value,
                    hirarkiIndex1,
                    hirarkiIndex2,
                    hirarkiIndex3
                  )
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: "93%", marginBottom: 20 }}>
          <div className="div-flex" style={{ marginBottom: 10 }}>
            <AutoCompleteSelect
              style={{ width: 450 }}
              onChange={(event, value) =>
                onChangeSub(
                  "periode",
                  value,
                  hirarkiIndex1,
                  hirarkiIndex2,
                  hirarkiIndex3
                )
              }
              options={periode}
              value={detail?.periode}
              getOptionLabel={(option) => option.text}
              getOptionSelected={(opt, value) => opt.text}
              label={<>Periode</>}
              disableClearable
              disabled={!is_edited}
            />
            &nbsp;&nbsp;&nbsp;
            <TextField
              style={{ width: 340 }}
              disabled={!is_edited}
              label="Kuantitas"
              variant="outlined"
              type="number"
              placeholder="0"
              value={detail?.kuantitas}
              size="small"
              className={classes.textField}
              onChange={(e) =>
                onChangeSub(
                  "kuantitas",
                  e.target.value,
                  hirarkiIndex1,
                  hirarkiIndex2,
                  hirarkiIndex3
                )
              }
            />
            &nbsp;&nbsp;&nbsp;
            <TextField
              disabled={!is_edited}
              label="Durasi pengerjaan (menit)"
              variant="outlined"
              type="number"
              placeholder="0"
              value={detail?.durasi}
              size="small"
              className={classes.textField}
              onChange={(e) =>
                onChangeSub(
                  "durasi",
                  e.target.value,
                  hirarkiIndex1,
                  hirarkiIndex2,
                  hirarkiIndex3
                )
              }
            />
            &nbsp;&nbsp;&nbsp;
            <TextField
              style={{ width: profile.roleId !== 5 && "80%" }}
              disabled={!is_edited}
              label="Jml pegawai terlibat"
              variant="outlined"
              type="number"
              placeholder="0"
              value={detail?.pegawai}
              size="small"
              className={classes.textField}
              onChange={(e) =>
                onChangeSub(
                  "pegawai",
                  e.target.value,
                  hirarkiIndex1,
                  hirarkiIndex2,
                  hirarkiIndex3
                )
              }
            />
            &nbsp;&nbsp;&nbsp;
            <AutoCompleteSelect
              style={{ width: 500 }}
              onChange={(event, value) =>
                onChangeSub(
                  "jenis_tugas",
                  value,
                  hirarkiIndex1,
                  hirarkiIndex2,
                  hirarkiIndex3
                )
              }
              options={type}
              value={detail?.jenis_tugas}
              getOptionLabel={(option) => option.text}
              getOptionSelected={(opt, value) => opt.text}
              label={<>Jenis Tugas</>}
              disableClearable
              disabled={!is_edited}
            />
            {profile.roleId !== 5 && (
              <>
                &nbsp;&nbsp;&nbsp;
                <TextField
                  error={total_beban_kerja > 50000 ? true : false}
                  style={{ width: 400 }}
                  disabled={true}
                  label="Beban Kerja"
                  variant="outlined"
                  type="text"
                  value={
                    Number.isNaN(total_beban_kerja)
                      ? "-"
                      : total_beban_kerja.toLocaleString()
                  }
                  size="small"
                  InputProps={{
                    classes: {
                      root:
                        total_beban_kerja > 50000
                          ? classes.root
                          : classes.root2,
                      disabled: classes.disabled,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                  onChange={(e) =>
                    onChangeSub(
                      "pegawai",
                      e.target.value,
                      hirarkiIndex1,
                      hirarkiIndex2,
                      hirarkiIndex3
                    )
                  }
                />
              </>
            )}
          </div>
          <div>
            <TextField
              label="Keterangan"
              variant="outlined"
              type="number"
              disabled={!is_edited}
              value={keterangan}
              size="small"
              multiline
              className={classes.textField}
              onChange={(e) => setketerangan(e.target.value)}
              onBlur={(e) =>
                onChangeSub(
                  "keterangan",
                  keterangan,
                  hirarkiIndex1,
                  hirarkiIndex2,
                  hirarkiIndex3
                )
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default HirarkiForm;
