import React,{useState} from 'react'
import {Button,FormControlLabel,Checkbox} from '@material-ui/core'
export default function Verifikasi_wla(props) {
    const [yakin, setyakin] = useState(false)
    let {modal_data}=props
    const onVerif=()=>{
        modal_data.action()
    }
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: modal_data.msg }}></div>
            <div className='div-flex div-space-between'>
                <FormControlLabel
                    control={<Checkbox color="primary" size="small" checked={yakin} onChange={()=>setyakin(!yakin)} name="checkedA" />}
                    label="Yakin"
                />
                <Button  disabled={!yakin} size="small" onClick={onVerif} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Verifikasi Hasil</Button>
            </div>
        </div>
    )
}
