import {createStore,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './redux/reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import immutableTransform from 'redux-persist-transform-immutable'
import {Record} from 'immutable'
const initialSate={};
const MyRecord = Record({
    start_date: 'null'
  }, 'start_date') 
  const persistConfig = {
      transforms: [immutableTransform({records: [MyRecord]})],
      key: 'root',
      storage,
      whitelist: ['hirarki','responden']
    }
    const persistedReducer = persistReducer(persistConfig, rootReducer)  
const middleware=[thunk];

export const store= createStore(persistedReducer,initialSate,applyMiddleware(...middleware));
export const persistor=persistStore(store)