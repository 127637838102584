import { combineReducers } from "redux";
import general from './general'
import client from './client'
import konsultan from './konsultan'
import proyek from './proyek'
import responden from './responden'
import hirarki from './hirarki'
import talenta from './talenta'
import sa from './sa'
export default combineReducers({
    general,
    client,
    konsultan,
    proyek,
    responden,
    hirarki,
    talenta,
    sa
})