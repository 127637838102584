import * as actionType from 'redux/constants/talenta'
import * as actionGeneral from './general'
import {apiCall} from 'service/apiCall'
import _ from "lodash";
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import moment from 'moment'
import { saveAs } from 'file-saver'

import Cookie from 'universal-cookie'
const cookie=new Cookie()
const token=cookie.get('login_cookie')
const profile=cookie.get('profile_cookie')
export const setTalenta=(obj)=>async dispatch=>{
    dispatch({
        type:actionType.SET_TALENTA,
        payload:obj
    })
    return obj
}

export const getProyekList=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoadingTable(true))
    let dataReq={
        url:`/talent/list${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoadingTable(false))
       
        dispatch(setTalenta({list_proyek:res.data.items}))
        dispatch(setTalenta({pagination:res.data.info}))
        return res
    }else{
        dispatch(actionGeneral.setLoadingTable(false))
        return res

    }
}
export const getDetailProject=(projectId)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/${projectId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setTalenta({project_info:res.data}))
        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}
export const postTalentaProject=(data)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/talent`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        await dispatch(setTalenta({project_id:res.data.id}))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}
export const postChangeFileProject=(data)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/upload`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        // await dispatch(setTalenta({project_id:res.data.id}))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}
export const deleteTalentaProject=(slug)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/talent${slug}`,
        method:'DELETE',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: "Kegiatan",
            modal_component: "delete_talenta_project",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Kegiatan Terhapus</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}

export const getKlasifikasiList=(projectId)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/list/class/${projectId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}

export const postKlasifikasiProject=(data)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/class`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        // await dispatch(setTalenta({project_id:res.data.id}))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}
export const postNormaProject=(data)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/norm`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: "Norma",
            modal_component: "post_norma",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Norma Tersimpan</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}

export const getBobot=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/weight${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        let new_bobot=[]
        res.data.map((d)=>{
            let new_item=[]
            d.items.map((item)=>{
                let totalX=0
                let totalY=0
                item.x.map(x=>{
                    totalX+=x.number
                })
                item.y.map(y=>{
                    totalY+=y.number
                })
                new_item.push({...item,totalX:totalX,totalY:totalY})
            })
            
            new_bobot.push({...d,items:new_item})
        })
        dispatch(setTalenta({bobot:new_bobot}))
        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}

export const postGrade=(data)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/grade`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: "Grade",
            modal_component: "post_grade",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Grade Tersimpan</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}
export const postDeskripsi=(data,userId)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/description/${userId}`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: "Deskripsi",
            modal_component: "post_grade",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Deskripsi Terupdate</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}

export const saveBobot=(data)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/save/weight`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: "Bobot",
            modal_component: "save_bobot",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Bobot Terupdate</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}
export const getOptModalNorm=(projectId)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/talent/norm/${projectId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        
        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}
export const getNormByRange=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/norm${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        let new_norma=[]
        res.data.map((d)=>{
            // if(d.headers.length>0){
                new_norma.push(d)
                // dispatch(setTalenta({norma_range:res.data}))
    
            // }
        })
        dispatch(setTalenta({norma_range:new_norma}))
        // if(res.data.headers.length>0){
        //     dispatch(setTalenta({norma_range:res.data}))

        // }else{
        //     dispatch(setTalenta({norma_range:null}))

        // }
        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}
export const postNormaRange=(data)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/save/range`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: "Norma",
            modal_component: "post_norma",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Norma tersimpan</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}
export const postNormaCategory=(data)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/save/category`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: "Norma",
            modal_component: "post_norma",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Norma tersimpan</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}
export const getOptionCategoryNorm=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/list/category${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}
export const getNormByCategory=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/category${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        Promise.all(res.data.map(async (n,i)=>{
            let new_dim= await Promise.all(n.dim.map(async (d)=>{
                let opt=await dispatch(getOptionCategoryNorm(`/${d.id}/${n.variant.id}/${n.variant.text}`))
                if(opt){
                    let new_opt=[]
                    opt.map((d)=>{
                        new_opt.push({label:d.text,value:d.id})
                    })
                    return {...d,value:[],options:new_opt}
                }
            }))
            let new_rows=[]
            n.rows.map((row)=>{
                let new_cell=[]
                row.cells.map((cell)=>{
                    let new_categories=[]
                    cell.categories.map((category)=>{
                        new_categories.push({value:category.id,label:category.text})
                    })
                    new_cell.push({...cell,categories:new_categories})
                })
                new_rows.push({...row,cells:new_cell})
            })
            return {rows:new_rows,dim:new_dim,variant:n.variant}
        })).then((result)=>{
            dispatch(setTalenta({norma_category:result}))
        })
        // if(res.data.dim.length>0){
            // let new_dim=await Promise.all(res.data.dim.map(async (d)=>{
            //     let opt=await dispatch(getOptionCategoryNorm(d.id))
            //     if(opt){
            //         let new_opt=[]
            //         opt.map((d)=>{
            //             new_opt.push({label:d.text,value:d.id})
            //         })
            //         return {...d,value:[],options:new_opt}
            //     }
            // }))
            // let new_rows=[]
            // res.data.rows.map((row)=>{
            //     let new_cell=[]
            //     row.cells.map((cell)=>{
            //         let new_categories=[]
            //         cell.categories.map((category)=>{
            //             new_categories.push({value:category.id,label:category.text})
            //         })
            //         new_cell.push({...cell,categories:new_categories})
            //     })
            //     new_rows.push({...row,cells:new_cell})
            // })
            //     dispatch(setTalenta({norma_category:{rows:new_rows,dim:new_dim}}))
            

        // }else{
        //     dispatch(setTalenta({norma_category:null}))
            
        // }
        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}

export const getListMapTalenta=(projectId)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/list/map/${projectId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setTalenta({list_map_talenta:res.data}))
        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}

export const postMapTalenta=(data,userId)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/list/map/${userId}`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: "Talenta Map",
            modal_component: "post_list_map",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Map Talenta Tersimpan</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}


export const getDataKaryawan=(slug,data)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoadingTable(true))
    let dataReq={
        url:`/Talent/list/data${slug}`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setTalenta({list_employee:res.data}))
        dispatch(actionGeneral.setLoadingTable(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoadingTable(false))
        

    }
}
export const getFilterField=(projectId)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/map/filter/${projectId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        let new_filter=[]
        let new_filter_value=[]
        res.data.map((d)=>{
            let new_item=[]
            d.items.map((item,i)=>{
                new_item.push({id:i,text:item})
            })
            new_filter.push({...d,items:new_item,value:[]})
            new_filter_value.push({id:d.filter.id,filters:[]})
        })
        dispatch(setTalenta({filter_by_field:new_filter}))
        dispatch(setTalenta({filter_by_field_value:new_filter_value}))
        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}

export const postEmployee=(data)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/save/data`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: "Karyawan",
            modal_component: "post_list_map",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Karyawan Terupdate</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}

export const getSebaranMap=(data)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoadingTable(true))
    let dataReq={
        url:`/Talent/map/dots`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setTalenta({peta_sebaran_dots:res.data.items}))
        dispatch(setTalenta({choosen_template:res.data.template}))
        dispatch(setTalenta({sebaran_label:res.data.personals}))
        dispatch(actionGeneral.setLoadingTable(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoadingTable(false))
        

    }
}
export const getSebaranMapWithoutSetTemplate=(data)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoadingTable(true))
    let dataReq={
        url:`/Talent/map/dots`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setTalenta({peta_sebaran_dots:res.data.items}))
        dispatch(setTalenta({choosen_template:""}))
        dispatch(setTalenta({sebaran_label:res.data.personals}))
        dispatch(actionGeneral.setLoadingTable(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoadingTable(false))
        

    }
}
export const getSebaranPersentase=(data)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/map/percent`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setTalenta({peta_sebaran_persentase:res.data.items}))
        dispatch(setTalenta({choosen_template:res.data.template}))
        dispatch(setTalenta({sebaran_label:res.data.personals}))

        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}
export const getSebaranPersentaseWithoutTemplate=(data)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/map/percent`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setTalenta({peta_sebaran_persentase:res.data.items}))
        dispatch(setTalenta({choosen_template:""}))
        dispatch(setTalenta({sebaran_label:res.data.personals}))

        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}

export const postTemplate=(data,userId)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/template/${userId}`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: "Template",
            modal_component: "post_template",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Template Tersimpan</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}

export const getListTemplate=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/list/template${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setTalenta({list_template:res.data}))
        dispatch(actionGeneral.setLoading(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoading(false))
        

    }
}
export const chooseTemplate=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/choose/template${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        console.log(`res`, res)

        // dispatch(setTalenta({list_template:res.data}))
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{

        dispatch(actionGeneral.setLoading(false))
        

    }
}
export const getDataReport=(projectId)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/export/${projectId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        const wb = new ExcelJS.Workbook()

        let header=res.data.headers
        const sheet = wb.addWorksheet("Sheet 1");
        sheet.getCell('A1').value ="Powered by: Manajemen Talenta"
        sheet.getRow(3).values =header;
        res.data.rows.map((d)=>{
            let a=[]
            d.items.map((i)=>{
                a.push(i.text)
            })
            sheet.addRow(a);
        })
        // d[1].map((d)=>{
        //         let a=[]
        //         d.items.map((item)=>{
        //             a.push(item.text)
        //         })
        //         sheet.addRow(a);

        // })
        // let grouping=_.groupBy(res.data.rows,((r)=>{
        //     return r.items[19].text
        // }))
        // let ArrayGrouping=Object.entries(grouping)
        // ArrayGrouping.map((d,i)=>{
        //     const sheet = wb.addWorksheet(d[0]);
        //     let header=res.data.headers
        //     sheet.getRow(1).values =header;
        //     d[1].map((d)=>{
        //         let a=[]
        //         d.items.map((item)=>{
        //             a.push(item.text)
        //         })
        //         sheet.addRow(a);

        //     })
        // })
        dispatch(actionGeneral.setLoading(false))
        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), `${res.data.filename}.xlsx`)
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
    }
}
export const getDataReportFilter=(projectId,data)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/export/${projectId}`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        const wb = new ExcelJS.Workbook()

        let header=res.data.headers
        const sheet = wb.addWorksheet("Sheet 1");
        sheet.getCell('A1').value ="Powered by: Manajemen Talenta"
        sheet.getRow(3).values =header;
        res.data.rows.map((d)=>{
            let a=[]
            d.items.map((i)=>{
                a.push(i.text)
            })
            sheet.addRow(a);
        })
        // d[1].map((d)=>{
        //         let a=[]
        //         d.items.map((item)=>{
        //             a.push(item.text)
        //         })
        //         sheet.addRow(a);

        // })
        // let grouping=_.groupBy(res.data.rows,((r)=>{
        //     return r.items[19].text
        // }))
        // let ArrayGrouping=Object.entries(grouping)
        // ArrayGrouping.map((d,i)=>{
        //     const sheet = wb.addWorksheet(d[0]);
        //     let header=res.data.headers
        //     sheet.getRow(1).values =header;
        //     d[1].map((d)=>{
        //         let a=[]
        //         d.items.map((item)=>{
        //             a.push(item.text)
        //         })
        //         sheet.addRow(a);

        //     })
        // })
        dispatch(actionGeneral.setLoading(false))
        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), `${res.data.filename}.xlsx`)
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
    }
}

export const postCategoryList=(data)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/add/category`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}
export const getOptionXY=(projectId)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/list/class/xy/${projectId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}
export const getRecordDetail=(recordId)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/talent/record/${recordId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoading(false))
       
        // dispatch(setSa({list_history:res.data}))
        // dispatch(setSa({pagination:res.data.info}))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
        // return res

    }
}

export const deleteNormaOpt=(id)=>async(dispatch)=>{
    dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Talent/category/${id}`,
        method:'DELETE',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===204){
        
        dispatch(actionGeneral.setLoading(false))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))

    }
}


export const getOutBox=(slug,data)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoadingTable(true))
    let dataReq={
        url:`/talent/list/exmap${slug}`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(setTalenta({out_box:res.data}))
        dispatch(actionGeneral.setLoadingTable(false))
        return res.data
    }else{
        dispatch(actionGeneral.setLoadingTable(false))
        

    }
}