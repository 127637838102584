import React,{useState,useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel,Checkbox} from '@material-ui/core'

import shape_1 from 'assets/image/shape_1.svg'
import shape_2 from 'assets/image/shape_2.svg'
import shape_3 from 'assets/image/shape_3.svg'
import shape_4 from 'assets/image/shape_4.svg'
import AutoCompleteSelect from 'components/Select'
import * as respondenAction from 'redux/actions/responden'
import * as hirarkiAction from 'redux/actions/hirarki'
import {isEmpty} from 'lodash'
import Cookie from 'universal-cookie'
import * as actionTypes from 'redux/constants/responden'
const cookie=new Cookie()
const profile=cookie.get('profile_cookie')

export default function Data_diri(props) {
    
    const [agree,setAgree]=useState()
    const [is_updated_bio, setis_updated_bio] = useState(false)
    const {classes}=props
    const dispatch=useDispatch()
    const responden = useSelector(state => state.responden)
    const hirarki = useSelector(state => state.hirarki)
    let {type}=hirarki
    let {jobs,works_unit,responden_info,respondent_column}=responden
    let {name,work_unit,job,employees,location,batch}=responden_info

    useEffect(() => {
        dispatch(respondenAction.getWorksUnit(`/${profile.userName}`))
        dispatch(respondenAction.getRespondenColumns(profile.userName))
        if(job!==null){
            dispatch(respondenAction.getRespondenColumnsOptions(`/${profile.userName}/${job.id}`))
        }
        if(batch===null||name===''||work_unit===null||job===null||employees===null||location===''){
            setis_updated_bio(false)
        }else{
            setis_updated_bio(true)
        }
    }, [])
    const onChangeWorksUnit=async (value)=>{
        dispatch(respondenAction.setRespondenInfo({work_unit:value}))
        if(value!==null){

            let res = await dispatch(respondenAction.getDinamycOption(`/${value.id}/0`))
            if(res){
                if(respondent_column.length===0){
                    dispatch(respondenAction.setJabatanOptions(res.data))
                }
                respondent_column[0].options = res.data
                for (let index = 0; index < respondent_column.length; index++) {
                    respondent_column[index].value=null
                    
                }
                dispatch(respondenAction.setRespondenInfo({job:null}))
                dispatch(respondenAction.setRespondenInfo({employees:''}))
                dispatch(respondenAction.setRespondenColumn(respondent_column))
            }
        }else{
            for (let index = 0; index < respondent_column.length; index++) {
                respondent_column[index].options=[]
                respondent_column[index].value=null
            }
            dispatch(respondenAction.setJabatanOptions([]))
            dispatch(respondenAction.setRespondenInfo({job:null}))

            dispatch(respondenAction.setRespondenColumn(respondent_column))
            dispatch(respondenAction.setRespondenInfo({employees:''}))
        }
        // console.log(`respondent_column`, respondent_column)
    }
    const onChangeJabatan=async (value)=>{
        // dispatch(respondenAction.getRespondenColumnsOptions(`/${profile.userName}/${work_unit.id}/${value.id}`))
        dispatch(respondenAction.setRespondenInfo({job:value}))
        if(value!==null){
            let res= await dispatch(respondenAction.getJobsDetail(`/${work_unit.id}/${value.id}/${props.profile.id}`))
            if(res){
                dispatch(respondenAction.setRespondenInfo({location:res.data.location}))
                dispatch(respondenAction.setRespondenInfo({employees:res.data.employees}))

                dispatch(respondenAction.setRespondenInfo({batch:res.data.batch}))
            }
        }else{
            dispatch(respondenAction.setRespondenInfo({employees:''}))

        }
        
    }
    const clickStart=async ()=>{
        let {tasks,hirarki_id,mcb}=responden_info
        
        if(!isEmpty(tasks)){
            if(hirarki_id===1){
                await dispatch(hirarkiAction.setConfigHirarkiIfTaskNotEmptyAndId1(tasks,type,mcb))
            }else{
                await dispatch(hirarkiAction.setConfigHirarkiIfTaskNotEmptyAndId2(tasks,type,mcb))
            }
        }else{
            if(hirarki_id===1){
                await dispatch(hirarkiAction.setConfigHirarkiIfTaskEmptyAndId1(mcb))
            }else{
                await dispatch(hirarkiAction.setConfigHirarkiIfTaskEmptyAndId2(mcb))
            }
        }
        props.settab('form_wla')
    }
    const renderDisableColumn=()=>{
        let filter=respondent_column.filter((d)=>{
            return d.value===null
        })
        if(filter.length>0){
            return true
        }else{
            return false
        }
    }
    const renderDisable=()=>{
        if(batch===null||name===''||work_unit===null||job===null||employees===null||location===''){
            return true
        }else{
            return false
        }
    }
    const renderDisableStart=()=>{
        if(is_updated_bio){
            return true
        }else{return false}
    }
    const clickSimpan=async ()=>{
        let data={
            name:name,
            workUnitId:work_unit.id,
            jobTitleId:job.id,
            employees:parseInt(employees),
            location:location,
            userId:props.profile.id,
            batch:batch,
            columns:[]
        }
        respondent_column.map((d)=>{
            data.columns.push({
                columnId:d.column.id,
                optionId:d.value.id
            })
        })
        let res=await dispatch(respondenAction.postRespondenInfo(data))
        if(res){
            setis_updated_bio(true)
        }
    }
    const onChangeColumn= async (i,value)=>{
        respondent_column[i].value=value
        dispatch({
            type:actionTypes.GET_RESPONDENT_COLUMN,
            payload:respondent_column
        })
        if(value!==null){
            let res = await dispatch(respondenAction.getDinamycOption(`/${responden_info.work_unit.id}/${value.id}`))
            if(res){
                if(i!==respondent_column.length-1){
                    respondent_column[i+1].options = res.data
                    respondent_column[i+1].value = null
                    dispatch(respondenAction.setRespondenColumn(respondent_column))
                    dispatch(respondenAction.setRespondenInfo({job:null}))
                    dispatch(respondenAction.setRespondenInfo({employees:''}))
                }else{
                    dispatch(respondenAction.setJabatanOptions(res.data))
                    dispatch(respondenAction.setRespondenInfo({job:null}))
                    dispatch(respondenAction.setRespondenInfo({employees:''}))

                }
                
            }
        }else{
            if(i!==respondent_column.length-1){
                respondent_column[i+1].options = []
                respondent_column[i+1].value = null
                dispatch(respondenAction.setRespondenColumn(respondent_column))
            }else{

                dispatch(respondenAction.setRespondenInfo({job:null}))
                dispatch(respondenAction.setJabatanOptions([]))
                dispatch(respondenAction.setRespondenInfo({employees:''}))

            }
            
        }
    }
    return (
        <div>
            <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile' >
                        <p style={{fontWeight:'bold',margin:0,color:'#777777'}}>{props.profile.userName}</p>
                        <p >Sebelum memulai proses Workload Analysis kami perlu mendapatkan informasi responden dengan posisi jabatan terkait. Silahkan isi form di samping</p>
                        <Button onClick={clickStart} disabled={!is_updated_bio} className='hide-mobile' style={{width:'80%',textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Mulai proses</Button>
                    </div>
                </div>
                <div className='welcome-example'>
                    <img style={{top:35,left:-20}} className='shape-img' src={shape_1}/>
                    <img style={{top:5,right:50}} className='shape-img' src={shape_2}/>
                    <img style={{top:350,left:-40}} className='shape-img' src={shape_3}/>
                    <img style={{bottom:10,left:300}} className='shape-img' src={shape_4}/>
                    <img style={{bottom:30,right:20}} className='shape-img' src={shape_1}/>
                    <div className='card-example'>
                        <div className='info-wrapper'>
                            <h2>Informasi data diri</h2>
                            <TextField
                                label="Nama"
                                variant='outlined'
                                type='text'
                                value={name}
                                size='small'
                                className={classes.textField}
                                onChange={(e)=>dispatch(respondenAction.setRespondenInfo({name:e.target.value}))}
                            />
                            <AutoCompleteSelect

                                onChange={(event,value)=>onChangeWorksUnit(value)}
                                options={works_unit}
                                value={work_unit}
                                getOptionLabel={(option) => option.text}
                                label={<>Nama Unit Kerja / Departemen</>}
                            />
                            
                            {respondent_column.map((d,i)=>(
                                <AutoCompleteSelect
                                    key={i}
                                    onChange={(event,value)=>onChangeColumn(i,value)}
                                    options={d.options}
                                    value={d.value}
                                    getOptionLabel={(option) => option.text}
                                    label={d.column.text}
                                />
                            ))}
                            <AutoCompleteSelect
                                
                                onChange={(event,value)=>onChangeJabatan(value)}
                                options={jobs}
                                value={job}
                                getOptionLabel={(option) => option.text}
                                label={<>Jabatan</>}
                            />
                            <TextField
                                label="Jumlah Pegawai"
                                variant='outlined'
                                type='text'
                                value={employees}
                                size='small'
                                className={classes.textField}
                                onChange={(e)=>dispatch(respondenAction.setRespondenInfo({employees:e.target.value}))}
                            />
                            <TextField
                                label="Lokasi Kerja"
                                variant='outlined'
                                type='text'
                                placeholder='Contoh : Jakarta - Kantor Pusat'
                                value={location}
                                size='small'
                                className={classes.textField}
                                onChange={(e)=>dispatch(respondenAction.setRespondenInfo({location:e.target.value}))}
                            />
                            <TextField
                                label="Batch"
                                variant='outlined'
                                type='text'
                                value={batch}
                                size='small'
                                className={classes.textField}
                                onChange={(e)=>dispatch(respondenAction.setRespondenInfo({batch:e.target.value}))}
                            />
                            <div style={{textAlign:'right'}}>
                                <Button onClick={clickSimpan} disabled={renderDisable()||renderDisableColumn()} style={{textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Simpan</Button>

                            </div>
                        </div>
                    </div>
                </div>
                <br className='show-mobile'/>
                <div  className=' show-mobile div-flex div-justify-center'>
                    
                    <Button onClick={clickStart} disabled={!is_updated_bio} style={{width:'100%',textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Mulai proses</Button>

                </div>
                <br className='show-mobile'/>

            </div>
        </div>
    )
}
