import * as actionTypes from "../constants/hirarki";
const initialState={
    hirarki_wla:[],
    periode:[],
    type:[]
}
export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.SET_HIRARKI:
            return{
                ...state,
                hirarki_wla:action.payload
            }
        case actionTypes.GET_PERIODE:
            return{
                ...state,
                periode:action.payload
            }
        case actionTypes.GET_TYPE:
            return{
                ...state,
                type:action.payload
            }
        case actionTypes.RESET_HIRARKI:
            return{
                ...state,
                hirarki_wla:[]
            }
        
        default:
            return state;
    }
}

