import React, { useEffect } from "react";
import HirarkiItem from "components/HirarkiItem";
import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import * as hirarkiAction from "redux/actions/hirarki";
import OE from "assets/image/OE.png";
import { modalToggle, modalToggleReset } from "redux/actions/general";
const Navbar = React.memo((props) => {
  return (
    <div className="navbar-wrapper">
      <div className="div-flex div-space-between div-align-center navbar">
        <img src={OE} style={{ width: 150 }} />
        <div className="div-flex div-align-center">
          <h3>
            <b>Petunjuk pengisian</b>
          </h3>
          &nbsp;&nbsp;
          <div onClick={null} className="circle-help">
            <span>?</span>
          </div>
        </div>
      </div>
    </div>
  );
});
export default function Form_wla(props) {
  const dispatch = useDispatch();
  const hirarki = useSelector((state) => state.hirarki);
  const responden = useSelector((state) => state.responden);
  let { hirarki_wla } = hirarki;
  let { responden_info } = responden;
  useEffect(() => {
    dispatch(hirarkiAction.getPeriod());
    dispatch(hirarkiAction.getType(`/${responden_info.projectId}`));
  }, []);
  console.log("hirarki", responden);

  const onClickSimpan = async () => {
    let new_tasks = [];
    if (responden_info.mcb === false) {
      if (responden_info.hirarki_id === 1) {
        hirarki_wla.map((data) => {
          let new_prosess = [];
          data.child.map((child) => {
            new_prosess.push({
              processId: child.id,
              process: child.title,
              periodId:
                child.detail.periode === null ? 0 : child.detail.periode.id,
              quantity:
                child.detail.kuantitas === ""
                  ? 0
                  : parseInt(child.detail.kuantitas),
              duration:
                child.detail.durasi === "" ? 0 : parseInt(child.detail.durasi),
              employees:
                child.detail.pegawai === ""
                  ? 0
                  : parseInt(child.detail.pegawai),
              typeId:
                child.detail.jenis_tugas === null
                  ? 0
                  : child.detail.jenis_tugas.id,
              remarks: child.detail.keterangan,
            });
          });
          new_tasks.push({
            taskId: data.id,
            task: data.title,
            children: [],
            processes: new_prosess,
          });
        });
      } else {
        hirarki_wla.map((data) => {
          let new_children = [];
          data.child.map((child) => {
            let new_prosess = [];
            child.child.map((prossess) => {
              new_prosess.push({
                processId: prossess.id,
                process: prossess.title,
                periodId:
                  prossess.detail.periode === null
                    ? 0
                    : prossess.detail.periode.id,
                quantity:
                  prossess.detail.kuantitas === ""
                    ? 0
                    : parseInt(prossess.detail.kuantitas),
                duration:
                  prossess.detail.durasi === ""
                    ? 0
                    : parseInt(prossess.detail.durasi),
                employees:
                  prossess.detail.pegawai === ""
                    ? 0
                    : parseInt(prossess.detail.pegawai),
                typeId:
                  prossess.detail.jenis_tugas === null
                    ? 0
                    : prossess.detail.jenis_tugas.id,
                remarks: prossess.detail.keterangan,
              });
            });
            new_children.push({
              taskId: child.id,
              task: child.title,
              processes: new_prosess,
            });
          });
          new_tasks.push({
            taskId: data.id,
            task: data.title,
            children: new_children,
          });
        });
      }
    }

    if (responden_info.mcb === true) {
      hirarki_wla.map((data) => {
        let new_children = [];
        data.child.map((mcb) => {
          let new_mcb = [];
          new_mcb.push({
            id: mcb.detail.id,
            taskId: data.id,
            bebanKerja:
              mcb.detail.bebanKerja === null
                ? 0
                : parseInt(mcb.detail.bebanKerja),
            skr: mcb.detail.skr === null ? 0 : parseInt(mcb.detail.skr),
            wpt: 0,
            satuanBebanKerja: mcb.detail.satuanBebanKerja,
            satuanSKR: mcb.detail.satuanSKR,
            satuanWPT: mcb.detail.satuanWPT,
          });
          let new_mcb_object = {};
          for (let i = 0; i < new_mcb.length; i++) {
            Object.assign(new_mcb_object, new_mcb[i]);
          }
          new_children.push({
            id: mcb.id,
            task: mcb.title,
            mcb: new_mcb_object,
            processes: [],
          });
        });
        new_tasks.push({
          id: data.id,
          task: data.title,
          children: new_children,
        });
      });
    }

    let new_data = {
      jobDetailId: responden_info.jobDetailId,
      tasks: new_tasks,
      userId: props.profile.id,
    };
    // console.log(`hirarki_wla`, hirarki_wla)
    let res = await dispatch(hirarkiAction.postJawabanResponden(new_data));
    if (res) {
      dispatch(
        modalToggle({
          modal_open: true,
          modal_title: "",
          modal_component: "",
          modal_size: 400,
          modal_type: "confirm-rbk",
          modal_data: {
            action_no: () => {
              dispatch(modalToggleReset());
              props.settab("selesai");
            },
            action_ya: () => dispatch(modalToggleReset()),
          },
          modal_action: "error",
        })
      );
    }
  };
  // console.log(`hirarki_wla`, hirarki_wla,responden_info)
  return (
    <div>
      <Navbar profile={props.profile} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div style={{ margin: "auto", width: "80%" }}>
        <div className="div-flex div-space-between">
          <p className="responden-form-title">
            Hirarki Element Tugas {responden_info.jobTitle} -{" "}
            {responden_info.shortName} - {responden_info.workUnit}
          </p>
          <Button
            style={{ height: 35, width: 80 }}
            size="small"
            onClick={onClickSimpan}
            color="primary"
            variant="contained"
            className="btn-remove-capital btn-rounded"
          >
            Simpan
          </Button>
        </div>
        <div className="card-content" style={{ padding: 20 }}>
          <HirarkiItem
            is_edited={true}
            tasks={responden_info.tasks}
            hirarki_id={responden_info.hirarki_id}
            classes={props.classes}
          />
        </div>
        <br />
        <div style={{ textAlign: "right" }}>
          <Button
            style={{ height: 35, width: 80 }}
            size="small"
            onClick={onClickSimpan}
            color="primary"
            variant="contained"
            className="btn-remove-capital btn-rounded"
          >
            Simpan
          </Button>
        </div>
      </div>
    </div>
  );
}
