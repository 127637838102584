import React,{useRef,useState,useEffect} from 'react'
import {Button,TextField} from '@material-ui/core'
import { MuiThemeProvider, createTheme,withStyles, makeStyles} from '@material-ui/core/styles'
import AutoCompleteSelect from 'components/Select'
import {useDispatch,useSelector} from 'react-redux'
import {postKeyAccess} from 'redux/actions/proyek'
const themeButton = createTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
          },
          width:'100%',
          marginBottom:15
  },
  textField2: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width:'100%',
    marginBottom:10
}
}));

export default function Filter_project(props) {
    const {classes,modal_data}=props
    const dispatch = useDispatch()
    const [state, setstate] = useState({
        projectId:null,
        username:'',
        password:''
    })
    useEffect(()=>{
       if(modal_data!==null){
           setstate({
               projectId:modal_data.projectId,
               username:modal_data.username,
               password:modal_data.password
           })
       }
    },[])

    const onClickSimpan=async ()=>{
       let data={
           projectId:state.projectId,
           username:state.username,
           password:state.password,
           userId:props.profile.id
       }
       dispatch(postKeyAccess(data))
    }
    const onChangeUsername=(username)=>{
        let result=username.replace(/\s/g,'').toLowerCase()
        setstate({...state,username:result})
    }
    const onChangePassword=(password)=>{
        let result=password.replace(/\s/g,'').toLowerCase()
        setstate({...state,password:result})
    }
    return (
        <div>
           
           <TextField
                label="Username"
                variant='outlined'
                value={state.username}
                size='small'
                className={classes.textField}
                // disabled={renderDisable()}
                onChange={(e)=>onChangeUsername(e.target.value)}
            />
            <TextField
                label="Password"
                variant='outlined'
                value={state.password}
                size='small'
                className={classes.textField}
                // disabled={renderDisable()}
                onChange={(e)=>onChangePassword(e.target.value)}
            />
            <div style={{textAlign:'right'}}>
                <MuiThemeProvider theme={themeButton}>
                    <Button size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Simpan</Button>
                </MuiThemeProvider>
            </div>
        </div>
    )
}
