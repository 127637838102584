import React from 'react';
import Routes from './routes'
import {Provider} from 'react-redux'
import {store,persistor} from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { MuiThemeProvider, createTheme, } from '@material-ui/core/styles'
const themeButton = createTheme({ 
  palette: { 
      primary: {
        main:'#00a1ed',
        contrastText: '#FFFFFF',

      },
      secondary: {
          main:'#4cc614',
          contrastText: '#FFFFFF',

      }
  } 
})
function App() {
  return (
    <MuiThemeProvider theme={themeButton}>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
            <Routes/>
        </PersistGate>
      </Provider>
      </MuiThemeProvider>
  );
}

export default App;
