import React,{useEffect,useState} from 'react'
import { Button } from '@material-ui/core'
import Talent_pool from './talent_pool'
import Peta_sebaran from './peta_sebaran'
import { useDispatch,useSelector } from 'react-redux'
import * as actionTalenta from 'redux/actions/talenta'
import * as actionGeneral from 'redux/actions/general'
import _ from 'lodash'
import OutBox from './OutBox'
export default function Peta(props) {
    const dispatch = useDispatch()
    const talenta = useSelector(state => state.talenta)
    const general = useSelector(state => state.general)
    const [jenis_sebaran, setjenis_sebaran] = useState('titik')
    const [search, setsearch] = useState('')
    const [modal_jenis, setmodal_jenis] = useState(false)

    let {project_info,list_map_talenta,template_active,peta_sebaran_dots,peta_sebaran_persentase,filter_by_field_value_with_id,filter_by_field_value}=talenta
    useEffect(() => {
        getListMapTalenta()
        let data={
            items:[],
            projectId:project_info.id,
            search:'*'
        }
        renderAction(data,jenis_sebaran)
        dispatch(actionTalenta.getFilterField(project_info.id))
        getOutBox('/1/10',data)
    }, [template_active])
    const getSebaranDots=async (data,from)=>{
        // if(from==='save'){
        //     let res=await dispatch(actionTalenta.getSebaranMapWithoutSetTemplate(data))
        //     return res
        // }else{
        //     let res=await dispatch(actionTalenta.getSebaranMap(data))
        //     return res
        // }
        let res=await dispatch(actionTalenta.getSebaranMap(data))
            return res
    }
    const getSebaranPersentase=async (data,from)=>{
        // if(from==='save'){
        //     let res=await dispatch(actionTalenta.getSebaranPersentaseWithoutTemplate(data))
        //     return res
        // }else{
        //     let res=await dispatch(actionTalenta.getSebaranPersentase(data))
        //     return res
        // }
        let res=await dispatch(actionTalenta.getSebaranPersentase(data))
            return res
        
    }
    const getListMapTalenta=()=>{
        dispatch(actionTalenta.getListMapTalenta(project_info.id))

    }
    const getReport=()=>{
        dispatch(actionTalenta.getDataReport(project_info.id))
    }
    const getReportFilter=()=>{
        let data={
            items:filter_by_field_value,
            projectId:talenta.project_info.id,
            search:search!==""?search:'*'
        }
        dispatch(actionTalenta.getDataReportFilter(project_info.id,data))
    }
    const jenisSebaranToggle=async (key)=>{
        setjenis_sebaran(key)
        setmodal_jenis(false)
        
    }
    const renderAction=async (data,key,from)=>{
        if(key==='titik'){
            return getSebaranDots(data,from)
        }
        if(key==='persentase'){
            return getSebaranPersentase(data,from)
        }
        if(key==='nama'){
            return getSebaranDots(data,from)
        }
    }
    const getOutBox=(slug,data)=>{
        dispatch(actionTalenta.getOutBox(slug,data))
    }
    const actionFilter=async (items)=>{
        dispatch(actionGeneral.modalToggleReset())

        let data={
            items:items,
            projectId:talenta.project_info.id,
            search:'*'
        }
        renderAction(data,jenis_sebaran)
        
    }
    const filterMap=async ()=>{
        dispatch(actionGeneral.modalToggle({
            modal_open: true,
            modal_title: `Filter Peta Sebaran`,
            modal_component: "filter_by_field",
            modal_size:450,
            modal_type:'filter_by_field',
            modal_data:{
                action:(items)=>actionFilter(items),
                
            },
            modal_action:'filter_by_field'
        }))
    }
    const detailKaryawan=async (data)=>{
        let res=await dispatch(actionTalenta.getRecordDetail(data.recordId))
        if(res){
            dispatch(actionGeneral.modalToggle({
                modal_open: true,
                modal_title: `Informasi Karyawan`,
                modal_component: "detail_karyawan",
                modal_size:400,
                modal_type:'detail_karyawan',
                modal_data:res.data,
                modal_action:'detail_karyawan'
            }))
        }
        
    }
    const searchToggle=_.debounce(async (e)=>{
        setsearch(e)
        let data={
            items:filter_by_field_value,
            projectId:project_info.id,
            search:e.length!==""?e:'*'
        }
        renderAction(data,jenis_sebaran)
    },1000)
    return (
        <div>
             <div className='head-section'>
                <h4><b>Detail Proyek {project_info.name}</b></h4>
                <div className='div-flex'>
                    <Button  onClick={()=>props.tabToggle(props.tab_back?props.tab_back:'detail')} style={{ border: '1.5px solid' }} className='btn-remove-capital btn-rounded' variant='outlined' color="primary">Kembali</Button>
                    {list_map_talenta.length>0&&<>&nbsp;&nbsp;&nbsp;
                    <Button onClick={getReport} style={{ border: '1.5px solid' }} className='btn-remove-capital btn-rounded' variant='outlined' color="secondary">Export</Button></>}
                    {/* &nbsp;&nbsp;&nbsp;
                    <Button onClick={null}  className='btn-remove-capital btn-rounded' variant='contained' color="primary">Hitung Peta Talenta</Button> */}
                </div>
            </div>
            <Talent_pool 
                getListMapTalenta={getListMapTalenta} 
                {...props}
                jenis_sebaran={jenis_sebaran}
                renderAction={renderAction}
                getOutBox={getOutBox}
            />
            <br/>
            <Peta_sebaran 
                project_info={project_info} 
                {...props}
                getSebaranDots={getSebaranDots}
                getSebaranPersentase={getSebaranPersentase}
                jenisSebaranToggle={jenisSebaranToggle}
                modal_jenis={modal_jenis}
                setmodal_jenis={setmodal_jenis}
                jenis_sebaran={jenis_sebaran}
                setjenis_sebaran={setjenis_sebaran}
                filterMap={filterMap}
                searchToggle={searchToggle}
                getReport={getReport}
                detailKaryawan={detailKaryawan}
                isLoadingTable={general.isLoadingTable}
                getReportFilter={getReportFilter}
            />
            <br/>
            <OutBox
                {...props}
                getOutBox={getOutBox}
            />
        </div>
    )
}
