import React,{useState,useEffect} from 'react'
import Select from 'components/Select'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import { useSelector,useDispatch } from 'react-redux'
import TextField from 'components/TextField'
import {postNormaProject} from 'redux/actions/talenta'
import {Switch} from '@material-ui/core';
const label = { inputProps: { 'aria-label': 'Switch demo' } };
export default function Norma(props) {
    let {klasifikasi_list,filename,variant_list,value}=props.modal_data
    const dispatch = useDispatch()
    const talenta = useSelector(state => state.talenta)
    const general = useSelector(state => state.general)
    const [state, setstate] = useState({
        list:klasifikasi_list,
        range:[],
        category:[],
        total:0,
        variantId:0
    })
    const [variant_colom, setvariant_colom] = useState(value.variant.id!==0?true:false)
    useEffect(() => {
        if(value){
            setstate({
                ...state,
                range:value.range,
                category:value.category,
                total:value.total,
                variantId:value.variant
            })
        }
    }, [])
    const onChangeSelect=(value,name,reason)=>{
        // let new_list
        // if(Array.isArray(value)){
        //     if(reason==='select-option'){
        //         value.map((v)=>{
        //             new_list=state.list.filter((d)=>{
        //                 return d.id!==v.id
        //             })
        //         })
        //     }else{
        //         if(_.isEmpty(value)){
        //             new_list=[...state[name],...state.list]
        //         }else{
        //             value.map((v)=>{
        //                 new_list=klasifikasi_list.filter((d)=>{
        //                     return d.id!==v.id
        //                 })
                        
        //             })
        //         }
        //     }
        // }else{
        //     if(value){
        //         new_list=state.list.filter((d)=>{
        //             return d.id!==value.id
        //         })
        //     }else{
        //         new_list=[state[name],...state.list]
        //     }
        // }
        setstate({...state,[name]:value})
    }
    const handleDisable=()=>{
        return false
    }
    const onSave=async ()=>{
        let data={
            projectId:talenta.project_info.id,
            range:state.range.map((d)=>d.id),
            category:state.category.map((d)=>d.id),
            total:parseInt(state.total),
            userId:props.profile.id,
            variantId:state.variantId!==0?state.variantId.id:0
        }
        let res=await dispatch(postNormaProject(data))
        if(res){
            props.modal_data.afterPost()
        }
    }
    const renderOptions=()=>{
        let {range,category,list}=state
        let used_options=[]
        let new_options=[]
        range.map((d)=>{
            used_options.push(d)
        })
        category.map((d)=>{
            used_options.push(d)
        })
        list.map((l)=>{
            let findIfUsed=used_options.filter((u)=>{
                return u.id===l.id
            })
            if(findIfUsed.length===0){
                new_options.push(l)
            }
        })
        return new_options
    }
    const onChangeSwitch=()=>{
        setvariant_colom(!variant_colom)
        setstate({
            ...state,
            variantId:0
        })
    }
    console.log(`state`, state)
    return (
        <div>
            <Select
                onChange={(event,value,reason)=>onChangeSelect(value,'range',reason)}
                options={renderOptions()}
                value={state.range}
                getOptionLabel={(option) => option.text}
                label="Norma berdasarkan rentang nilai"
                multiple
                filterSelectedOptions
                style={{marginBottom:10}}
            />
            <Select
                onChange={(event,value,reason)=>onChangeSelect(value,'category',reason)}
                options={renderOptions()}
                value={state.category}
                getOptionLabel={(option) => option.text}
                label="Norma berdasarkan kategori"
                multiple
                filterSelectedOptions
                style={{marginBottom:10}}
            />
            <div style={{margin:'20px 0',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <p style={{margin:0}}>Gunakan varian norma?</p>
                <Switch onChange={onChangeSwitch} checked={variant_colom} color="primary" size="small" {...label} defaultChecked />
            </div>
            {variant_colom&&<Select
                onChange={(event,value,reason)=>onChangeSelect(value,'variantId',reason)}
                options={variant_list}
                value={state.variantId}
                getOptionLabel={(option) => option.text}
                label="Kolom Variant"
                filterSelectedOptions
                style={{marginBottom:10}}
            />}
            <TextField
                label="Jumlah nilai norma"
                onChange={(e)=>setstate({...state,total:e.target.value})}
                value={state.total}
                color='primary'
                variant='outlined'
                size='small'
                name='total'
                style={{marginBottom:20}}
            />
            <div style={{textAlign:'right'}}>
                <Button onClick={onSave} disabled={handleDisable()} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
