import * as actionTypes from "../constants/talenta";

const initialState={
    list_proyek:[],
    project_id:0,
    pagination:null,
    filter:{
        segments:[],
        branches:[]
    },

    project_info:{
        id:0,
        name:'',
        year:0,
        employees:0,
        description:"",
        xAxis:"",
        yAxis:""
    },
    bobot:[],
    norma_range:[{"variant":{"id":0,"text":""},"headers":[],"rows":[]}],
    norma_category:[
        {
            "rows": [],
            "dim": [],
            "variant": {
                "id": 0,
                "text": ""
            }
        }
    ],
    list_map_talenta:[],
    client:[],
    client_pagination:null,
    list_industry:[],

    list_employee:{
        filename:'',
        headers:[],
        rows:[],
        info:{
            page:1,
            perPage:10,
            total:0
        }
    },

    filter_by_field:[],
    filter_by_field_value:[],
    filter_by_field_value_with_id:[],


    list_template:[],
    template_active:null,

    peta_sebaran_dots:[],
    peta_sebaran_persentase:[],
    choosen_template:'',
    sebaran_label:[],

    out_box:{
        headers:[],
        rows:[],
        info:{
            page:1,
            perPage:10,
            total:0
        }
    },
}
export default (state=initialState,action)=>{

    switch (action.type) {
        
        case actionTypes.SET_TALENTA:
            return{
                ...state,
                [Object.keys(action.payload)]:Object.values(action.payload)[0]
            }
    
            default:

                return state
    }
}